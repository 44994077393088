import type { CellValueChangedEvent, ColDef, GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { getCellClassesForGroups } from '@org/utils';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
};

export const useController = (apiParams: UseControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);

  const { t } = useTranslation();

  const {
    plannedAccountEntries,
    plannedCostRevenueDetail,
    isPending,
    updatePlannedCostRevenueDetails,
    updatePlannedCostRevenueAccountEntry,
    plannedCostTypeAccountEntries,
    updatePlannedCostRevenueCostTypeAccountEntry,
  } = aggregated.usePlannedCostRevenue(apiParams);

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: plannedAccountEntries ?? [],
  });

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererParams: {
        suppressCount: true,
      },
      checkboxSelection: false,
      field: 'accountName',
      valueFormatter: ({ value, data, node }) => (node?.group ? '' : `${data.accountId} ${value}`),
      flex: 3,
      headerName: t('main:manageMenu.manageImputedWithdrawalCapital.description'),
    }),
    [t],
  );

  const updateDetail = useCallback(
    async <T extends keyof Parameters<typeof updatePlannedCostRevenueDetails>['0']>(
      field: T,
      newValue: Parameters<typeof updatePlannedCostRevenueDetails>['0'][T],
    ) => {
      await updatePlannedCostRevenueDetails({
        ...plannedCostRevenueDetail,
        [field]: newValue,
      });
    },
    [plannedCostRevenueDetail, updatePlannedCostRevenueDetails],
  );

  const handleOnCellChange = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      data: updatedPlannedAccountEntry,
    } = event;

    if (!colName) {
      return;
    }

    if (event.node?.group && colName === 'accountPriceIncreasePercentage') {
      const { originalId } = event.node.allLeafChildren?.at(0)?.data ?? {};

      const selectedCostTypeAccountEntry = plannedCostTypeAccountEntries?.find(
        ({ id }) => id === originalId,
      );

      return await updatePlannedCostRevenueCostTypeAccountEntry({
        ...selectedCostTypeAccountEntry,
        ctaPriceIncreasePercentage: event.value,
      });
    }

    await updatePlannedCostRevenueAccountEntry(updatedPlannedAccountEntry);
  });

  const columnDefs = useColumns({
    plannedCostTypeAccountEntries,
    updatePlannedCostRevenueAccountEntry,
    updatePlannedCostRevenueCostTypeAccountEntry,
    priceIncreaseActivated: Boolean(plannedCostRevenueDetail.priceIncreaseActivated),
  });

  const getRowId = useCallback(
    ({ data: getRowParamsData }: GetRowIdParams) => getRowParamsData?.id,
    [],
  );

  const detailCellRendererParams = useMemo(
    () => ({
      apiParams,
    }),
    [apiParams],
  );

  return {
    ...apiParams,
    agGridProps,
    autoGroupColumnDef,
    columnDefs,
    defaultColDef,
    detailCellRendererParams,
    getRowId,
    priceIncreaseActivated: plannedCostRevenueDetail.priceIncreaseActivated,
    plannedCostRevenueEnabled: plannedCostRevenueDetail.plannedCostRevenueEnabled,
    priceIncreaseMasterConfigurationId: plannedCostRevenueDetail.priceIncreaseMasterConfigurationId,
    priceIncreaseMasterConfigurationYear:
      plannedCostRevenueDetail.priceIncreaseMasterConfigurationYear,
    updateDetail,
    tableRef,
    isPending,
    handleOnCellChange,
  };
};

export type ControllerType = ReturnType<typeof useController>;
