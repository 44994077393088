import nameOf from 'easy-tsnameof';

import type { griddy } from '@org/query';

export const REVENUE_TYPES = {
  DEFINE_REVENUES: 'DEFINE_REVENUES',
  EMPTY: 'EMPTY',
  SELECT_ACCOUNTS: 'SELECT_ACCOUNTS',
};

export const columnNames = nameOf<griddy.CostUnit, never>();
