import type { UseDisclosureProps } from '@nextui-org/react';
import type { FC } from 'react';
import React, { useState } from 'react';

import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';

import type { dice, griddy } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { Button, Modal, ModalContent } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { AddNewYearModalContent, DeleteYearModalContent, YearCard } from './components';
import { useController } from './useController';

type ModalContentKeys = 'ADD_NEW_YEAR' | 'DELETE_YEAR';

export interface YearsOverviewModalContentProps {
  createYear: ({ year }: { year: string }) => Promise<griddy.YearDTOV2>;
  deleteYear: (yearId: string) => Promise<void>;
  disclosureState: UseDisclosureProps;
  years?: dice.YearDTOV2[];
  publicFacilityId: string;
  selectedYear: dice.YearDTOV2;
}

interface YearsOverviewOverviewProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const View: FC<YearsOverviewOverviewProps> = ({
  LinkComponent,
  getLinkProps,
  createYear,
  disclosureState,
  publicFacilityId,
  years,
  deleteYear,
}) => {
  const [selectedYear, setSelectedYear] = useState<dice.YearDTOV2>({});

  const { t } = useTranslation();
  const [activeModalKey, setActiveModalKey] = useState<ModalContentKeys>('ADD_NEW_YEAR');

  const { onOpen, onOpenChange, isOpen } = disclosureState;

  const MODAL_CONTENT_LIST: Record<ModalContentKeys, React.FC<YearsOverviewModalContentProps>> = {
    ADD_NEW_YEAR: AddNewYearModalContent,
    DELETE_YEAR: DeleteYearModalContent,
  };

  const SelectedModalContent = MODAL_CONTENT_LIST[activeModalKey];

  const modalContentProps = {
    createYear,
    deleteYear,
    disclosureState,
    publicFacilityId,
    selectedYear,
    years,
  };

  const handleOpenTileOptions = (key: ModalContentKeys, year: dice.YearDTOV2) => {
    onOpen();
    setActiveModalKey(key);
    setSelectedYear(year);
  };

  return (
    <>
      <div className="h-full">
        <div className="mx-auto my-4 mb-4 max-w-3xl px-4">
          <Button
            className="flex cursor-pointer items-center !rounded-md"
            onClick={() => {
              onOpen();
              setActiveModalKey('ADD_NEW_YEAR');
            }}
            size="md"
            variant="secondary"
          >
            <PlusIcon className="h-4 w-4" />
            <div>{t('main:clients.yearlyOverviewPanel.header')}</div>
          </Button>
        </div>
        <div className="scrollable-container mx-auto h-[calc(100vh-240px)] max-w-3xl space-y-8">
          {years?.map((year) => (
            <div
              className="space-y-4 rounded-lg border p-4 shadow-md"
              key={year.year}
            >
              <div className="flex justify-between">
                <div className="text-lg font-medium">{year.year}</div>
                <div className="">
                  <Dropdown>
                    <DropdownTrigger className="h-8 w-8 rounded p-1 hover:bg-gray-200">
                      <EllipsisVerticalIcon
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Static Actions">
                      <DropdownItem
                        className="text-red-500"
                        color="danger"
                        key="delete"
                        onClick={() => handleOpenTileOptions('DELETE_YEAR', year)}
                        startContent={
                          <span className="menu-item-icon appkiticon icon-delete-outline" />
                        }
                      >
                        {t('common:contextMenu.delete')}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {year.preYearCalculationDTO && (
                  <YearCard
                    LinkComponent={LinkComponent}
                    getLinkProps={getLinkProps}
                    type="pre"
                    yearCalculation={year.preYearCalculationDTO}
                  />
                )}
                {year.postYearCalculationDTO && (
                  <YearCard
                    LinkComponent={LinkComponent}
                    getLinkProps={getLinkProps}
                    type="post"
                    yearCalculation={year.postYearCalculationDTO}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          <SelectedModalContent {...modalContentProps} />
        </ModalContent>
      </Modal>
    </>
  );
};

export const YearsOverview = bindControllers(View, [useController]);

YearsOverview.displayName = 'YearsOverview';
