import type { CellValueChangedEvent, GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useRef } from 'react';

import type { erase } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';

import type { BaseControllerType } from '../types';
import { columns, useColumns } from './useColumns';

export interface ControllerProps extends BaseControllerType {}

export function useController(apiParams: ControllerProps) {
  const {
    assets,
    error,
    isLoading: isAssetsLoading,
    updateAssets,
    resetBabCCByAssetIds,
  } = aggregated.useAssets(apiParams);

  const {
    assetCategoryConfigs,
    updateAssetConfig,
    isLoading: isAssetConfigsLoading,
  } = aggregated.useAssetConfigs(apiParams);

  const { masterConfiguration, updateMasterConfiguration: updateConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const { year } = aggregated.useYear(apiParams);

  const updateSelectedWiberaFileYear = useCallback(
    async (wiberaYear: number) => {
      await updateConfiguration({
        ...masterConfiguration,
        wiberaConfig: {
          ...masterConfiguration?.wiberaConfig,
          configYear: wiberaYear,
        },
      });
    },
    [updateConfiguration, masterConfiguration],
  );

  const updateNbrOfYearsForFactorsCalculation = async (value: number) => {
    await updateConfiguration({
      ...masterConfiguration,
      wiberaConfig: {
        ...masterConfiguration?.wiberaConfig,
        nbrOfYearsForFactorsCalculation: value,
      },
    });
  };

  const handleImputedAssetActivated = useCallback(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      await updateConfiguration({
        ...masterConfiguration,
        assetCostCenterConfig: {
          enableAssetImputedUsageTimeConfig: checked,
        },
      });
    },
    [masterConfiguration, updateConfiguration],
  );

  const handleWiberaDepreciationActivated = useCallback(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      await updateConfiguration({
        ...masterConfiguration,
        wiberaConfig: {
          ...masterConfiguration?.wiberaConfig,
          applyReplacementValueDepreciation: checked,
        },
      });
    },
    [masterConfiguration, updateConfiguration],
  );

  const enableAssetImputedUsageTimeConfig =
    !!masterConfiguration?.assetCostCenterConfig?.enableAssetImputedUsageTimeConfig;

  const enableWiberaDepreciationConfig =
    !!masterConfiguration?.wiberaConfig?.applyReplacementValueDepreciation;

  const tableRef = useRef<AgGridReact>(null);

  const onCellValueChange = useCallback(
    async (event: CellValueChangedEvent) => {
      const colName = event.colDef.field;

      if (!colName) {
        return;
      }

      if (event.source === 'defaultCategoryCheckbox' || event.source === 'operatingCheckbox') {
        return;
      }

      const isGroupNode = event.node?.group;
      let updatedRows: erase.Asset[];
      // update row groups
      if (colName && isGroupNode && event.node?.level === 0) {
        updatedRows = (event.node?.childrenAfterGroup ?? []).map((row) => ({
          ...row.data,
          [colName]: event.data[colName],
        }));
      } else {
        updatedRows = [event.node.data!];
      }

      if (colName === columns.currentBabCCShortName.$path) {
        const { currentBabCCLongName, currentBabCCShortName } = event.data;
        updatedRows = updatedRows.map((row) => ({
          ...row,
          clientCCNameByLabel: '',
          currentBabCCLongName,
          currentBabCCShortName,
          currentCcMappingStatus: 'MANUAL',
        }));
      }

      await updateAssets(updatedRows);
    },
    [updateAssets],
  );

  const resetBabCc = useCallback(
    async (assetsToReset: erase.Asset | erase.Asset[]) => {
      const assetsAsArray = Array.isArray(assetsToReset) ? assetsToReset : [assetsToReset];

      const assetIds = assetsAsArray
        .filter(({ currentCcMappingStatus }) => currentCcMappingStatus === 'MANUAL')
        .map((asset) => asset._id)
        .filter(String) as string[];

      await resetBabCCByAssetIds(assetIds);
    },
    [resetBabCCByAssetIds],
  );

  const columnDefs = useColumns({
    apiParams,
    assetCategoryConfigs,
    enableAssetImputedUsageTimeConfig,
    enableWiberaDepreciationConfig,
    onRefresh: resetBabCc,
    updateAssetConfig,
    updateAssets,
  });

  const getRowId = useCallback(
    (params: GetRowIdParams) => `${params.data.assetNumber}:${params.data.assetType}`,
    [],
  );

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: assets,
  });

  return {
    ...apiParams,
    agGridProps,
    columnDefs,
    currentYear: Number(year?.year),
    enableAssetImputedUsageTimeConfig,
    enableWiberaDepreciationConfig,
    error,
    getRowId,
    handleImputedAssetActivated,
    handleWiberaDepreciationActivated,
    isLoading: isAssetsLoading || isAssetConfigsLoading,
    onCellValueChange,
    tableRef,
    updateNbrOfYearsForFactorsCalculation,
    updateSelectedWiberaFileYear,
    wiberaConfigYear: Number(masterConfiguration?.wiberaConfig.configYear),
    wiberaYearFactorNum: masterConfiguration?.wiberaConfig.nbrOfYearsForFactorsCalculation,
  };
}

export type ControllerType = ReturnType<typeof useController>;
