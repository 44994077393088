import type { CellEditingStoppedEvent, ColDef, GetRowIdFunc } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef } from 'react';

import type { dice } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { toast, useDeleteRowConfirmModal } from '@org/ui';
import { getCopyOfTableData } from '@org/utils';

import type { BaseControllerType } from '..';
import type { UseColumnsProps } from './useColumns';
import { useColumns } from './useColumns';

const getRowId: GetRowIdFunc<dice.TechnicalDataDTO> = ({ data }) => data.id!;

interface UseControllerProps extends BaseControllerType {}

const defaultColDef: ColDef = {
  cellRendererParams: {
    suppressCount: true,
  },
  editable: true,
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
  sortable: false,
  suppressMovable: true,
};

export const useController = (apiParams: UseControllerProps) => {
  const {
    technicalData,
    createTechnicalDataEntry,
    deleteTechnicalDataEntries,
    updateTechnicalDataEntries,
    updateTechnicalDataEntry,
  } = aggregated.useTechnicalData(apiParams);
  const { t } = useTranslation();
  const agGridRef = useRef<AgGridReact>(null);

  const handleDelete = useCallback(
    async (ids: string[]) => {
      try {
        await deleteTechnicalDataEntries(ids);
      } catch (error) {
        const castedError = error as {
          error: string;
          message: string;
        };
        if (typeof castedError?.error === 'string') {
          toast.error(
            t(`main:manageMenu.manageTechnicalData.delete.${castedError.error}`, {
              defaultValue: castedError.message,
            }),
          );
        }
      }
    },
    [deleteTechnicalDataEntries, t],
  );

  const handleChangeEntries = useCallback(
    async (data: dice.TechnicalDataDTO[]) => {
      try {
        await updateTechnicalDataEntries(data);
      } catch (error) {
        const castedError = error as {
          error: string;
          message: string;
        };
        if (typeof castedError?.error === 'string') {
          toast.error(
            t(`main:manageMenu.manageTechnicalData.put.${castedError.error}`, {
              defaultValue: castedError.message,
            }),
          );
        }
      }
    },
    [t, updateTechnicalDataEntries],
  );

  const handleChange = useCallback(
    async (event: CellEditingStoppedEvent) => {
      try {
        const {
          data,
          node: { field: nodeField },
          newValue,
          colDef: { colId },
        } = event;

        if (nodeField === 'name') {
          const affectedChildren = (getCopyOfTableData(event.api) as dice.TechnicalDataDTO[])
            .filter((item) => item[colId! as 'name' | 'unit'] === newValue)
            .map((rowNode) => ({
              ...rowNode,
              [colId!]: newValue,
            }));

          await handleChangeEntries(affectedChildren);
        } else {
          await updateTechnicalDataEntry({ id: data.id, body: data });
        }
      } catch (error) {
        const castedError = error as {
          error: string;
          message: string;
        };
        if (typeof castedError?.error === 'string') {
          toast.error(
            t(`main:manageMenu.manageTechnicalData.put.${castedError.error}`, {
              defaultValue: castedError.message,
            }),
          );
        }
      }
    },
    [handleChangeEntries, t, updateTechnicalDataEntry],
  );

  const { openModal, modal } = useDeleteRowConfirmModal({
    onConfirm: handleDelete,
  });

  const agGridProps = useAgGridData({
    agGridRef,
    data: technicalData,
  });

  const handleAddItem: UseColumnsProps['addItem'] = useCallback(
    async (props) => {
      await createTechnicalDataEntry(props);
    },
    [createTechnicalDataEntry],
  );

  const handleAddSubGroup: UseColumnsProps['addNewSubGroup'] = useCallback(
    async (groupType) => {
      if (technicalData?.some(({ type, name }) => type === groupType && name === '')) {
        return;
      }

      return await handleAddItem({
        name: '',
        type: groupType,
      });
    },
    [handleAddItem, technicalData],
  );

  const handleDeleteItem: UseColumnsProps['deleteItem'] = useCallback(
    (id) => {
      openModal([id]);
    },
    [openModal],
  );

  const handleDeleteSubGroup: UseColumnsProps['deleteSubGroup'] = useCallback(
    (groupType, groupName) => {
      const ids = technicalData?.flatMap(({ id, type, name }) => {
        if (groupType === type && groupName === name && id) {
          return [id];
        }
        return [];
      });

      if (!ids?.length) {
        return;
      }
      openModal(ids);
    },
    [openModal, technicalData],
  );

  const handleAddGroup = useCallback(
    (type: dice.TechnicalDataDTO['type']) => async () => {
      await handleAddItem({ name: '', type });
    },
    [handleAddItem],
  );

  const columnDefs = useColumns({
    addItem: handleAddItem,
    addNewSubGroup: handleAddSubGroup,
    apiParams,
    deleteItem: handleDeleteItem,
    deleteSubGroup: handleDeleteSubGroup,
  });

  return {
    agGridProps,
    agGridRef,
    columnDefs,
    defaultColDef,
    getRowId,
    handleAddGroup,
    handleChange,
    isCostCenterGroupMissing: !technicalData?.some(({ type }) => type === 'COST_CENTER'),
    isCostUnitGroupMissing: !technicalData?.some(({ type }) => type === 'COST_UNIT'),
    modal,
  };
};

export type ViewProps = ReturnType<typeof useController>;
