import type {
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGroupSelectionState,
} from 'ag-grid-community';

import type { TFunction } from '@org/locales';
import type { aggregated } from '@org/query';
import { dice } from '@org/query';
import { showErrorCamsNotification, showNotification } from '@org/ui';

import type { InitialFilterMap } from './utils';
import {
  createFilterStructure,
  getSelectedNodes,
  getSelectedNodesTreeRoot,
  setNodesInSelectedStructure,
} from './utils';

let initialData: Map<string, InitialFilterMap> | undefined;

interface ApiFilterParams extends dice.GetImportAssetFiltersQueryParams {
  apiFileType: Extract<aggregated.ApiFileType, 'ASSETS' | 'JOURNAL' | 'LEDGER_ACCOUNT'>;
  isPostCalculation: boolean;
}

export function clearServerSideCache(api: GridApi) {
  initialData = undefined;
  api.refreshServerSide({ purge: true });
}

export function createServerSideDatasource(apiParams: ApiFilterParams, t: TFunction) {
  const dataSource: IServerSideDatasource = {
    getRows: async (params: IServerSideGetRowsParams) => {
      try {
        if (initialData === undefined) {
          let fetchedData;
          if (apiParams.apiFileType === 'ASSETS') {
            fetchedData = await dice.fetchGetImportAssetFilters({
              queryParams: apiParams,
            });
          } else if (apiParams.apiFileType === 'LEDGER_ACCOUNT') {
            fetchedData = await dice.fetchGetImportLedgerAccountFilters({
              queryParams: apiParams,
            });
          } else {
            fetchedData = await dice.fetchGetImportJournalFilters({
              queryParams: apiParams,
            });
          }

          const { xInternalErrorCode } = fetchedData;

          if (xInternalErrorCode !== 200) {
            params.success({
              rowData: [],
              rowCount: 0,
            });

            showErrorCamsNotification(t, fetchedData);
            return;
          }

          initialData = createFilterStructure(fetchedData);
          const rowData = Array.from(initialData.values());

          // costCenterSetKeys labels and other in this data set
          params.success({
            rowData,
            rowCount: rowData.length,
          });

          const parentNodes: IServerSideGroupSelectionState[] = [];

          rowData.forEach((row) => {
            const { children, id } = row;

            if (children.length > 0) {
              const selectedAll = children.every((child) => child.isSelected === 'FULLY');

              if (selectedAll) {
                parentNodes.push({
                  nodeId: id ?? '',
                  selectAllChildren: true,
                  toggledNodes: [],
                });
              } else {
                const selectedNodes = getSelectedNodes(children) ?? [];

                if (selectedNodes.length > 0) {
                  parentNodes.push({
                    nodeId: id ?? '',
                    selectAllChildren: false,
                    toggledNodes: selectedNodes,
                  });
                }
              }
            }

            const selectionStateTree = getSelectedNodesTreeRoot();
            selectionStateTree.toggledNodes = parentNodes;

            params.api.setServerSideSelectionState(
              selectionStateTree as IServerSideGroupSelectionState,
            );
          });
        } else {
          const groupKey = params.request.groupKeys.at(-1);
          const parentNodeId = params.parentNode.id ?? '';

          // check whether there is data in ag grid
          // getServerSideGroupLevelState - returns number of rendered groups, there is also 1 default and at least 1 for initial labels
          if (params.api.getServerSideGroupLevelState()?.length < 2) {
            clearServerSideCache(params.api);
            return;
          }

          if (Array.from(initialData.keys()).includes(groupKey ?? '')) {
            const initialDataList = Array.from(initialData.values());
            const treePart = initialDataList.find((item) => item.nodeKey === groupKey);

            const sortedTreePart = treePart?.children ?? [];

            params.success({
              rowData: sortedTreePart,
              rowCount: sortedTreePart.length,
            });
          } else {
            let ccData;
            if (apiParams.apiFileType === 'ASSETS') {
              ccData = await dice.fetchGetCostCenterChildrenAsset({
                body: {
                  clientId: apiParams.esraClientId,
                  calculationYear: Number(apiParams.calculationYear),
                  selectedRoot: parentNodeId,
                  yearId: apiParams.yearCalculationId,
                },
              });
            } else if (apiParams.apiFileType === 'LEDGER_ACCOUNT') {
              ccData = await dice.fetchGetCostCenterChildrenLedger({
                body: {
                  clientId: apiParams.esraClientId,
                  calculationYear: Number(apiParams.calculationYear),
                  selectedRoot: parentNodeId,
                  yearId: apiParams.yearCalculationId,
                },
              });
            } else {
              ccData = await dice.fetchGetCostCenterChildrenJournal({
                body: {
                  clientId: apiParams.esraClientId,
                  calculationYear: Number(apiParams.calculationYear),
                  selectedRoot: parentNodeId,
                  yearId: apiParams.yearCalculationId,
                },
              });
            }

            const toggleValue =
              initialData.get('costCenterSetss')?.toggle ??
              initialData.get('costCenterSetKeys')?.toggle;
            const rowData =
              ccData?.map(({ nodeKey, id, isGroup, isSelected }) => ({
                nodeKey,
                id,
                isGroup,
                isSelected,
                toggle: toggleValue,
              })) ?? [];

            params.success({
              rowData,
              rowCount: rowData.length,
            });

            const currentSelectionState =
              params.api.getServerSideSelectionState() as IServerSideGroupSelectionState;
            const selectedNodes = getSelectedNodes(rowData) as IServerSideGroupSelectionState[];

            const selectionStateTree = setNodesInSelectedStructure(
              currentSelectionState,
              parentNodeId,
              selectedNodes,
            );

            params.api.setServerSideSelectionState(
              selectionStateTree as IServerSideGroupSelectionState,
            );
          }
        }
      } catch {
        params.success({
          rowData: [],
          rowCount: 0,
        });
        showNotification(
          'error',
          t(
            'common:modals.camsImport.errors.NO_DATA_FOUND_FOR_ESRA_CLIENT_ID_AND_CALCULATION_YEAR',
          ),
          5000,
        );
      }
    },
  };
  return dataSource;
}
