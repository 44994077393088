import type { griddy } from '@org/query';

import type { IgnoreMappings, Job } from '../types';
import type { useLocalState } from './useLocalState';
import type { useProfitCenterTree } from './useProfitCenterTree';
import {
  areCostCentersIgnored,
  areJobsIgnored,
  areProfitCentersIgnored,
  conditionalFilter,
  formatName,
  getCorrectCostCenters,
  getCorrectJobs,
  isValidCostCenterMapping,
  iterableToArray,
  sort,
} from '../helpers';

export interface UseDerivedStateProps
  extends ReturnType<typeof useProfitCenterTree>,
    ReturnType<typeof useLocalState> {
  ignoreMappings: IgnoreMappings;
  checkedJobs: Map<string, griddy.MappingKey>;
  isJobChecked: (job: Job) => boolean;
}

export const useDerivedState = ({
  selectedCostCenterName,
  availableProfitCenters,
  costCenterMap,
  selectedProfitCenterName,
  costCenterSearch,
  jobIdSearch,
  profitCenterSearch,
  unavailableProfitCenters,
  ignoreMappings,
  checkedJobs,
  isJobChecked,
}: UseDerivedStateProps) => {
  const ignoreProfitCenters = areProfitCentersIgnored({
    ignoreMappings,
  });

  const ignoreCostCenters = areCostCentersIgnored({
    ignoreMappings,
    selectedProfitCenterName,
  });

  const ignoreJobIds = areJobsIgnored({
    ignoreMappings,
    selectedCostCenterName: costCenterMap?.get(selectedCostCenterName)?.name,
    selectedProfitCenterName,
  });

  const costCenters = getCorrectCostCenters(
    availableProfitCenters,
    unavailableProfitCenters,
    ignoreProfitCenters,
    selectedProfitCenterName,
  );

  const jobs = getCorrectJobs(
    costCenters.availableCostCenters,
    costCenters.unavailableCostCenters,
    costCenterMap,
    ignoreCostCenters,
    selectedCostCenterName,
  );

  const availableCostCenters = sort(costCenters.availableCostCenters, formatName);
  const unavailableCostCenters = sort(costCenters.unavailableCostCenters, formatName);

  const availableJobs = sort(jobs.availableJobs, formatName);
  const unavailableJobs = sort(jobs.unavailableJobs, formatName);

  const allAvailableProfitCenters = iterableToArray(availableProfitCenters?.values());
  const allAvailableProfitCentersJobs = allAvailableProfitCenters.flatMap(
    ({ availableJobs: availableProfitCentersJobs }) => [...availableProfitCentersJobs],
  );

  const allProfitCenters = [
    ...allAvailableProfitCenters,
    ...(unavailableProfitCenters?.values() ?? []),
  ];

  const allSelectedCostCenters = [...availableCostCenters, ...unavailableCostCenters];

  const allSelectedJobs = [...availableJobs, ...unavailableJobs];

  const profitCentersWithFilter = conditionalFilter(
    allProfitCenters,
    formatName,
    profitCenterSearch,
  );

  const costCentersWithFilter = conditionalFilter(
    allSelectedCostCenters,
    formatName,
    costCenterSearch,
  );
  const jobsWithFilter = conditionalFilter(allSelectedJobs, formatName, jobIdSearch);

  const allJobsSelected =
    ignoreJobIds || (availableJobs.length > 0 && availableJobs.every(isJobChecked));

  const someJobsSelected = !allJobsSelected && availableJobs.some(isJobChecked);

  const allCostCentersSelected =
    availableCostCenters.length > 0 &&
    availableCostCenters
      .flatMap(({ availableJobs: costCenterAvailableJobs }) =>
        iterableToArray(costCenterAvailableJobs),
      )
      .every(isJobChecked);

  const someCostCentersSelected =
    !allCostCentersSelected &&
    availableCostCenters
      .flatMap(({ availableJobs: costCenterAvailableJobs }) =>
        iterableToArray(costCenterAvailableJobs),
      )
      .some(isJobChecked);

  const allProfitCentersSelected = allAvailableProfitCentersJobs.every(isJobChecked);

  const someProfitCentersSelected =
    !allProfitCentersSelected && allAvailableProfitCentersJobs.some(isJobChecked);

  const isIgnoreProfitCenterDisabled = allAvailableProfitCenters.length === 0;

  const isIgnoreCostCenterDisabled = availableCostCenters.length === 0;

  const isIgnoreJobsDisabled = unavailableJobs.length > 0;

  const isValidConfiguration = isValidCostCenterMapping(ignoreMappings, checkedJobs);

  return {
    allAvailableProfitCenters,
    allAvailableProfitCentersJobs,
    allCostCentersSelected,
    allJobsSelected,
    allProfitCenters,
    allProfitCentersSelected,
    allSelectedCostCenters,
    allSelectedJobs,
    availableCostCenters,
    availableJobs,
    costCentersWithFilter,
    ignoreCostCenters,
    ignoreJobIds,
    ignoreProfitCenters,
    isIgnoreCostCenterDisabled,
    isIgnoreJobsDisabled,
    isIgnoreProfitCenterDisabled,
    isValidConfiguration,
    jobsWithFilter,
    profitCentersWithFilter,
    someCostCentersSelected,
    someJobsSelected,
    someProfitCentersSelected,
    unavailableCostCenters,
    unavailableJobs,
  };
};
