import { useEffect, useRef, useState } from 'react';

const useDidMountEffect = (toExecute, onChangeOf) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      toExecute();
    } else {
      didMount.current = true;
    }
  }, onChangeOf);
};

function useLocalStorage(key, initialValue, parseValue = (v) => v) {
  const [item, setValue] = useState(() => {
    const value = parseValue(localStorage.getItem(key)) || initialValue;
    localStorage.setItem(key, value);
    return value;
  });

  const setItem = (newValue) => {
    setValue(newValue);
    window.localStorage.setItem(key, newValue);
  };

  return [item, setItem];
}

export function useSetPersistentTimeout(name, fn, timeout) {
  const [callfn, setCallfn] = useState(false);
  const [lapse, setLapse] = useLocalStorage(`${name}-nanoedmo:time`, 0, Number);
  const [running, setRunning] = useLocalStorage(
    `${name}-nanoedmo:running`,
    false,
    (string) => string === 'true',
  );
  const loggedInAt = sessionStorage.getItem('time');

  useEffect(() => {
    // round to the nearest second
    const timer = setInterval(() => {
      if (running) {
        setLapse(Date.now() - loggedInAt);
      }
      // round to the nearest second
    }, 1000);

    if (lapse >= timeout) {
      setCallfn(true);
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [running, lapse, setLapse, timeout, loggedInAt]);

  useDidMountEffect(() => {
    fn();
  }, [callfn]);

  const startTimer = () => {
    setRunning(true);
  };

  return [startTimer];
}
