import type {
  CellValueChangedEvent,
  ColDef,
  ColumnFunctionCallbackParams,
  PostSortRowsParams,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef, useState } from 'react';

import nameOf from 'easy-tsnameof';

import type { griddy } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import { useAgGridData, useDebounce, useEvent, useLoadingAction } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated, dice } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';
import {
  costCenterTypes,
  generateGroupRowName,
  getCellClassesForGroups,
  getLastGroupItem,
  GROUP,
  prepareGroupRowNamesFromGridAPI,
} from '@org/utils';

import type { BaseControllerType } from '../types';
import type { CostCenterMappings } from './types';
import { CustomGroupCellRenderer } from './components';
import {
  convertMappingObjectToArrays,
  createRow,
  isGroupEditable,
  isValidCCcostCenterTypeOrShowNotif,
  isValidCCLongNameOrShowNotif,
  isValidCCMappingsOrShowNotif,
  isValidCCShortNameOrShowNotif,
  ValidationError,
} from './helpers';
import { getItemsInCostType } from './helpers/getItemsInCostType';
import { useDnD } from './hooks';
import { useAutomaticCCCreation } from './useAutomaticCCCreation';
import { useColumns } from './useColumns';

export interface UseControllerProperties extends BaseControllerType {}

const columnNames = nameOf<griddy.CostCenter, never>();

const prepareUpdate = <T extends CostCenterMappings>(updatedCostCenter: T): T => {
  const updatedRow = convertMappingObjectToArrays(updatedCostCenter);
  updatedRow.assignCostCenterMappings = updatedRow.mappingKeys!.length > 0;
  return updatedRow as T;
};

const isRowSelectable = <T extends ColumnFunctionCallbackParams>(params: T) => {
  const isGroup = params.node.group;
  return !isGroup;
};

export const useController = (apiParams: UseControllerProperties) => {
  const { masterConfigurationId: configId, yearId } = apiParams;
  const { t } = useTranslation();
  const tableRef = useRef<AgGridReact>(null);
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const { createAutomaticCCs } = useAutomaticCCCreation();
  const { updateCostCenter, addCostCenter, deleteCostCenter } =
    aggregated.useUpdateCostCenters(apiParams);

  const { mutateAsync: updateConfig } = dice.useProcessConfig();
  const { isLoading, asyncAction, asyncActionDirect } = useLoadingAction();
  const [costCenterTypesExist, setCostCenterTypesExist] = useState({
    [costCenterTypes.PRIMARY_COST_CENTER.key]: false,
    [costCenterTypes.INDIRECT_COST_CENTER.key]: false,
  });

  const updateAssetCCMapping = useCallback(async () => {
    await updateConfig({
      body: {
        masterConfigId: configId,
        yearId,
      },
      queryParams: {
        'config-type': 'cc-mapping',
      },
    });
  }, [configId, updateConfig, yearId]);

  const { getRowId, onRowDragMove, onRowDragEnd, onGridReady, rowData, moveGroup } = useDnD({
    masterConfiguration,
    setCostCenterTypesExist,
    updateAssetCCMapping,
  });

  const updateMasterConfigurationWithDeps = useCallback(
    async (updatedConfig: dice.MasterConfiguration) => {
      await updateMasterConfiguration(updatedConfig);
      await updateAssetCCMapping();

      showNotification('success', t('main:manageMenu.alerts.success.updatedRow'));
    },
    [t, updateAssetCCMapping, updateMasterConfiguration],
  );

  const deleteGroupRow = useCallback(
    async ({ gridProps: { node, api: gridApi } }: ButtonCellRendererProps) => {
      let masterConfigurationConfig = masterConfiguration ?? ({} as griddy.MasterConfiguration);
      const { childrenAfterGroup } = node;
      const childrenRowData: CostCenterMappings[] = (childrenAfterGroup ?? []).map((row) => {
        const { data } = row;
        return data;
      });

      if (childrenRowData?.length) {
        gridApi.applyTransaction({
          remove: childrenAfterGroup,
        });

        childrenRowData.forEach((cc) => {
          masterConfigurationConfig = deleteCostCenter(cc, masterConfigurationConfig);
        });
        await asyncActionDirect(() => updateMasterConfigurationWithDeps(masterConfigurationConfig));
      }
    },
    [asyncActionDirect, deleteCostCenter, masterConfiguration, updateMasterConfigurationWithDeps],
  );

  const deleteRow = useCallback(
    async ({ gridProps: { node, api: gridApi } }: ButtonCellRendererProps) => {
      const masterConfigurationConfig = masterConfiguration ?? ({} as griddy.MasterConfiguration);

      gridApi.applyTransaction({
        remove: [node],
      });
      const masterConfigurationConfigWithoutDeletedCC = deleteCostCenter(
        node.data,
        masterConfigurationConfig,
      );

      await asyncActionDirect(() =>
        updateMasterConfigurationWithDeps(masterConfigurationConfigWithoutDeletedCC),
      );
    },
    [asyncActionDirect, deleteCostCenter, masterConfiguration, updateMasterConfigurationWithDeps],
  );

  const handleDelete = useCallback(
    async (btnProps: ButtonHandlerProps) => {
      const { type, ...props } = btnProps;
      if (type === GROUP) {
        await deleteGroupRow(props);
      } else {
        await deleteRow(props);
      }
    },
    [deleteGroupRow, deleteRow],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonHandlerProps>({
    onConfirm: handleDelete,
  });

  const addNewGroupToCostCenterType = useCallback(
    async (costType: griddy.CostCenter['costCenterType']) => {
      const masterConfigurationConfig = masterConfiguration ?? ({} as griddy.MasterConfiguration);
      const newGroupName = generateGroupRowName(
        t('main:manageMenu.manageImputedWithdrawalCapital.newGroup'),
        prepareGroupRowNamesFromGridAPI(tableRef.current?.api, 'group', 1),
      );

      let groupItemRank = 1;
      if (costType === 'PRIMARY_COST_CENTER') {
        const indirectItems = getItemsInCostType(
          tableRef.current?.api,
          costCenterTypes.INDIRECT_COST_CENTER.key,
        );
        if (indirectItems.length > 0) {
          const lastIndirectItem = indirectItems.at(-1);
          groupItemRank = Number(lastIndirectItem?.rank) + 1;
        }
      }
      const updatedMasterConfiguration = addCostCenter(
        {
          assignCostCenterMappings: false,
          costCenterType: costType,
          group: newGroupName,
          longName: newGroupName,
          rank: groupItemRank,
          shortName: crypto.randomUUID(),
        },
        masterConfigurationConfig,
      );
      await asyncActionDirect(() => updateMasterConfigurationWithDeps(updatedMasterConfiguration));
    },
    [addCostCenter, asyncActionDirect, masterConfiguration, t, updateMasterConfigurationWithDeps],
  );

  const addNewRow = useCallback(
    async ({ gridProps: { api: gridApi, node } }: ButtonCellRendererProps) => {
      const masterConfigurationConfig = masterConfiguration ?? ({} as griddy.MasterConfiguration);
      const { parent, key: nodeKey, childrenAfterGroup } = node;
      const newGroupName = generateGroupRowName(
        t('main:manageMenu.manageImputedWithdrawalCapital.newGroup'),
        prepareGroupRowNamesFromGridAPI(gridApi, 'group', 1),
      );

      let groupItemRank = 1;
      if (childrenAfterGroup) {
        const lastGroup = childrenAfterGroup.at(-1);
        const lastParentChild = getLastGroupItem(lastGroup);
        groupItemRank = Number(lastParentChild?.data.rank) + 1;
      }
      let newRow: CostCenterMappings;
      // add new row to existing group
      if (parent?.rowIndex !== null) {
        newRow = createRow({
          group: nodeKey!,
          type: parent?.key as griddy.CostCenter['costCenterType'],
          shortName: crypto.randomUUID(),
          longName: 'New cost center',
          rank: groupItemRank,
        });
      } else {
        const data = {
          group: newGroupName,
          longName: newGroupName,
          rank: groupItemRank,
          shortName: crypto.randomUUID(),
          type: nodeKey as griddy.CostCenter['costCenterType'],
        };

        newRow = createRow(data);
      }

      gridApi.applyTransaction({
        add: [newRow],
      });

      const updatedMasterConfiguration = addCostCenter(
        {
          assignCostCenterMappings: false,
          costCenterType: nodeKey as griddy.CostCenter['costCenterType'],
          ...newRow,
        },
        masterConfigurationConfig,
      );

      await asyncActionDirect(() => updateMasterConfigurationWithDeps(updatedMasterConfiguration));
    },
    [addCostCenter, asyncActionDirect, masterConfiguration, t, updateMasterConfigurationWithDeps],
  );

  const updateCostCenterRow = (
    newRow: CostCenterMappings,
    customMasterConfiguration: dice.MasterConfiguration,
  ) => {
    if (
      !(
        isValidCCShortNameOrShowNotif(newRow, t) &&
        isValidCCLongNameOrShowNotif(newRow, t) &&
        isValidCCcostCenterTypeOrShowNotif(newRow, t)
      )
    ) {
      throw new ValidationError();
    }

    const updatedRowWithOldMappings = prepareUpdate(newRow);
    if (!isValidCCMappingsOrShowNotif(updatedRowWithOldMappings, t)) {
      throw new ValidationError();
    }

    return updateCostCenter(updatedRowWithOldMappings, customMasterConfiguration);
  };

  const onChange = useEvent(async (event: CellValueChangedEvent) => {
    const masterConfigurationConfig = masterConfiguration ?? ({} as griddy.MasterConfiguration);

    const {
      colDef: { field: colName },
      node: { field: nodeField },
      data,
      newValue,
    } = event;

    if (!colName) {
      return;
    }

    if (nodeField === 'group') {
      await onGroupCellChange(event, colName);
    } else {
      const isMappingObject = colName === 'mappingObject';

      const newRowData: CostCenterMappings = isMappingObject ? { ...data, ...newValue } : data;

      const updatedMasterConfiguration = updateCostCenterRow(newRowData, masterConfigurationConfig);

      if (isMappingObject) {
        // mapping object needs to be saved without spinner
        if (newValue?.isValid) {
          await updateMasterConfigurationWithDeps(updatedMasterConfiguration);
        }
      } else {
        await asyncActionDirect(() =>
          updateMasterConfigurationWithDeps(updatedMasterConfiguration),
        );
      }
    }
  });

  const onGroupCellChange = asyncAction(async (event: CellValueChangedEvent, colName: string) => {
    const {
      node: { childrenAfterGroup },
      data: eventData,
    } = event;
    const childrenRowData: CostCenterMappings[] = (childrenAfterGroup ?? []).map((row) => {
      const { data } = row;
      data.group = eventData[colName];
      return data;
    });

    if (childrenRowData?.length) {
      let masterConfigurationConfig = masterConfiguration ?? ({} as griddy.MasterConfiguration);

      childrenRowData.forEach((cc) => {
        masterConfigurationConfig = updateCostCenterRow(cc, masterConfigurationConfig);
      });
      await updateMasterConfigurationWithDeps(masterConfigurationConfig);
    }
  });

  const createAutomaticCC = asyncAction(
    useCallback(
      async (creationType: griddy.CreateBabCostCenterPathParams['creationType']) => {
        await createAutomaticCCs({
          creationType,
          masterConfigId: configId,
          yearId,
        });
      },
      [createAutomaticCCs, configId, yearId],
    ),
  );

  const moveGroupAsyncAction = useDebounce(
    asyncAction(
      useCallback(
        async (props, direction) => {
          const tableData = moveGroup(props, direction);
          const tableWithoutRowId = tableData?.map(({ id: _, ...other }) => other);
          if (tableData?.length) {
            await updateMasterConfiguration({
              ...masterConfiguration,
              costCenterConfig: { costCenterMapping: tableWithoutRowId },
            });
            await updateAssetCCMapping();
          }
        },
        [masterConfiguration, moveGroup, updateMasterConfiguration, updateAssetCCMapping],
      ),
    ),
    15,
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererSelector: ({ node: { group } }) => {
        if (group) {
          return { component: CustomGroupCellRenderer };
        } else {
          return { component: 'agGroupCellRenderer' };
        }
      },
      checkboxSelection: isRowSelectable,
      editable: ({ node }) => isGroupEditable(node),
      field: columnNames.longName.$path,
      flex: 3,
      headerName: t('main:manageMenu.manageCostCenters.tableColumns.costCenter'),
      rowDrag: isRowSelectable,
    }),
    [t],
  );

  const columnDefs = useColumns({
    addNewRow,
    handleDeleteModal,
    moveRow: moveGroupAsyncAction,
    yearId,
  });

  const detailCellRendererParams = useMemo(
    () => ({
      apiParams,
    }),
    [apiParams],
  );

  const postSortRow = useCallback((params: PostSortRowsParams) => {
    // manually re-sort cost center types if necessary
    const rowNodes = params.nodes.filter((node) => node.field === 'costCenterType');
    if (rowNodes.length === 2 && rowNodes[1].key === costCenterTypes.INDIRECT_COST_CENTER.key) {
      const [firstRow, secondRow] = rowNodes;
      params.nodes[0] = secondRow;
      params.nodes[1] = firstRow;
    }
  }, []);

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: rowData,
    onGridReady,
  });

  return {
    addNewGroupToCostCenterType,
    agGridProps,
    apiParams,
    autoGroupColumnDef,
    columnDefs,
    configId,
    costCenterTypesExist,
    createAutomaticCC,
    detailCellRendererParams,
    getRowId,
    handleDelete,
    isLoading,
    modal,
    onChange,
    onRowDragEnd,
    onRowDragMove,
    postSortRow,
    tableRef,
    yearId,
  };
};

export type ControllerType = ReturnType<typeof useController>;
