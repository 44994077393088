import type React from 'react';

import { useDisclosure } from '@nextui-org/react';

import { aggregated } from '@org/query';

import type { BaseControllerType } from '..';

export interface UseControllerProps
  extends Omit<BaseControllerType, 'masterConfigurationId' | 'yearId' | 'clientId' | 'type'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LinkComponent: React.ComponentType<any>;
  publicFacilityId: string;
  getLinkProps: ({
    type,
    yearCalculationId,
  }: {
    type: string;
    yearCalculationId: string;
  }) => Record<string, unknown>;
}

export const useController = ({
  LinkComponent,
  getLinkProps,
  publicFacilityId,
}: UseControllerProps) => {
  const disclosureState = useDisclosure();

  const { years, createYear, deleteYear } = aggregated.useYears({
    publicFacilityId,
  });

  const sortedYears = years?.sort(
    (a, b) => Number.parseInt(b.year ?? '') - Number.parseInt(a.year ?? ''),
  );

  return {
    LinkComponent,
    createYear,
    deleteYear,
    disclosureState,
    getLinkProps,
    publicFacilityId,
    years: sortedYears ?? [],
  };
};

export type ControllerType = ReturnType<typeof useController>;
