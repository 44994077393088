import type { CellClassParams, ColDef, ColGroupDef, IAggFunc } from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import {
  addBoldTextClassForFirstLevelRows,
  addPriceClassForCorrectType,
  findRoot,
  generateColumnGroups,
} from '@org/utils';

const aggFunc: IAggFunc = (params) => {
  if (params.rowNode.level === 1) {
    return (params.values.at(0) ?? 0) - (params.values.at(1) ?? 0);
  }

  let sum = 0;
  params.values.forEach((item = 0) => {
    sum = sum + item;
  });

  return sum;
};

const getHighlightedClass = ({ node }: CellClassParams) =>
  node?.level <= 1 ? 'bg-highlighted' : '';

const getBackgroundClass = ({ node }: CellClassParams) => {
  const { level: nodeLevel } = node;
  if (nodeLevel >= 1) {
    const { childIndex: rootChildNodeIndex } = findRoot(node, 1);
    return rootChildNodeIndex % 2 ? 'gray-lightest-background' : 'white-background';
  }
  return '';
};

const cellClass = (props: CellClassParams) => [
  getHighlightedClass(props),
  getBackgroundClass(props),
];

export const defaultColDef: ColDef = {
  aggFunc: 'sum',
  cellClass,
  cellRendererParams: {
    suppressCount: true,
  },
  flex: 1,
  minWidth: 100,
  resizable: true,
  sortable: false,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  tooltipValueGetter: (params) => params.value,
  width: 100,
};

interface UseControllerProps {
  report?: aggregated.ExtendedReportObjectsResponse;
  apiParams: APIParams<'masterConfigurationId'>;
}

export const useColumns = ({ report, apiParams }: UseControllerProps) => {
  const { t } = useTranslation();
  const { getCustomLabel } = aggregated.useMasterConfiguration(apiParams);

  const { indirectCostCentersGrouped, primaryCostCentersGrouped } = report ?? {};

  return useMemo(
    () =>
      [
        {
          children: [
            {
              cellClass: (params) => [
                ...cellClass(params),
                addBoldTextClassForFirstLevelRows(params),
              ],
              cellRenderer: 'agGroupCellRenderer',
              colId: 'rowName',
              flex: 1,
              headerName: '',
              minWidth: 200,
              pinned: 'left',
              showRowGroup: true,
              valueGetter: ({ node }) =>
                getCustomLabel(node?.data?.rowName) ??
                t(`main:reportPanels.tradeTax.tableRows.${node?.data?.rowName}`) ??
                '',
              width: 300,
            },
            {
              colId: 'group',
              field: 'group',
              hide: true,
              rowGroup: true,
              valueFormatter: ({ value }) =>
                getCustomLabel(value) ?? t(`main:reportPanels.tradeTax.tableRows.${value}`) ?? '',
            },
            {
              colId: 'subGroup',
              field: 'subGroup',
              hide: true,
              rowGroup: true,
              valueFormatter: ({ value }) =>
                getCustomLabel(value) ?? t(`main:reportPanels.tradeTax.tableRows.${value}`) ?? '',
            },
            {
              aggFunc,
              cellClass: (params) => [
                addPriceClassForCorrectType(params),
                addBoldTextClassForFirstLevelRows(params),
                ...cellClass(params),
              ],
              colId: 'total',
              field: 'total',
              headerName: t('common:total'),
              pinned: 'left',
              type: 'report-price',
            },
          ],
          headerName: '',
          pinned: 'left',
        },
        ...(indirectCostCentersGrouped
          ? ([
              {
                children: generateColumnGroups({
                  aggFunc,
                  cellClass,
                  costCentersGroups: indirectCostCentersGrouped,
                  t,
                  type: 'INDIRECT_COST_CENTER',
                }),
                headerClass: 'white-background',
                headerName: t('common:supportCostCenter'),
                pinned: 'left',
              },
            ] satisfies (ColDef | ColGroupDef)[])
          : []),
        ...(primaryCostCentersGrouped
          ? ([
              {
                children: generateColumnGroups({
                  aggFunc,
                  cellClass,
                  costCentersGroups: primaryCostCentersGrouped,
                  isSeparated: true,
                  t,
                  type: 'PRIMARY_COST_CENTER',
                }),
                headerClass: 'white-background border-left-2px',
                headerName: t('common:mainCostCenter'),
                pinned: 'left',
              },
            ] satisfies (ColDef | ColGroupDef)[])
          : []),
      ] satisfies (ColDef | ColGroupDef)[],
    [getCustomLabel, indirectCostCentersGrouped, primaryCostCentersGrouped, t],
  );
};
