import type { CellClassParams, ColGroupDef, IAggFuncParams } from 'ag-grid-community';

import type { dice } from '@org/query';
import { t } from '@org/locales';
import { getAlignmentIndentClass } from '@org/utils';

const BORDER_CSS_CLASS = 'border-right-1px';

const aggCalculateCostRevenueTotal = (params: IAggFuncParams) => {
  if (params?.rowNode?.childrenAfterGroup?.length) {
    return params.rowNode.childrenAfterGroup?.reduce(
      (acc, curr) => acc + (curr?.data?.costRevenueCalculationTotal ?? 0),
      0,
    );
  }
};

export const getColumns = (
  costUnits: dice.CostUnit[],
  defaultCellClass: (params: CellClassParams) => string[],
): ColGroupDef[] => [
  {
    children: [
      {
        autoHeight: true,
        cellClass: (params: CellClassParams) => [
          ...defaultCellClass(params),
          getAlignmentIndentClass(params),
          'bg-transparent',
          'ag-wrap-words',
        ],
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
        },
        field: 'costTypeDescription',
        headerClass: 'white-background',
        headerName: '',
        pinned: 'left',
        showRowGroup: true,
        valueGetter: (params) => params?.data?.costTypeDescription,
        width: 300,
      },
      {
        field: 'parentCostTypeDescription',
        hide: true,
        rowGroup: true,
      },
      {
        aggFunc: aggCalculateCostRevenueTotal,
        cellClass: (params: CellClassParams) => [
          ...defaultCellClass(params),
          'align-right',
          'bg-transparent',
          BORDER_CSS_CLASS,
        ],
        field: 'costRevenueCalculationTotal',
        headerClass: ['white-background', BORDER_CSS_CLASS],
        headerName: t('main:reportPanels.costUnits.totalColumn'),
        pinned: 'left',
        type: 'report-price',
        width: 120,
      },
    ],
    headerClass: BORDER_CSS_CLASS,
    headerName: '',
  },
  {
    children: costUnits.map((costUnit) => ({
      aggFunc: (params) => params.values.reduce((acc, curr = 0) => acc + curr, 0),
      cellClass: (params: CellClassParams) => [
        ...defaultCellClass(params),
        'bg-transparent',
        'align-right',
        'number',
      ],
      field: costUnit.shortName,
      headerClass: 'white-background',
      headerName: costUnit.longName,
      headerTooltip: costUnit.longName,
      type: 'report-price',
    })),
    headerName: t('common:costUnit'),
  },
];
