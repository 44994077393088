import type { FC } from 'react';

import { useTranslation } from '@org/locales';
import { CheckboxInput, InfoIcon, Input, Tooltip } from '@org/ui';

import type { ControllerReturnType } from './useController';
import { CheckboxList, ErrorMessage } from './components';
import { formatName, iterableToArray } from './helpers';

export const View: FC<ControllerReturnType> = (props) => {
  const {
    availableCostCenters: allAvailableCostCenters,
    allAvailableProfitCenters,
    profitCentersWithFilter,
    selectedCostCenterName,
    selectedProfitCenterName,
    jobsWithFilter,
    availableJobs: allAvailableJobs,
    updateState,
    checkedJobs,
    ignoreProfitCenters,
    ignoreCostCenters,
    ignoreJobIds,
    profitCenterSearch,
    jobIdSearch,
    costCenterSearch,
    handleInputChange,
    costCentersWithFilter,
    allJobsSelected,
    someJobsSelected,
    allCostCentersSelected,
    someCostCentersSelected,
    handleProfitCenterIgnore,
    handleCostCenterIgnore,
    handleJobsIgnore,
    isIgnoreProfitCenterDisabled,
    isIgnoreCostCenterDisabled,
    isIgnoreJobsDisabled,
    allProfitCentersSelected,
    someProfitCentersSelected,
    handleBulkCheckboxCheck,
    isValidConfiguration,
    handleCheck,
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className="relative p-4"
      data-testid="CostCenterMappingBeta"
    >
      <ErrorMessage isValid={isValidConfiguration} />
      <div className="grid grid-cols-3">
        <div className="flex-column border-right-1px flex px-2">
          <span className="mx-2 text-sm font-medium">
            {t('main:manageMenu.manageCostCenters.tableColumns.profitCenterId')}
          </span>
          <div className="align-cell-center mx-2 mb-3 flex gap-1 border-b border-solid border-grey-light py-3">
            <CheckboxInput
              checked={ignoreProfitCenters}
              disabled={isIgnoreProfitCenterDisabled}
              onChange={handleProfitCenterIgnore}
            >
              {t('main:manageMenu.manageCostCenters.ignoreProfitCenters')}
            </CheckboxInput>
            <Tooltip
              content={t(`main:manageMenu.manageCostCenters.ignoreProfitCentersInfo`)}
              placement="right-start"
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <div className="d-flex mx-2 my-1">
            <CheckboxInput
              checked={allProfitCentersSelected}
              data-testid="ProfitCenterBulkCheckbox"
              disabled={ignoreProfitCenters}
              indeterminate={someProfitCentersSelected}
              onChange={handleBulkCheckboxCheck(...allAvailableProfitCenters)}
            />
            <Input
              onChange={handleInputChange('profitCenterSearch')}
              placeholder={t('search')}
              size="xs"
              value={profitCenterSearch}
            />
          </div>
          <div>
            <CheckboxList
              data-testid="ProfitCenterCheckboxList"
              getCheckboxProps={({
                name,
                label,
                unavailableJobs,
                availableJobs,
                isUnavailable = false,
              }) => {
                const allJobs = iterableToArray(availableJobs);
                return {
                  checked: allJobs.every(({ id }) => checkedJobs.has(id)),
                  children: formatName({ label, name }),
                  disabled: isUnavailable || ignoreProfitCenters,
                  indeterminate:
                    unavailableJobs.size > 0 || allJobs.some(({ id }) => checkedJobs.has(id)),
                  key: name,
                  notAvailable: isUnavailable,
                  selected: name === selectedProfitCenterName,
                };
              }}
              handleSelect={({ name }) =>
                updateState({
                  selectedCostCenterName: '',
                  selectedProfitCenterName: name,
                })
              }
              items={profitCentersWithFilter}
              onChange={handleCheck}
            />
          </div>
        </div>
        <div className="border-right-1px flex-column border-right-1px flex px-2">
          <span className="mx-2 text-sm font-medium">
            {t('main:manageMenu.manageCostCenters.tableColumns.costCenterId')}
          </span>
          <div className="align-cell-center mx-2 mb-3 flex gap-1 border-b border-solid border-grey-light py-3">
            <CheckboxInput
              checked={ignoreCostCenters}
              disabled={isIgnoreCostCenterDisabled}
              onChange={(event) => handleCostCenterIgnore(event, selectedProfitCenterName)}
            >
              {t('main:manageMenu.manageCostCenters.ignoreCostCenters')}
            </CheckboxInput>
            <Tooltip
              content={t(`main:manageMenu.manageCostCenters.ignoreCostCentersInfo`)}
              placement="right-start"
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <div className="d-flex mx-2 my-1">
            <CheckboxInput
              checked={allCostCentersSelected}
              disabled={ignoreCostCenters}
              indeterminate={someCostCentersSelected}
              onChange={handleBulkCheckboxCheck(...allAvailableCostCenters)}
            />
            <Input
              onChange={handleInputChange('costCenterSearch')}
              placeholder={t('search')}
              size="xs"
              value={costCenterSearch}
            />
          </div>
          <div>
            <CheckboxList
              data-testid="CostCenterCheckboxList"
              getCheckboxProps={({
                fullName,
                name,
                label,
                profitCenterName,
                availableJobs,
                unavailableJobs,
                isUnavailable,
              }) => ({
                checked: [...availableJobs].every(({ id }) => checkedJobs.has(id)),
                children: formatName(
                  { label, name },
                  {
                    condition: ignoreProfitCenters,
                    formatter(value) {
                      return `${value} (PC: ${profitCenterName})`;
                    },
                  },
                ),
                disabled: ignoreCostCenters || isUnavailable,
                indeterminate:
                  unavailableJobs.size > 0 ||
                  [...availableJobs].some(({ id }) => checkedJobs.has(id)),
                key: fullName,
                notAvailable: isUnavailable,
                selected: fullName === selectedCostCenterName,
              })}
              handleSelect={({ fullName }) =>
                updateState({
                  selectedCostCenterName: fullName,
                })
              }
              items={costCentersWithFilter}
              onChange={handleCheck}
            />
          </div>
        </div>
        <div className="flex-column border-right-1px flex px-2">
          <span className="mx-2 text-sm font-medium">
            {t('main:manageMenu.manageCostCenters.tableColumns.jobId')}
          </span>
          <div className="align-cell-center mx-2 mb-3 flex gap-1 border-b border-solid border-grey-light py-3">
            <CheckboxInput
              checked={ignoreJobIds}
              disabled={isIgnoreJobsDisabled}
              onChange={(event) => handleJobsIgnore(event, selectedCostCenterName)}
            >
              {t('main:manageMenu.manageCostCenters.ignoreJobIds')}
            </CheckboxInput>
            <Tooltip
              content={t(`main:manageMenu.manageCostCenters.ignoreJobIdsInfo`)}
              placement="right-start"
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <div className="d-flex mx-2 my-1">
            <CheckboxInput
              checked={allJobsSelected}
              disabled={ignoreJobIds}
              indeterminate={someJobsSelected}
              onChange={handleBulkCheckboxCheck(...allAvailableJobs)}
            />
            <Input
              onChange={handleInputChange('jobIdSearch')}
              placeholder={t('search')}
              size="xs"
              value={jobIdSearch}
            />
          </div>
          <div>
            <CheckboxList
              data-testid="JobIdCheckboxList"
              getCheckboxProps={({
                name,
                id,
                label,
                isUnavailable,
                costCenter,
                profitCenterName,
                accountId,
              }) => ({
                checked: checkedJobs.has(id) || isUnavailable,
                children: formatName(
                  { label, name },
                  {
                    condition: !selectedProfitCenterName,
                    formatter(value) {
                      return `${value} (PC: ${profitCenterName})`;
                    },
                  },
                  {
                    condition: !selectedCostCenterName,
                    formatter(value) {
                      return `${value} (CC: ${costCenter})`;
                    },
                  },
                  {
                    condition: Boolean(accountId),
                    formatter(value) {
                      return `${value} (${t('main:manageMenu.manageCostCenters.accountNumber', {
                        accountNumber: accountId,
                      })})`;
                    },
                  },
                ),
                disabled: ignoreJobIds || isUnavailable,
                key: id,
                notAvailable: isUnavailable,
              })}
              items={jobsWithFilter}
              onChange={handleCheck}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
