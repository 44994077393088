import type { CellClassParams, ColDef, ExcelExportParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { FC } from 'react';
import { useCallback, useMemo, useRef } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { ExcelReportType } from '@org/models';
import { BaseTable, Button } from '@org/ui';

import { useReportExcelFileName } from '../../hooks/useReportExcelFileName';
import { convertToCostUnitReportRows, getCellClasses, getColumns } from './utils';
import { getDefaultExcelExportParams } from './utils/getDefaultExcelExportParams';

const getSortedCostUnits = (costUnits: dice.CostUnit[]) =>
  [...costUnits].sort(
    (firstCostUnit, secondCostUnit) => (firstCostUnit?.rank ?? 0) - (secondCostUnit?.rank ?? 0),
  );

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 105,
  sortable: false,
  suppressHeaderMenuButton: true,
};

export interface CostUnitReportTableProps {
  reportCostUnits: dice.CostUnit[];
  configurationCostUnits: dice.CostUnit[];
}

export const CostUnitReportTable: FC<CostUnitReportTableProps> = (props) => {
  const { reportCostUnits: unsortedReportCostUnits, configurationCostUnits } = props;
  const { t } = useTranslation();
  const tableGridRef = useRef<AgGridReact>(null);
  const { getReportExcelFileName } = useReportExcelFileName();
  const sortedReportCostUnits = useMemo(
    () => getSortedCostUnits(unsortedReportCostUnits),
    [unsortedReportCostUnits],
  );
  const rowsToBold = useMemo(
    () => [
      t('main:reportPanels.costUnits.tableRows.eligibleCosts'),
      t('main:reportPanels.costUnits.tableRows.eligibleAncillaryRevenues'),
      t('main:reportPanels.costUnits.tableRows.subtotal'),
      t('main:reportPanels.costUnits.tableRows.costRecoveryRequirement'),
      t('main:reportPanels.costUnits.tableRows.totalOverfundingUnderfunding'),
      t('main:reportPanels.costUnits.tableRows.feeRate'),
      t('main:reportPanels.costUnits.tableRows.requiredCostCoverage'),
    ],
    [t],
  );

  const cellClass = useCallback(
    (params: CellClassParams) => getCellClasses(rowsToBold, params),
    [rowsToBold],
  );

  // update columns when t is changed

  const columns = useMemo(
    () => getColumns(sortedReportCostUnits, cellClass),
    [cellClass, sortedReportCostUnits],
  );
  const rowData = useMemo(
    () => convertToCostUnitReportRows(sortedReportCostUnits, configurationCostUnits),
    // update rowData when t is changed
    // eslint-disable-next-line
    [sortedReportCostUnits, configurationCostUnits, t],
  );

  const defaultExcelExportParams = useMemo<ExcelExportParams>(
    () =>
      getDefaultExcelExportParams(
        getReportExcelFileName(ExcelReportType.COST_UNIT),
        ExcelReportType.COST_UNIT,
      ),
    [getReportExcelFileName],
  );
  const handleExport = useCallback(
    () => tableGridRef?.current?.api.exportDataAsExcel(),
    [tableGridRef],
  );

  return (
    <>
      <div className="float-right inline-flex items-center gap-4">
        <Button
          onClick={handleExport}
          size="sm"
          variant="primary"
        >
          <span className="appkiticon icon-download-outline" />
          EXPORT
        </Button>
      </div>
      <div className="flex h-[calc(100vh-180px)] w-full flex-auto items-start justify-center overflow-auto p-0 pt-3">
        <BaseTable
          columnDefs={columns}
          defaultColDef={defaultColDef}
          defaultExcelExportParams={defaultExcelExportParams}
          groupDisplayType="custom"
          ref={tableGridRef}
          rowClass="bg-transparent"
          rowData={rowData}
        />
      </div>
    </>
  );
};
