import type { FC } from 'react';

import classNames from 'classnames';

import { useTranslation } from '@org/locales';
import {
  BaseEditTable,
  Button,
  FullSizeModal,
  LoaderWrapper,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@org/ui';
import { bindControllers } from '@org/utils';

import type { UseControllerProps, ViewProps } from './useController';
import { onReportModalAsyncTransactionsFlushed } from './onReportModalAsyncTransactionsFlushed';
import { useController } from './useController';

export type BabReportModalProps = UseControllerProps;

const View: FC<ViewProps> = (props) => {
  const {
    discardChanges,
    accountId,
    selectedAdjustedAccount,
    transactionsPresentToFlush,
    columnDefs,
    defaultColDef,
    transactions,
    modalGridRef,
    isOpen,
    onOpenChange,
    handleClose,
    recalculate,
    updateMasterConfiguration,
    saveTransactionModificationBulks,
    isRecalculating,
    accountIds,
    costTypeAccount,
    doesExternalFilterPass,
    isExternalFilterPresent,
  } = props;
  const { t } = useTranslation();

  if (accountIds?.length === 0) {
    return null;
  }

  return (
    <FullSizeModal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        <ModalHeader>
          <div className="mr-4 flex justify-between">
            <div>
              {accountId && (
                <>
                  {t('main:reportPanels.babReport.headerAccountDetails')}
                  <span className="font-bold">
                    {accountId} {selectedAdjustedAccount}
                  </span>
                  {selectedAdjustedAccount && (
                    <span className="font-bold text-red-600">
                      {t('main:reportPanels.babReport.tableColumns.adjustedAccount')}
                    </span>
                  )}
                </>
              )}
              {!accountId && (
                <>
                  {t('main:reportPanels.babReport.headerAccountDetailsCostTypeName')}
                  <span className="font-bold">{costTypeAccount}</span>
                </>
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            className={classNames('w-100 pr-3', {
              'pointer-events-none opacity-70': selectedAdjustedAccount,
            })}
          >
            <div className="flex">
              <Button
                className="mr-3"
                isDisabled={!transactionsPresentToFlush}
                onClick={discardChanges}
                size="sm"
                variant="primary"
              >
                {t('common:discardChanges')}
              </Button>
              <Button
                isDisabled={!transactionsPresentToFlush}
                onClick={() => handleClose()}
                size="sm"
                variant="primary"
              >
                {t('common:saveAndRecalculate')}
              </Button>
            </div>
          </div>
          <div className="mt-4 h-full">
            <LoaderWrapper loading={isRecalculating}>
              <BaseEditTable
                // Set to an hour to only manually trigger the flush of transactions via modal close (BabReportPage.js)
                asyncTransactionWaitMillis={600_000}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                doesExternalFilterPass={doesExternalFilterPass}
                getRowId={({ data }) => data.index ?? ''}
                isDisabled={selectedAdjustedAccount}
                isExternalFilterPresent={isExternalFilterPresent}
                onAsyncTransactionsFlushed={onReportModalAsyncTransactionsFlushed({
                  executeCloseModal: handleClose,
                  recalculate,
                  saveTransactionModificationBulks,
                  updateConfiguration: updateMasterConfiguration,
                })}
                ref={modalGridRef}
                // @ts-expect-error -- wrong type
                rowData={transactions}
                rowSelection="multiple"
                suppressRowClickSelection
              />
            </LoaderWrapper>
          </div>
        </ModalBody>
      </ModalContent>
    </FullSizeModal>
  );
};

export const BabReportModal = bindControllers(View, [useController]);
BabReportModal.displayName = 'BabReportModal';
