import type { FC } from 'react';

import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, DisabableContent, Label, NumberInput, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

export interface ImputedWorkingCapitalPage extends ControllerType {}

const ImputedWorkingCapitalView: FC<ImputedWorkingCapitalPage> = (props) => {
  const { t } = useTranslation();
  const {
    columns,
    handleDefaultWorkingCapitalRateChange,
    handleEnableWorkingCapitalChange,
    resetAllFilteredWorkingCapitalRatesToDefault,
    tableRef,
    workingCapitalEnabled,
    workingCapitals,
    updateWorkingCapitals,
    enteredWorkingCapitalRate,
  } = props;

  return (
    <div
      className="flex flex-1 flex-col"
      data-testid="ImputedWorkingCapitalPage"
    >
      <h6 className="my-3">{t('main:manageMenu.manageImputedWorkingCapital.title')}</h6>

      <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
        <ToggleInput
          checked={workingCapitalEnabled}
          name={t('main:manageMenu.manageImputedWorkingCapital.activateImputedConcessionFee')}
          onChange={handleEnableWorkingCapitalChange}
        />
        {t('main:manageMenu.manageImputedWorkingCapital.activateImputedConcessionFee')}
      </Label>

      <DisabableContent
        className="h-full"
        disabled={!workingCapitalEnabled}
      >
        <div className="mb-4 flex items-end justify-start gap-2 pt-2">
          <div className="w-44">
            <Label>{t('main:manageMenu.manageImputedWithdrawalCapital.workingCapitalRate')}</Label>

            <NumberInput
              className="text-right"
              onChange={handleDefaultWorkingCapitalRateChange}
              suffix="%"
              value={enteredWorkingCapitalRate}
            />
          </div>
          <Button
            onClick={resetAllFilteredWorkingCapitalRatesToDefault}
            size="md"
            variant="primary"
          >
            {t('main:manageMenu.manageImputedWithdrawalCapital.applyToTable')}
          </Button>
        </div>
        <div className="h-full">
          <div
            className="h-full flex-1"
            data-testid="ImputedWorkingCapitalTable"
          >
            <BaseEditTable
              columnDefs={columns}
              onChange={updateWorkingCapitals}
              ref={tableRef}
              rowData={workingCapitals}
            />
          </div>
        </div>
      </DisabableContent>
    </div>
  );
};

export const ImputedWorkingCapital = bindControllers(ImputedWorkingCapitalView, [useController]);

ImputedWorkingCapital.displayName = 'ImputedWorkingCapital';
