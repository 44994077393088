import type { ExcelExportParams, ProcessCellForExportParams } from 'ag-grid-community';

const replaceNilValueForNumericCellBuilder =
  (nonNumericColumns: Set<string>) => (params: ProcessCellForExportParams) =>
    nonNumericColumns.has(params.column.getColId()) ? params.value : (params.value ?? 0);

export enum ExcelReportType {
  BAB = 'BAB',
  INTEREST = 'Zinstableau',
  COST_UNIT = 'KTR',
  TRADE_TAX = 'Gewerbesteuer',
  PRICE_SHEET = 'Preisblatt',
}

export const getDefaultExcelExportParams = (
  fileName: string,
  reportType: ExcelReportType,
  nonNumericColumns: Set<string>,
): ExcelExportParams => ({
  fileName,
  processCellCallback: replaceNilValueForNumericCellBuilder(nonNumericColumns),
  sheetName: reportType,
});
