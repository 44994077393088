import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import nameOf from 'easy-tsnameof';

import type { APIParams } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import {
  ENTRY,
  formatNonZeroNumberToLocaleString,
  getCellClassesForGroups,
  getCellClassesForGroupsWithActions,
  GROUP,
  SUB_GROUP,
} from '@org/utils';

import type { PriceSheetRow, RowType } from './types';
import { MOVE_DIRECTION } from '../CostCenterConfiguration';
import { PRICE_SHEET } from './constants';

interface UseColumnsProps {
  addNewRow: (type: RowType, props: ButtonCellRendererProps | undefined) => Promise<void> | void;
  moveRow: (
    props: ButtonCellRendererProps,
    direction: string,
    type: RowType,
  ) => Promise<void> | void;
  handleDeleteModal: (props: ButtonHandlerProps) => Promise<void> | void;
  apiParams: APIParams<'masterConfigurationId'>;
}

export const columns = nameOf<PriceSheetRow, never>();

export const useColumns = ({
  addNewRow,
  apiParams,
  handleDeleteModal,
  moveRow,
}: UseColumnsProps) => {
  const { t } = useTranslation();
  const { masterConfiguration } = aggregated.useMasterConfiguration(apiParams);
  const costUnitOptions = useMemo(
    () =>
      (masterConfiguration?.costUnitConfig ?? []).map((costUnit) => ({
        label: costUnit.longName,
        value: costUnit.shortName,
      })),
    [masterConfiguration?.costUnitConfig],
  );
  const costUnitMap = useMemo(
    () =>
      new Map(
        masterConfiguration?.costUnitConfig?.map(({ longName, shortName }) => [
          shortName,
          longName,
        ]),
      ),
    [masterConfiguration?.costUnitConfig],
  );
  return useMemo(
    () =>
      [
        {
          cellRenderer: 'agGroupCellRenderer',
          field: 'source',
          valueFormatter: ({ value }) => t(`main:manageMenu.priceSheet.${value}`),
          hide: true,
          rowGroup: true,
          aggFunc: ({ values }) => values.at(0),
        },
        {
          colId: 'groupName',
          field: 'groupName',
          aggFunc: ({ rowNode }) => {
            const rootGroupId = rowNode.allLeafChildren?.[0]?.data?.groupId;
            return rowNode.allLeafChildren?.every(({ data }) => data?.groupId === rootGroupId)
              ? rootGroupId
              : '';
          },
          flex: 1,
          hide: true,
          rowGroup: true,
        },
        {
          colId: 'subGroupName',
          field: 'subGroupName',
          aggFunc: ({ rowNode }) => {
            const rootSubGroupId = rowNode.allLeafChildren?.[0]?.data?.subGroupId;
            return rowNode.allLeafChildren?.every(({ data }) => data?.subGroupId === rootSubGroupId)
              ? rootSubGroupId
              : '';
          },
          flex: 1,
          hide: true,
          rowGroup: true,
        },
        {
          colId: 'entryName',
          field: 'entryName',
          flex: 1,
          hide: true,
        },
        {
          aggFunc: (params) => {
            const { rowNode: node, values } = params;
            const isPriceSheetSubGroup = Boolean(
              node?.group && node?.allLeafChildren?.[0]?.data?.source === PRICE_SHEET,
            );

            return isPriceSheetSubGroup || node.level === 1 ? values.at(0) : '';
          },
          cellClass: getCellClassesForGroups,
          colId: 'currency',
          editable: ({ node }) => {
            const isPriceSheetSubGroup =
              node?.group &&
              node?.allLeafChildren?.[0]?.data?.source === PRICE_SHEET &&
              node?.level === 2;

            return !!isPriceSheetSubGroup;
          },
          field: 'currency',
          headerName: t('main:manageMenu.priceSheet.tableColumns.unit'),
          maxWidth: 100,
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'factor1',
          field: 'factor1',
          headerName: t('main:manageMenu.priceSheet.tableColumns.factor1'),
          maxWidth: 80,
          valueFormatter: ({ value, node }) =>
            node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'factor2',
          field: 'factor2',
          headerName: t('main:manageMenu.priceSheet.tableColumns.factor2'),
          maxWidth: 80,
          valueFormatter: ({ value, node }) =>
            node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'factor3',
          field: 'factor3',
          headerName: t('main:manageMenu.priceSheet.tableColumns.factor3'),
          maxWidth: 80,
          valueFormatter: ({ value, node }) =>
            node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'factor4',
          field: 'factor4',
          headerName: t('main:manageMenu.priceSheet.tableColumns.factor4'),
          maxWidth: 80,
          valueFormatter: ({ value, node }) =>
            node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'select',
          cellEditorParams: {
            options: costUnitOptions,
            placeholder: t('main:manageMenu.priceSheet.tableColumns.costUnit'),
          },
          cellEditorPopup: true,
          colId: 'costUnit',
          editable: (params) => params?.data?.source === PRICE_SHEET,
          field: 'costUnit',
          headerName: t('main:manageMenu.priceSheet.tableColumns.costUnit'),
          maxWidth: 140,
          valueFormatter: ({ value }) => costUnitMap.get(value) ?? value,
        },
        {
          cellClass: getCellClassesForGroupsWithActions,
          cellRendererSelector: (params) => {
            const { node } = params;
            const isPriceSheet = node.allLeafChildren?.at(0)?.data?.source === 'PRICE_SHEET';
            const isRootNode = node.level === 0;
            const isGroup = node.level === 1;

            const icons = [
              isPriceSheet &&
                !isRootNode && {
                  icon: 'icon-delete-outline',
                  onClick: (props: ButtonCellRendererProps) =>
                    handleDeleteModal({
                      ...props,
                      type: node.group ? (node.field === 'groupName' ? GROUP : SUB_GROUP) : ENTRY,
                    }),
                  size: 'iconButton',
                },
            ];
            if (node.group) {
              icons.unshift(
                (isPriceSheet || node.level < 2) && {
                  icon: 'icon-arrow-up-outline',
                  onClick: (props: ButtonCellRendererProps) =>
                    moveRow(props, MOVE_DIRECTION.MOVE_UP, node.level < 2 ? GROUP : SUB_GROUP),
                  size: 'iconButton',
                },
                (isPriceSheet || node.level < 2) && {
                  icon: 'icon-arrow-down-outline',
                  onClick: (props: ButtonCellRendererProps) =>
                    moveRow(props, MOVE_DIRECTION.MOVE_DOWN, node.level < 2 ? GROUP : SUB_GROUP),
                  size: 'iconButton',
                },
                isPriceSheet && {
                  icon: 'icon-plus-outline',
                  onClick: (data: ButtonCellRendererProps) =>
                    addNewRow(isRootNode ? GROUP : isGroup ? SUB_GROUP : ENTRY, data),
                  size: 'iconButton',
                },
              );
            }

            return {
              component: 'multipleButtons',
              params: {
                buttonParams: icons.filter(Boolean),
                className: 'flex',
              },
            };
          },
          colId: 'actions',
          editable: false,
          flex: 1,
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            icon: 'icon-plus-fill',
            onClick: () => addNewRow(GROUP, undefined),
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          maxWidth: 140,
        },
      ] satisfies (ColDef<PriceSheetRow> | ColGroupDef<PriceSheetRow>)[],
    [addNewRow, costUnitMap, costUnitOptions, handleDeleteModal, moveRow, t],
  );
};
