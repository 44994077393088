import type { GetRowIdParams, IDetailCellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';

import type { APIParams, griddy } from '@org/query';
import type { BaseEditTableProps, UseMasterDetailStateProps } from '@org/ui';
import { BasicFeeTable } from '@org/features';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, LoaderWrapper } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { RevenueDefinition, SelectAccounts } from './components';
import { columnNames, REVENUE_TYPES } from './constants';
import { useController } from './useController';

interface CostUnitConfigurationProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

interface DetailCellRendererProps extends IDetailCellRendererParams {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  context: UseMasterDetailStateProps;
  data: griddy.CostUnit;
}

const getRowId = ({ data }: GetRowIdParams) => data?.shortName;

const DetailCellRenderer = (params: DetailCellRendererProps) => {
  const { data, context, apiParams, api } = params;

  // Props data can be out of date
  // Use rowNode instead
  const rowNode = api.getRowNode(data.shortName);

  if (context.SELECTED_DETAIL_COL === columnNames.rank.$path) {
    if (rowNode?.data?.revenueType === REVENUE_TYPES.DEFINE_REVENUES) {
      return (
        <RevenueDefinition
          apiParams={apiParams}
          data={data}
        />
      );
    } else {
      return (
        <SelectAccounts
          {...params}
          apiParams={apiParams}
          data={rowNode?.data}
        />
      );
    }
  }
  return (
    <BasicFeeTable
      apiParams={apiParams}
      costUnitShortName={data.shortName}
    />
  );
};

const CostUnitConfigurationView: FC<CostUnitConfigurationProps> = (
  props: CostUnitConfigurationProps,
) => {
  const { t } = useTranslation();

  const {
    isLoading,
    columnDefs,
    tableRef,
    onRowDragMove,
    onRowDragEnd,
    onCellValueChange,
    modal,
    defaultColDef,
    addNewRow,
    tableContext,
    onGridReady,
    detailCellRendererParams,
  } = props;

  return (
    <div className="h-full">
      <div className="my-2">
        <Button
          onClick={addNewRow}
          size="sm"
          variant="primary"
        >{`${t('common:btnAdd')} ${t('common:costUnit')}`}</Button>
      </div>
      <LoaderWrapper loading={isLoading}>
        <BaseEditTable
          animateRows
          columnDefs={columnDefs}
          context={tableContext}
          defaultColDef={defaultColDef}
          detailCellRenderer={DetailCellRenderer}
          detailCellRendererParams={detailCellRendererParams}
          detailRowHeight={415}
          getRowId={getRowId}
          masterDetail
          onCellValueChanged={onCellValueChange}
          onGridReady={onGridReady}
          onRowDragEnd={onRowDragEnd}
          onRowDragMove={onRowDragMove}
          ref={tableRef}
          rowDragMultiRow
          rowSelection="multiple"
        />
      </LoaderWrapper>
      {modal}
    </div>
  );
};

export const CostUnitConfigurationPage = bindControllers(CostUnitConfigurationView, [
  useController,
]);

CostUnitConfigurationPage.displayName = 'CostUnitConfigurationPage';
