import type { ComponentType, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { aggregated, APIParams } from '@org/query';
import { dice } from '@org/query';

interface UseControllerProps
  extends APIParams<'yearId' | 'clientId' | 'masterConfigurationId' | 'publicFacilityId' | 'type'> {
  LinkComponent: ComponentType<{
    className?: string;
    children?: ReactNode;
    [key: string]: unknown;
  }>;
  /**
   * Get link props for the link component
   * hover target is ERP, UPLOAD or undefined undefined means the whole component.
   * @param hoverTarget hover target
   * @returns
   */
  getFileLinkProps?: (
    fileType: aggregated.BaseFileType,
    hoverTarget?: 'ERP' | 'UPLOAD',
  ) => Record<string, unknown>;
  getConfigurationsLinkProps?: () => Record<string, unknown>;
}

export const useController = (props: UseControllerProps) => {
  const [hoverTarget, setHoverTarget] = useState<'ERP' | 'UPLOAD' | undefined>();
  const { yearId } = props;
  const { data } = dice.useGetFilesMetadataStatus(
    {
      pathParams: {
        yearCalculationId: yearId,
      },
    },
    {
      enabled: Boolean(yearId),
    },
  );

  const filesMetadata = useMemo(
    () => new Map(data?.files?.map(({ type, ...rest }) => [type, rest] as const) ?? []),
    [data],
  );

  const handleMouseOver = useCallback(
    (target: 'ERP' | 'UPLOAD') => () => {
      setHoverTarget(target);
    },
    [],
  );

  const handleMouseLeave = useCallback(() => {
    setHoverTarget(undefined);
  }, []);

  const getSubLinkProps = useCallback(
    (target: Parameters<typeof handleMouseOver>['0']) => ({
      className: 'flex items-center gap-2 px-3 font-normal hover:underline',
      onFocus: handleMouseOver(target),
      onMouseLeave: handleMouseLeave,
      onMouseOver: handleMouseOver(target),
    }),
    [handleMouseLeave, handleMouseOver],
  );

  return {
    ...props,
    filesMetadata,
    hoverTarget,
    getSubLinkProps,
  };
};

export type ViewProps = ReturnType<typeof useController>;
