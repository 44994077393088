import type {
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import type { RowType } from '@org/utils';
import { t } from '@org/locales';
import { aggregated } from '@org/query';
import {
  ENTRY,
  formatToSimpleDate,
  getCellClassesForGroups,
  getCellClassesForGroupsWithActions,
  GROUP,
  iterableToArray,
} from '@org/utils';

const dateFormatter = (params: ValueFormatterParams | ValueGetterParams) =>
  params?.data?.purchasingDate ? formatToSimpleDate(new Date(params.data.purchasingDate)) : '';

export interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  activeConfiguration: boolean;
  handleDeleteModal: (props: ButtonHandlerProps) => void;
  addNewRow: (type: RowType, props?: ButtonCellRendererProps) => void;
  assetCategories: Map<string, string> | undefined;
}

export const useColumns = ({
  assetCategories,
  activeConfiguration,
  handleDeleteModal,
  addNewRow,
  apiParams,
}: UseColumnsProps) => {
  const { costCentersNameMap } = aggregated.useCostCenters(apiParams);

  const costCenterOptions = useMemo(
    () => Array.from(costCentersNameMap.entries()).map(([value, label]) => ({ label, value })),
    [costCentersNameMap],
  );

  const assetCategoryOptions = useMemo(
    () =>
      iterableToArray(assetCategories)
        .sort()
        .map(([value, label]) => ({
          label: `${value} ${label}`,
          value,
        })),
    [assetCategories],
  );

  return useMemo(
    () =>
      [
        {
          cellRenderer: 'agGroupCellRenderer',
          colId: 'assetDescription',
          field: 'assetDescription',
          flex: 3,
          headerName: t('main:manageMenu.manageFutureAssets.tableColumns.assetDescription'),
          hide: true,
        },
        {
          colId: 'groupName',
          // @ts-expect-error -- error in types
          field: 'groupName',
          flex: 3,
          headerName: t('main:manageMenu.priceSheet.tableColumns.description'),
          hide: true,
          rowGroup: true,
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'datePicker',
          cellEditorPopup: true,
          cellEditorPopupPosition: 'under',
          colId: 'purchasingDate',
          field: 'purchasingDate',
          filterValueGetter: dateFormatter,
          flex: 1,
          headerName: t('main:manageMenu.manageFutureAssets.tableColumns.purchasingDate'),
          type: 'date',
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'acquisitionValueEnd',
          field: 'acquisitionValueEnd',
          flex: 1,
          headerName: t(
            'main:manageMenu.manageFutureAssets.tableColumns.acquisitionAndProductionCosts',
          ),
          type: 'price',
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'numeric',
          cellEditorParams: {
            decimalScale: 0,
          },
          colId: 'lifeTime',
          field: 'lifeTime',
          flex: 1,
          headerName: t('main:manageMenu.manageFutureAssets.tableColumns.usageTimeInMonths'),
          type: 'integer',
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'select',
          cellEditorParams: {
            options: costCenterOptions,
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          cellEditorPopup: true,
          colId: 'assetCostCenterName',
          field: 'assetCostCenterName',
          flex: 2,
          headerName: t('main:manageMenu.manageFutureAssets.tableColumns.assetCostCenterName'),
          valueFormatter: ({ value }) => costCentersNameMap.get(value) ?? value,
          valueSetter: (params: ValueSetterParams) => {
            params.data.assetCostCenterName = params.newValue;
            params.data.currentBabCCLongName = costCentersNameMap.get(params.newValue);
            params.data.currentBabCCShortName = params.newValue;

            return true;
          },
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'select',
          cellEditorParams: {
            options: assetCategoryOptions,
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          cellEditorPopup: true,
          colId: 'assetCategory',
          field: 'assetCategory',
          flex: 2,
          headerName: t('main:manageMenu.manageFutureAssets.tableColumns.assetCategory'),
          valueFormatter: ({ value }) =>
            value ? `${value} ${assetCategories?.get(value) ?? ''}`.trim() : '',
        },
        {
          cellClass: getCellClassesForGroupsWithActions,
          cellRendererSelector: (params) => {
            if (params.node.group) {
              return {
                component: 'multipleButtons',
                params: {
                  buttonParams: [
                    {
                      icon: 'icon-plus-fill',
                      onClick: (btnProps: ButtonCellRendererProps) => addNewRow(ENTRY, btnProps),
                      size: 'iconButton',
                    },
                    {
                      icon: 'icon-delete-outline',
                      onClick: (btnProps: ButtonCellRendererProps) =>
                        handleDeleteModal({ ...btnProps, type: GROUP }),
                      size: 'iconButton',
                    },
                  ],
                  className: 'flex',
                },
              };
            }
            return {
              component: 'button',
              params: {
                className: 'flex',
                icon: 'icon-delete-outline',
                onClick: (btnProps: ButtonCellRendererProps) =>
                  handleDeleteModal({ ...btnProps, type: ENTRY }),
                size: 'iconButton',
              },
            };
          },
          colId: 'actions',
          editable: false,
          flex: 1,
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            disabled: !activeConfiguration,
            icon: 'icon-plus-fill',
            onClick: () => addNewRow(GROUP),
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          minWidth: 55,
        },
      ] satisfies (ColDef<dice.Asset> | ColGroupDef<dice.Asset>)[],
    [
      activeConfiguration,
      addNewRow,
      assetCategories,
      assetCategoryOptions,
      costCenterOptions,
      costCentersNameMap,
      handleDeleteModal,
    ],
  );
};
