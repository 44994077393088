import type {
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  GetRowIdFunc,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import type { RowType } from '@org/utils';
import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';
import {
  ENTRY,
  FILE_CONFIG,
  generateGroupRowName,
  getCellClassesForGroups,
  getPrimaryCostCenters,
  GROUP,
  prepareGroupRowNamesFromGridAPI,
} from '@org/utils';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

const getRowId: GetRowIdFunc<aggregated.AssetWithGroup> = ({ data }) => data._id ?? '';

const createRow = (masterConfigId: string): Partial<aggregated.AssetWithGroup> => ({
  acquisitionValueEnd: 0,
  assetCategory: '',
  assetCostCenterName: '',
  assetDescription: '',
  assetType: 'FUTURE_ASSET',
  currentCcMappingStatus: 'AUTOMATIC',
  lifeTime: 0,
  masterConfigurationId: masterConfigId,
  purchasingDate: '',
});

export const useController = (apiParams: UseControllerProps) => {
  const { masterConfigurationId: configId } = apiParams;
  const [activeConfiguration] = useState<boolean>(true);
  const { masterConfiguration: selectedConfig, updateMasterConfiguration: updateConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const { t } = useTranslation();
  const tableRef = useRef<AgGridReact>(null);
  const {
    futureAssets,
    updateAsset,
    deleteAsset,
    deleteBatchAsset,
    addAsset,
    addAssetGroup,
    updateAssetGroup,
    deleteAssetGroup,
  } = aggregated.useUpdateAssets({
    assetType: 'FUTURE_ASSET',
    ...apiParams,
  });

  const tableProps = useAgGridData({
    agGridRef: tableRef,
    data: futureAssets,
  });

  const { labels: assetCategories } = aggregated.useLabels({
    ...apiParams,
    fileType: FILE_CONFIG.LABELS.apiType,
    labelType: 'ANLAGEN_KLASSE',
  });

  const generateGroupName = useCallback(
    (colName: string, level: number) =>
      generateGroupRowName(
        t('main:manageMenu.manageImputedWithdrawalCapital.newGroup'),
        prepareGroupRowNamesFromGridAPI(tableRef.current?.api, colName, level),
      ),
    [t],
  );

  const addNewRow = useCallback(
    async (type: RowType, props?: ButtonCellRendererProps) => {
      const groupName = props?.gridProps?.node.key ?? generateGroupName('groupName', 0);
      const newRow = createRow(configId);

      if (type === GROUP) {
        const newAssets = await addAsset(newRow);

        const newRowGroup: dice.AssetGroupDTO = {
          assetIds: newAssets.map((newAsset) => newAsset._id!),
          masterConfigurationId: configId,
          name: groupName,
        };
        await addAssetGroup(newRowGroup);
      }

      if (type === ENTRY && props?.gridProps?.node?.key) {
        const currentAssetsInGroup = futureAssets?.filter(
          (asset) => asset?.groupName === props?.gridProps?.node?.key,
        );

        const firstItem = currentAssetsInGroup[0];

        if (firstItem?.groupId) {
          const newAssets = await addAsset(newRow);

          const assetIds = [
            ...newAssets.map((newAsset) => newAsset._id!),
            ...currentAssetsInGroup.map((currentAsset) => currentAsset._id!),
          ];

          await updateAssetGroup({
            assetIds,
            id: firstItem.groupId,
            masterConfigurationId: configId,
            name: firstItem.groupName,
          });
        }
      }

      tableRef?.current?.api.applyTransaction({
        add: [newRow],
      });
    },
    [addAsset, addAssetGroup, configId, futureAssets, generateGroupName, updateAssetGroup],
  );

  const deleteRow = useCallback(
    async (props: ButtonHandlerProps) => {
      const {
        node: { data },
        type,
      } = props;

      if (type === GROUP) {
        const groupFutureAssets = futureAssets?.filter(
          (futureAsset) => futureAsset?.groupName === props?.gridProps?.node?.key,
        );

        const firstGroupFutureAssetGroupId = groupFutureAssets[0].groupId;

        const groupFutureAssetIds = groupFutureAssets.reduce<string[]>((acc, asset) => {
          if (asset._id) {
            acc.push(asset._id);
          }
          return acc;
        }, []);

        await deleteBatchAsset(groupFutureAssetIds);
        await deleteAssetGroup(firstGroupFutureAssetGroupId!);
      }

      if (type === ENTRY) {
        const groupFutureAssets = futureAssets?.filter(
          (futureAsset) => futureAsset?.groupName === data.groupName,
        );

        await deleteAsset(data._id);

        // Delete also a group if there is only one last entry
        if (groupFutureAssets?.length === 1 && props.data.groupId) {
          await deleteAssetGroup(props.data.groupId);
        }
      }
    },
    [deleteAsset, deleteAssetGroup, deleteBatchAsset, futureAssets],
  );

  const handleActivateFutureAssets = useEvent(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      await updateConfiguration({
        ...selectedConfig,
        futureAssetConfig: {
          ...selectedConfig?.futureAssetConfig,
          isEnabled: checked,
        },
      });
    },
  );

  const costCentersConfig = getPrimaryCostCenters(
    selectedConfig?.costCenterConfig.costCenterMapping,
  );

  const defaultColDef: ColDef = useMemo(
    () => ({
      editable: (params: EditableCallbackParams) => activeConfiguration && !params.node.group,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    }),
    [activeConfiguration],
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererParams: {
        suppressCount: true,
      },
      editable: activeConfiguration,
      field: 'assetDescription',
      flex: 3,
      headerName: t('main:manageMenu.manageFutureAssets.tableColumns.assetDescription'),
    }),
    [activeConfiguration, t],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonHandlerProps>({
    onConfirm: deleteRow,
  });

  const activeFutureAssets = !!selectedConfig?.futureAssetConfig.isEnabled;

  const columnDefs = useColumns({
    activeConfiguration,
    addNewRow,
    apiParams,
    assetCategories,
    handleDeleteModal,
  });

  const onCellValueChange = useCallback(
    async (event: CellValueChangedEvent) => {
      const {
        colDef: { field: colName },
        node,
        data,
      } = event;

      const { allLeafChildren, level, group } = node;

      if (!colName) {
        return;
      }

      if (level === 1) {
        const { groupId: _, groupName: __, ...otherData } = data;

        await updateAsset({
          ...otherData,
          assetCategoryDescriptionByLabel: assetCategories?.get(otherData.assetCategory),
        });
      }

      if (level === 0 && group) {
        const firstGroupItem = allLeafChildren?.[0]?.data;

        const groupData: dice.AssetGroupDTO = {
          assetIds: allLeafChildren?.map((entry) => entry.data._id),
          masterConfigurationId: configId,
          name: data[colName],
        };

        if (!firstGroupItem.groupId) {
          await addAssetGroup(groupData);
        } else {
          await updateAssetGroup({
            ...groupData,
            id: firstGroupItem.groupId,
          });
        }
      }
    },
    [addAssetGroup, assetCategories, configId, updateAsset, updateAssetGroup],
  );

  return {
    activeFutureAssets,
    autoGroupColumnDef,
    columnDefs,
    costCentersConfig,
    defaultColDef,
    getRowId,
    handleActivateFutureAssets,
    modal,
    onCellValueChange,
    ...tableProps,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
