import { AccordionItem, AccordionItemBody, AccordionItemHeader, Accordions } from 'appkit-react';
import styled from 'styled-components';

import { toast } from '@org/ui';

const StyledAlertContent = styled.div`
  max-width: 50vw;

  .a-accordion-item-header.a-accordion-title,
  .react-slidedown.a-accordion-dropdown-slidedown,
  .a-accordion-content {
    background-color: #fff;
  }

  .a-accordion .a-accordion-item-header .a-ccordion-item-header-text .ellipip-able {
    text-overflow: unset;
    white-space: break-spaces;
    overflow: visible;
  }

  .a-accordion .a-accordion-item-header {
    height: 2.525rem;
  }

  .accordion-body {
    border-top: 1px solid #7d7d7d;
    padding-top: 3px;
    max-height: 30vh;
    overflow: auto;
  }
`;

const renderMessage = (message: string, detailedMessage?: string | React.JSX.Element) => {
  if (!detailedMessage) {
    return <StyledAlertContent>{message}</StyledAlertContent>;
  }

  return (
    <StyledAlertContent>
      <Accordions>
        <AccordionItem itemId="details">
          <AccordionItemHeader title={message} />
          <AccordionItemBody className="accordion-body">{detailedMessage}</AccordionItemBody>
        </AccordionItem>
      </Accordions>
    </StyledAlertContent>
  );
};

type StatusTypes = 'info' | 'warning' | 'error' | 'success';

const getOptions = (
  status: StatusTypes,
  duration?: false | number | undefined,
  detailedMessage?: string | React.JSX.Element,
) => {
  const options = {
    autoClose: duration ?? 5000,
    closeOnClick: true,
  };

  if (detailedMessage) {
    options.closeOnClick = false;
  }

  return options;
};

export const handleShowAlert = (
  status: StatusTypes,
  message: string,
  duration?: false | number | undefined,
  detailedMessage?: string | React.JSX.Element,
) => {
  switch (status) {
    case 'info': {
      toast.info(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage),
      );
      break;
    }

    case 'warning': {
      toast.warn(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage),
      );
      break;
    }

    case 'error': {
      // Errors must be closed by the user
      toast.error(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage),
      );
      break;
    }

    case 'success': {
      // LD 09.02.22 currently no success messages should be shown: https://jira.pwc.de/browse/JDEACCESRA-1746
      /* toast.success(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage)
      ); */
      break;
    }

    default: {
      toast(renderMessage(message, detailedMessage), getOptions(status, duration, detailedMessage));
    }
  }
};
