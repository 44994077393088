import type { TFunction } from '@org/locales';

import type { CostCenter } from '../../griddy';
import type { ReportsResponse } from '../../rave';
import { getGroupTotalsOfRow } from './groupTotals';

interface GenerateNonOperatingInterestRateReportProps {
  reportObject: ReportsResponse;
  t: TFunction;
  primaryCostCentersGrouped: [string, CostCenter[]][];
  indirectCostCentersGrouped: [string, CostCenter[]][];
}

export const generateNonOperatingInterestRateReport = ({
  indirectCostCentersGrouped,
  primaryCostCentersGrouped,
  reportObject,
  t,
}: GenerateNonOperatingInterestRateReportProps) => {
  const avgRowIndex = reportObject.newInterestTableReport?.nonOperating?.entries?.findIndex(
    ({ description }) => description === 'RBV_CAPITAL_NON_OPERATING_ASSET_AVG',
  );

  const rowData =
    reportObject.newInterestTableReport?.nonOperating?.entries
      ?.toSpliced(avgRowIndex!, 1)
      ?.map((row, rowIndex) => {
        const { ccShortNameToValueMap, description, reportingDate, imputedInterestAmount } = row;
        return {
          ...ccShortNameToValueMap,
          group: t('main:reportPanels.interestTable.tableRowsData.NON_OPERATING_ASSETS'),
          imputedInterestAmount,
          reportingDate,
          rowDescription: t(`main:reportPanels.interestTable.tableRowsData.${description}`),
          rowIndex,
          subGroup: t('main:reportPanels.interestTable.tableRowsData.ROW_HEADER_2'),
          ...getGroupTotalsOfRow({
            indirectCCGroups: indirectCostCentersGrouped,
            primaryCCGroups: primaryCostCentersGrouped,
            row: {
              costCenterValueMap: ccShortNameToValueMap,
            },
          }),
        };
      }) ?? [];
  return rowData;
};
