import { type FC } from 'react';

import { BaseTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerReturnType } from './hooks';
import { getRowClasses } from '../ReportTable/helpers';
import { ModifiedTransactionsCellRenderer } from './components';
import { useController } from './hooks';

const components = {
  modifiedTransactionCellRenderer: ModifiedTransactionsCellRenderer,
};

const View: FC<ControllerReturnType> = (props) => {
  const {
    columnsDefs,
    defaultColDef,
    defaultExcelExportParams,
    tableGridRef,
    agGridProps,
    isExternalFilterPresent,
    doesExternalFilterPass,
    getRowId,
    ...otherProps
  } = props;

  return (
    <BaseTable
      {...agGridProps}
      autoSizeStrategy={{
        colIds: ['group', 'accountType', 'groupName', 'costTypeName'],
        type: 'fitCellContents',
      }}
      columnDefs={columnsDefs}
      components={components}
      defaultColDef={defaultColDef}
      defaultExcelExportParams={defaultExcelExportParams}
      doesExternalFilterPass={doesExternalFilterPass}
      getRowClass={getRowClasses()}
      getRowId={getRowId}
      groupDefaultExpanded={1}
      groupDisplayType="custom"
      isExternalFilterPresent={isExternalFilterPresent}
      ref={tableGridRef}
      suppressAggFuncInHeader
      {...otherProps}
    />
  );
};

export const BABReportTable = bindControllers(View, [useController]);
BABReportTable.displayName = 'BABReportTable';
