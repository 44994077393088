import { useParams } from 'react-router-dom';

import type { ExcelReportType } from '@org/models';
import { aggregated } from '@org/query';

export const useReportExcelFileName = () => {
  const {
    clientId = '',
    publicFacilityId = '',
    yearId = '',
    type = '',
  } = useParams<{
    clientId: string;
    publicFacilityId: string;
    yearId: string;
    type: string;
  }>();

  const { getClient } = aggregated.useClients({});
  const client = getClient(clientId!);

  const { publicFacility } = aggregated.usePublicFacilities({
    clientId,
    publicFacilityId,
  });
  const { year } = aggregated.useYear({
    publicFacilityId,
    type,
    yearId,
  });
  const getReportExcelFileName = (reportType: ExcelReportType) =>
    `${client?.clientName}_${publicFacility?.name ?? ''}_${year?.year ?? ''}_${reportType}`;
  return { getReportExcelFileName };
};
