import type { FC } from 'react';

import { IndexFactorsUpload } from '@org/features';
import { useTranslation } from '@org/locales';

interface AdminProps {}

const AdminView: FC<AdminProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full">
      <div className="p-16">
        <h1 className="pb-2">{t('main:adminMenu.indexFactors.tab')}</h1>
        <IndexFactorsUpload />
      </div>
    </div>
  );
};

export const AdminPage = AdminView;
AdminPage.displayName = 'AdminPage';
