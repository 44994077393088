import type { CellValueChangedEvent } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef } from 'react';

import type { ButtonCellRendererProps } from '@org/ui';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

export const useController = (apiParams: UseControllerProps) => {
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);
  const tableRef = useRef<AgGridReact>(null);

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: masterConfiguration?.manualCorrectionsInterest,
  });

  const onChange = useCallback(
    async (event: CellValueChangedEvent) => {
      const {
        colDef: { field: colName },
        node,
        data: { agGridRowId: _, ...otherData },
      } = event;

      if (!colName) {
        return;
      }

      const updatedManualCorrection = masterConfiguration?.manualCorrectionsInterest ?? [];

      if (node.rowIndex) {
        updatedManualCorrection[node.rowIndex] = otherData;
      }

      await updateMasterConfiguration({
        ...masterConfiguration,
        manualCorrectionsInterest: updatedManualCorrection,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const handleDelete = useCallback(
    async ({ gridProps: { node } }: ButtonCellRendererProps) => {
      if (node.rowIndex === undefined || node.rowIndex === null) {
        return;
      }

      await updateMasterConfiguration({
        ...masterConfiguration,
        manualCorrectionsInterest: masterConfiguration?.manualCorrectionsInterest.filter(
          (_, index) => index !== node.rowIndex,
        ),
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const { modal, openModal } = useDeleteRowConfirmModal({
    onConfirm: handleDelete,
  });

  const columnDefs = useColumns({
    apiParams,
    handleDeleteModal: openModal,
  });

  return {
    agGridProps,
    columnDefs,
    modal,
    onChange,
    tableRef,
  };
};

export type ViewProps = ReturnType<typeof useController>;
