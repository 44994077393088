import type { UseQueryOptions } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import type { APIParams } from '../..';
import type {
  GetMasterConfigurationByIdError,
  GetMasterConfigurationVariables,
  MasterConfiguration,
} from '../dice';
import type * as Schemas from '../dice/diceSchemas';
import type { GetByFileIdsFromGridFSVariables } from '../griddy';
import {
  useDeleteMasterConfigurationById,
  useDiceContext,
  useGetMasterConfiguration,
  usePutMasterConfiguration,
  useUpsertMasterConfiguration,
} from '../dice';
import { useGriddyContext, useSaveJsonNodesInGridFS } from '../griddy';

interface UseMasterConfigurationParams<T = Schemas.MasterConfiguration> extends APIParams {
  /**
   * use query options for `useGetMasterConfigurationById` query
   */
  useQueryOptions?: Omit<
    UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, T>,
    'queryKey' | 'queryFn'
  >;
}

export const useMasterConfiguration = <T = Schemas.MasterConfiguration>({
  masterConfigurationId: configId,
  useQueryOptions,
}: UseMasterConfigurationParams<T> = {}) => {
  const queryClient = useQueryClient();

  const { queryKeyFn } = useGriddyContext();
  const { queryKeyFn: diceQueryKeyFn } = useDiceContext();

  const { mutateAsync: deleteMasterConfigurationById } = useDeleteMasterConfigurationById();

  const gridFsVariables: GetByFileIdsFromGridFSVariables = useMemo(
    () => ({
      pathParams: {
        ids: [configId!],
      },
    }),
    [configId],
  );

  const deleteMasterConfiguration = useCallback(
    async (masterConfigId: string) => {
      if (masterConfigId) {
        return await deleteMasterConfigurationById({
          pathParams: {
            id: masterConfigId,
          },
        });
      }
    },
    [deleteMasterConfigurationById],
  );

  const gridFSFileQueryKey = queryKeyFn({
    operationId: 'getByFileIdsFromGridFS',
    path: '/objects/{ids}',
    variables: gridFsVariables,
  });

  // TODO: remove together with useYear(s) + old configuration overview panel
  const { mutateAsync: mutateAsyncGridFs } = useSaveJsonNodesInGridFS({
    onMutate(data) {
      queryClient.setQueryData(gridFSFileQueryKey, data);
    },
    onSuccess(data) {
      queryClient.setQueryData(gridFSFileQueryKey, data);
    },
  });

  // TODO: remove together with useYear(s) + old configuration overview panel
  const { mutateAsync: postMasterConfigurationAsync } = useUpsertMasterConfiguration({});

  const { mutateAsync: updateMasterConfigurationAsync, isPending: isMasterConfigurationUpdating } =
    usePutMasterConfiguration({
      onMutate({ pathParams, body }) {
        // we need to cancel any ongoing requests for the same master configuration
        // This will override optimistic update when slow network and previous request is still pending
        queryClient.cancelQueries({
          queryKey: diceQueryKeyFn({
            operationId: 'getMasterConfiguration',
            path: '/master-configurations/{masterConfigurationId}',
            variables: {
              pathParams: {
                masterConfigurationId: pathParams.masterConfigurationId,
              },
            },
          }),
        });
        queryClient.setQueryData(
          diceQueryKeyFn({
            operationId: 'getMasterConfiguration',
            path: '/master-configurations/{masterConfigurationId}',
            variables: {
              pathParams: {
                masterConfigurationId: pathParams.masterConfigurationId,
              },
            },
          }),
          (old: MasterConfiguration) => ({ ...old, ...body }),
        );
      },
    });

  const variables: GetMasterConfigurationVariables = useMemo(
    () => ({
      pathParams: {
        masterConfigurationId: configId!,
      },
    }),
    [configId],
  );

  const { data: masterConfiguration, isPending: isMasterConfigurationPending } =
    useGetMasterConfiguration(variables, {
      ...useQueryOptions,
      enabled: !!configId,
    });

  const customLabelMap = useMemo(
    () =>
      new Map(
        (masterConfiguration as Schemas.MasterConfiguration | undefined)?.customLabels?.map(
          ({ key = '', name }) => [key, name],
        ),
      ),
    [masterConfiguration],
  );

  const getCustomLabel = useCallback((key: string) => customLabelMap.get(key), [customLabelMap]);

  const updateMasterConfiguration = useCallback(
    async <TData = T>(newMasterConfiguration: TData) => {
      const masterConfig = newMasterConfiguration as Schemas.MasterConfiguration;
      if (!masterConfig.name) {
        throw new Error('Master configuration must not be empty');
      }
      const newConfig: Schemas.MasterConfiguration = {
        ...masterConfig,
        updatedAt: Date.now(),
      };

      const newMasterConfigId = masterConfig.id;

      const masterConfigurationResponse = await updateMasterConfigurationAsync({
        body: newConfig,
        pathParams: {
          masterConfigurationId: newMasterConfigId,
        },
      });

      return { masterConfiguration: newConfig, masterConfigurationResponse };
    },
    [updateMasterConfigurationAsync],
  );

  // TODO: remove together with useYear(s) + old configuration overview panel
  /**
   * Create master configuration. For updating use `updateMasterConfiguration`!
   */
  const createMasterConfiguration = useCallback(
    async <TData = T>(newMasterConfiguration: TData) => {
      const masterConfigurationToCreate = newMasterConfiguration as MasterConfiguration;

      // FIXME: This will be removed once we tackle single master configuration and ID is generated by BE
      const [savedMasterConfiguration] =
        (await mutateAsyncGridFs({
          body: [masterConfigurationToCreate],
          queryParams: {
            type: 'MASTER_CONFIGURATIONS',
          },
        })) ?? [];

      if (savedMasterConfiguration?.id) {
        masterConfigurationToCreate.id = savedMasterConfiguration.id;
        await postMasterConfigurationAsync({
          body: masterConfigurationToCreate,
        });
      }

      return masterConfigurationToCreate;
    },
    [mutateAsyncGridFs, postMasterConfigurationAsync],
  );

  return {
    createMasterConfiguration,
    deleteMasterConfiguration,
    getCustomLabel,
    isMasterConfigurationUpdating,
    isPending: isMasterConfigurationUpdating || isMasterConfigurationPending,
    /**
     * Master configuration with optimistic updates.
     */
    masterConfiguration,
    /**
     * @deprecated use `masterConfiguration` instead
     */
    unsafe_masterConfiguration: masterConfiguration as T,
    updateMasterConfiguration,
  };
};

export type UseMasterConfigurationReturnType = ReturnType<typeof useMasterConfiguration>;

export type UpdateMasterConfiguration =
  UseMasterConfigurationReturnType['updateMasterConfiguration'];
