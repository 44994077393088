import type { UseQueryOptions } from '@tanstack/react-query';
import type { IDetailCellRendererParams } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import type { APIParams } from '@org/query';
import { griddy } from '@org/query';

import { columnNames } from '../../constants';

export const useGetAllAcctIdToJobIdsMapByAccountRange = (
  variables: griddy.GetAllAcctIdToJobIdsMapByAccountRangeVariables,
  options?: Omit<
    UseQueryOptions<
      griddy.GetAllAcctIdToJobIdsMapByAccountRangeResponse,
      griddy.GetAllAcctIdToJobIdsMapByAccountRangeError,
      griddy.GetAllAcctIdToJobIdsMapByAccountRangeVariables
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const { fetcherOptions } = griddy.useGriddyContext();
  return useQuery<
    griddy.GetAllAcctIdToJobIdsMapByAccountRangeResponse,
    griddy.GetAllAcctIdToJobIdsMapByAccountRangeError,
    griddy.GetAllAcctIdToJobIdsMapByAccountRangeVariables
  >({
    queryFn: () =>
      griddy.fetchGetAllAcctIdToJobIdsMapByAccountRange({ ...fetcherOptions, ...variables }),
    queryKey: ['getAllAcctIdToJobIdsMapByAccountRange'],
    ...options,
  });
};

interface ControllerProps extends IDetailCellRendererParams {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  data: griddy.CostUnit;
}

const colName = columnNames.selectRevenueAccountsConfiguration.$path;

export const useController = (props: ControllerProps) => {
  const { apiParams, node, data } = props;
  const { yearId, masterConfigurationId } = apiParams;
  const { selectRevenueAccountsConfiguration, shortName } = data;

  const { data: accounts = {}, isPending: accountsIsLoading } =
    useGetAllAcctIdToJobIdsMapByAccountRange(
      {
        body: {
          fileType: 'JOURNAL',
          yearId,
          masterConfigurationId,
        },
      },
      {
        staleTime: 0,
      },
    );

  const { data: accountLabels, isPaused: accountLabelsIsLoading } =
    griddy.useGetAllAccountIdToAccountDescriptionMap(
      {
        queryParams: {
          fileType: 'ACCOUNT_PLAN',
          yearId,
        },
      },
      {
        staleTime: Number.POSITIVE_INFINITY,
      },
    );

  const {
    accountIds = [],
    costCenterIgnoreMappings = [],
    mappingKeys = [],
  } = selectRevenueAccountsConfiguration ?? {};

  const options = useMemo(
    () =>
      Object.keys(accounts).map((accountNumber) => ({
        label: `${accountNumber} ${accountLabels?.[accountNumber] ?? ''}`,
        value: accountNumber.toString(),
      })) ?? [],
    [accountLabels, accounts],
  );

  const handleSelectChange = useCallback(
    (value: string[]) => {
      node.setDataValue(colName, {
        accountIds: value,
        costCenterIgnoreMappings: [],
        mappingKeys: [],
      });
    },
    [node],
  );

  const handleMappingChange = useCallback(
    (
      mapping: Pick<
        griddy.SelectRevenueAccountsConfiguration,
        'costCenterIgnoreMappings' | 'mappingKeys'
      >,
    ) => {
      node.setDataValue(colName, {
        ...selectRevenueAccountsConfiguration,
        ...mapping,
      });
    },
    [node, selectRevenueAccountsConfiguration],
  );

  return {
    ...props,
    accountIds,
    apiParams,
    handleMappingChange,
    handleSelectChange,
    isLoading: accountLabelsIsLoading || accountsIsLoading,
    mappingObject: {
      costCenterIgnoreMappings,
      mappingKeys,
    },
    options,
    selectRevenueAccountsConfiguration,
    shortName,
  };
};

export type ViewProps = ReturnType<typeof useController>;
