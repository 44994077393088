import type { GridReadyEvent } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';

interface UseAgGridDataProps<T> {
  data?: T[];
  dataUpdatedAt?: number;
  /**
   * Reference to ag-grid
   */
  agGridRef: RefObject<AgGridReact>;
  onGridReady?: (event: GridReadyEvent<T>) => void;
}

/**
 * Hook for fetching data from hook and setting it to ag-grid
 * @example
 * const { onGridReady, rowData } = useAgGridData({
 *  data,
 *  agGridRef: tableRef,
 * });
 */
export const useAgGridData = <T>(props: UseAgGridDataProps<T>) => {
  const { data, agGridRef, onGridReady, dataUpdatedAt } = props;

  useEffect(() => {
    agGridRef?.current?.api?.setGridOption('rowData', data);
  }, [agGridRef, data, dataUpdatedAt]);

  const wrappedOnGridReady = useCallback(
    (event: GridReadyEvent<T>) => {
      onGridReady?.(event);
      agGridRef?.current?.api?.setGridOption('rowData', data!);
    },
    [agGridRef, data, onGridReady],
  );

  return {
    onGridReady: wrappedOnGridReady,
  };
};
