import type { UseDisclosureProps } from '@nextui-org/react';
import type { FC } from 'react';
import React, { useState } from 'react';

import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';

import type { dice } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { Button, Modal, ModalContent } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import {
  AddNewPublicFacilityModalContent,
  DeletePublicFacilityModalContent,
  RenamePublicFacilityModalContent,
} from './components';
import { useController } from './useController';

type PublicFacilityModalContentKeys =
  | 'ADD_NEW_PUBLIC_FACILITY'
  | 'RENAME_PUBLIC_FACILITY'
  | 'DELETE_PUBLIC_FACILITY';

export interface PublicFacilitiesModalContentProps {
  createPublicFacility: (data: dice.PublicFacilityDTO) => Promise<dice.PublicFacilityDTO>;
  disclosureState: UseDisclosureProps;
  sortedPublicFacilities?: dice.PublicFacilityDTO[];
  selectedPublicFacility: dice.PublicFacilityDTO;
  updatePublicFacility: (data: dice.PublicFacilityDTO) => Promise<dice.PublicFacilityDTO>;
  deletePublicFacility: (publicFacilityId: string) => Promise<void>;
}

interface PublicFacilitiesOverviewProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const View: FC<PublicFacilitiesOverviewProps> = ({
  LinkComponent,
  getLinkProps,
  sortedPublicFacilities,
  disclosureState,
  createPublicFacility,
  updatePublicFacility,
  deletePublicFacility,
}) => {
  const [selectedPublicFacility, setSelectedPublicFacility] = useState<dice.PublicFacilityDTO>({});
  const [activeModalKey, setActiveModalKey] =
    useState<PublicFacilityModalContentKeys>('ADD_NEW_PUBLIC_FACILITY');
  const { t } = useTranslation();
  const { onOpen, onOpenChange, isOpen } = disclosureState;

  const MODAL_CONTENT_LIST: Record<
    PublicFacilityModalContentKeys,
    React.FC<PublicFacilitiesModalContentProps>
  > = {
    ADD_NEW_PUBLIC_FACILITY: AddNewPublicFacilityModalContent,
    DELETE_PUBLIC_FACILITY: DeletePublicFacilityModalContent,
    RENAME_PUBLIC_FACILITY: RenamePublicFacilityModalContent,
  };

  const SelectedModalContent = MODAL_CONTENT_LIST[activeModalKey];

  const modalContentProps = {
    createPublicFacility,
    deletePublicFacility,
    disclosureState,
    selectedPublicFacility,
    sortedPublicFacilities,
    updatePublicFacility,
  };

  const handleOpenTileOptions = (
    key: PublicFacilityModalContentKeys,
    publicFacility: dice.PublicFacilityDTO,
  ) => {
    onOpen();
    setActiveModalKey(key);
    setSelectedPublicFacility(publicFacility);
  };

  return (
    <>
      <div className="h-full">
        <div className="mx-auto my-4 max-w-7xl px-4">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8 lg:grid-cols-4">
            <div className="mb-4">
              <Button
                className="flex cursor-pointer items-center !rounded-md"
                onClick={() => {
                  onOpen();
                  setActiveModalKey('ADD_NEW_PUBLIC_FACILITY');
                }}
                size="md"
                variant="secondary"
              >
                <PlusIcon className="h-4 w-4" />
                <div>{t('main:clients.publicFacilitiesOverviewPanel.header')}</div>
              </Button>
            </div>
          </div>
        </div>

        <div className="scrollable-container mx-auto h-[calc(100vh-240px)] max-w-7xl">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8 lg:grid-cols-4">
            {sortedPublicFacilities?.map((publicFacility) => {
              const { name = '', id = '' } = publicFacility;
              return (
                <Card
                  as={LinkComponent}
                  className="h-[100px] max-w-[340px] rounded-md p-4 hover:bg-actions-hover hover:text-black hover:no-underline"
                  key={name}
                  {...getLinkProps(id)}
                >
                  <div className="flex justify-between space-x-4">
                    <div>{name}</div>
                    <div className="">
                      <Dropdown>
                        <DropdownTrigger className="h-8 w-8 rounded p-1 hover:bg-gray-200">
                          <EllipsisVerticalIcon
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Static Actions">
                          <DropdownItem
                            key={t('common:contextMenu.edit')}
                            onClick={() =>
                              handleOpenTileOptions('RENAME_PUBLIC_FACILITY', publicFacility)
                            }
                            startContent={
                              <span className="menu-item-icon appkiticon icon-edit-outline" />
                            }
                          >
                            {t('common:contextMenu.edit')}
                          </DropdownItem>
                          <DropdownItem
                            className="text-red-500"
                            color="danger"
                            key="delete"
                            onClick={() =>
                              handleOpenTileOptions('DELETE_PUBLIC_FACILITY', publicFacility)
                            }
                            startContent={
                              <span className="menu-item-icon appkiticon icon-delete-outline" />
                            }
                          >
                            {t('common:contextMenu.delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          <SelectedModalContent {...modalContentProps} />
        </ModalContent>
      </Modal>
    </>
  );
};

export const PublicFacilitiesOverview = bindControllers(View, [useController]);

PublicFacilitiesOverview.displayName = 'ClientPublicFacilitiesOverviewsOverview';
