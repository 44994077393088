import type { aggregated, dice } from '@org/query';

import { CostUnitReportTable } from '../../components';

interface CostUnitReportPageProps {
  selectedReport: aggregated.ExtendedReportObjectsResponse;
  costUnitConfig: dice.CostUnit[];
}

export const CostUnitReportPage = ({ selectedReport, costUnitConfig }: CostUnitReportPageProps) => (
  <CostUnitReportTable
    configurationCostUnits={costUnitConfig}
    reportCostUnits={(selectedReport.costUnits as dice.CostUnit[]) ?? []}
  />
);
