import type {
  CellValueChangedEvent,
  ColDef,
  GetRowIdParams,
  RowDragEvent,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { CostTypeAccountRowData } from '@org/features';
import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';
import {
  addToArrayAtIndexMut,
  getCellClassesForGroups,
  getCopyOfTableData,
  removeFromArrayAtIndexMut,
} from '@org/utils';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
};

export const useController = (apiParams: UseControllerProps) => {
  const tableRef = useRef<AgGridReact<CostTypeAccountRowData>>(null);

  const { t } = useTranslation();

  const { costTypeAccountConfig, extendedCostTypeAccountConfig, updateCostTypeAccount } =
    aggregated.useCostTypeAccounts(apiParams);

  const { rowData } = extendedCostTypeAccountConfig ?? {};
  const { costTypeAccounts = [] } = costTypeAccountConfig ?? {};

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: rowData,
  });

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererParams: {
        suppressCount: true,
      },
      checkboxSelection: false,
      editable: ({ node }) => !node.group,
      field: 'name',
      flex: 3,
      headerName: t('main:manageMenu.costTypeAccountConfiguration.tableColumns.name'),
      rowDrag: ({ node }) => !node.group,
    }),
    [t],
  );

  const handleOnChange = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      data: eventData,
    } = event;

    if (!colName) {
      return;
    }

    const costTypeAccountId = eventData.id;

    if (costTypeAccountId) {
      const updated = costTypeAccounts.map((currentEntry) => {
        if (currentEntry.id === costTypeAccountId) {
          currentEntry.name = eventData.name;
        }

        return currentEntry;
      });

      const config = {
        ...costTypeAccountConfig,
        costTypeAccounts: updated,
      };

      await updateCostTypeAccount(config);
    }
  });

  const addNewRow = useCallback(
    async (props: { node: { key: dice.CostTypeAccountDTO['type'] } }) => {
      const {
        node: { key },
      } = props;

      const type = key;

      const newCostTypeAccount: dice.CostTypeAccountDTO = {
        accountIds: [],
        adjustedAccountIds: [],
        name: t('main:manageMenu.costTypeAccountConfiguration.newCostTypeAccount'),
        rank: costTypeAccounts.length,
        type,
      };

      const config = {
        ...costTypeAccountConfig,
        costTypeAccounts: [...(costTypeAccountConfig?.costTypeAccounts ?? []), newCostTypeAccount],
      };

      await updateCostTypeAccount(config);
    },
    [costTypeAccountConfig, costTypeAccounts.length, t, updateCostTypeAccount],
  );

  const deleteRow = useCallback(
    async ({ data }: ButtonCellRendererProps) => {
      const costTypeAccountId = data.id;
      const config = {
        ...costTypeAccountConfig,
        costTypeAccounts: costTypeAccountConfig?.costTypeAccounts?.filter(
          (acc) => acc.id !== costTypeAccountId,
        ),
      };

      await updateCostTypeAccount(config);
    },
    [costTypeAccountConfig, updateCostTypeAccount],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonCellRendererProps>(
    {
      onConfirm: deleteRow,
    },
  );

  const columnDefs = useColumns({
    addNewRow,
    handleDeleteModal,
  });

  const updateRankAndSetRows = useCallback((ccItems: CostTypeAccountRowData[]) => {
    const updatedRank = ccItems.map((node, index) => {
      node.rank = index + 1;
      return node;
    });
    tableRef.current?.api.updateGridOptions({
      rowData: updatedRank,
    });
  }, []);

  const onRowDragMove = useCallback(
    (event: RowDragEvent) => {
      const movingNodes = event.nodes;
      const { overNode, api } = event;

      if (!overNode) {
        return;
      }

      const INVALID_INDEX = -1;

      const overRowIsGroupOrMainCategory = overNode?.group ?? overNode?.level === 0;

      // DO NOTHING - when insert directly in the main category (primary/indirect group) or moving over group
      if (overRowIsGroupOrMainCategory) {
        return;
      }

      const overData: CostTypeAccountRowData = overNode?.data;
      const movingDataRows: CostTypeAccountRowData[] = movingNodes.map((node) => node.data);
      const rowsHaveSameShortName = movingDataRows.some((cc) => cc.id === overData?.id);

      // DO NOTHING - when dragging over same node
      if (rowsHaveSameShortName) {
        return;
      }

      const updatedMovingDataRows = movingDataRows.map((node) => ({
        ...node,
        type: overData.type,
      }));

      const allNodesData: CostTypeAccountRowData[] = getCopyOfTableData(api);

      let fromIndex = INVALID_INDEX;

      updatedMovingDataRows.forEach((movingDataRow) => {
        fromIndex = allNodesData.findIndex((nodeData) => nodeData.id === movingDataRow.id);
        removeFromArrayAtIndexMut(allNodesData, fromIndex);
      });

      const toIndex = allNodesData.findIndex((nodeData) => nodeData.id === overData.id);

      const areAddAndRemoveIndexesValid = toIndex > INVALID_INDEX && fromIndex > INVALID_INDEX;

      // DO NOTHING - when invalid indexes
      if (!areAddAndRemoveIndexesValid) {
        return;
      }

      if (fromIndex > toIndex) {
        addToArrayAtIndexMut(allNodesData, updatedMovingDataRows, toIndex);
      } else {
        addToArrayAtIndexMut(allNodesData, updatedMovingDataRows, toIndex + 1);
      }

      updateRankAndSetRows(allNodesData);

      tableRef?.current?.api?.clearFocusedCell();
    },
    [updateRankAndSetRows],
  );

  const onRowDragEnd = useCallback(
    async (event: RowDragEvent) => {
      const { api } = event;
      const tableData: CostTypeAccountRowData[] = getCopyOfTableData<CostTypeAccountRowData>(api);

      const updatedCostTypeAccounts = tableData.map((tableRow) => {
        const { accounts: _, ...rest } = tableRow;
        const originalCostTypeAccountData = costTypeAccounts.find(
          (item) => item.id === tableRow.id,
        );

        let accountIds = originalCostTypeAccountData?.accountIds ?? [];
        let adjustedAccountIds = originalCostTypeAccountData?.adjustedAccountIds ?? [];

        // remove account ids if type is changed
        if (tableRow.type !== originalCostTypeAccountData?.type) {
          accountIds = [];
          adjustedAccountIds = [];
        }

        const update: dice.CostTypeAccountDTO = {
          ...rest,
          accountIds,
          adjustedAccountIds,
        };
        return update;
      });

      const config = {
        ...costTypeAccountConfig,
        costTypeAccounts: updatedCostTypeAccounts,
      };

      await updateCostTypeAccount(config);

      tableRef?.current?.api.deselectAll();
    },
    [costTypeAccountConfig, costTypeAccounts, updateCostTypeAccount],
  );

  const getRowId = useCallback(
    ({ data: getRowParamsData }: GetRowIdParams) => getRowParamsData?.id,
    [],
  );

  const detailCellRendererParams = useMemo(
    () => ({
      apiParams,
    }),
    [apiParams],
  );

  return {
    addNewRow,
    agGridProps,
    autoGroupColumnDef,
    columnDefs,
    defaultColDef,
    detailCellRendererParams,
    getRowId,
    handleOnChange,
    hasExpenseRows: rowData?.some(({ type }) => type === 'EXPENSE'),
    hasRevenueRows: rowData?.some(({ type }) => type === 'REVENUE'),
    modal,
    onRowDragEnd,
    onRowDragMove,
    rowData,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
