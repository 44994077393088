import type { ColDef, ExcelExportParams, GetRowIdFunc } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { APIParams } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { ExcelReportType, getAlignmentIndentClass } from '@org/utils';

import { getCellClasses } from '../ReportTable/helpers';
import { getDefaultExcelExportParams } from '../ReportTable/helpers/getDefaultExcelExportParams';
import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<
    'masterConfigurationId' | 'yearId' | 'clientId' | 'publicFacilityId' | 'type'
  >;
}

export type ControllerType = ReturnType<typeof useController>;

const getRowId: GetRowIdFunc = ({ data }) => String(data.rowIndex);

const defaultColDef = {
  cellClass: getCellClasses(),
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  useValueFormatterForExport: true,
} satisfies ColDef;

export function useController({ apiParams }: ControllerProps) {
  const columnsDefs = useColumns();
  const gridRef = useRef<AgGridReact>(null);
  const { clientId } = apiParams;
  const { publicFacility } = aggregated.usePublicFacilities(apiParams);
  const { year } = aggregated.useYear(apiParams);

  const { priceSheetGroups = [], isLoading } = aggregated.usePriceSheetReport(apiParams);

  const { t } = useTranslation();

  const autoGroupColumnDef = useMemo(
    () =>
      ({
        cellRendererParams: {
          suppressCount: true,
        },
        field: 'subGroupName',
        cellClass: getCellClasses(getAlignmentIndentClass),
        filterValueGetter: ({ data }) => data?.subGroupName,
        flex: 1,
        headerName: t('main:manageMenu.priceSheet.tableColumns.description'),
      }) satisfies ColDef<ReturnType<typeof aggregated.flattenPriceSheetReportGroups>[number]>,
    [t],
  );

  const { getClient } = aggregated.useClients({});
  const client = getClient(clientId);

  const handleExport = useCallback(() => {
    gridRef.current?.api?.exportDataAsExcel();
  }, []);

  const defaultExcelExportParams = useMemo<ExcelExportParams>(
    () =>
      getDefaultExcelExportParams(
        `${client?.clientName}_${publicFacility?.name ?? ''}_${year?.year ?? ''}_Preisblatt`,
        ExcelReportType.PRICE_SHEET,
      ),
    [client?.clientName, publicFacility?.name, year?.year],
  );

  const agGridProps = useAgGridData({
    agGridRef: gridRef,
    data: priceSheetGroups,
  });

  return {
    autoGroupColumnDef,
    columnsDefs,
    defaultColDef,
    isLoading,
    handleExport,
    getRowId,
    gridRef,
    defaultExcelExportParams,
    agGridProps,
  };
}
