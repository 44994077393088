import type { dice } from '@org/query';
import { sortByKey } from '@org/utils/array';
import * as objUtil from '@org/utils/object';

import type { CostUnitReportRow } from '../models';

export const createCostCentersAggregatedRows = <Keys extends keyof dice.CostUnit>(
  costUnits: dice.CostUnit[],
  translationKeys: {
    [key in Keys]?: string;
  },
) =>
  Object.entries(translationKeys).flatMap(([costUnitKey, translation]) => {
    const struct: Record<string, Record<string, number>> = {};
    costUnits.forEach((costUnit) =>
      Object.entries(costUnit[costUnitKey as Keys] ?? {}).forEach(([key, value]) => {
        const item = objUtil.get(struct, key) || {};
        objUtil.set(struct, key, objUtil.set(item, costUnit.shortName, value));
      }),
    );

    const rows = Object.entries(struct).map(([key, value]) => {
      const currentRow: CostUnitReportRow = {
        costRevenueCalculationTotal: Object.values(value).reduce((acc, curr) => acc + curr, 0),
        costTypeDescription: key,
        parentCostTypeDescription: translation,
        ...value,
      };

      return currentRow;
    });

    return sortByKey(rows, 'costTypeDescription') as CostUnitReportRow[];
  });
