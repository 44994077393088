import _ from 'lodash';

export const combineTransactions = (transactions, modifiedTransactions, removeOriginal, t) => {
  if (!modifiedTransactions || !transactions) {
    return transactions;
  }
  const newTransactions = structuredClone(transactions);
  const { newTransactionsByIndex, newTransactionsByAssetNumber } =
    mapNewTransactions(newTransactions);
  for (const transaction of modifiedTransactions) {
    const matchingTransaction =
      newTransactionsByIndex.get(transaction.index) ??
      newTransactionsByAssetNumber.get(transaction.assetNumber);
    if (!matchingTransaction) {
      continue;
    }

    if (transaction.enabled !== null && transaction.enabled !== undefined) {
      if (!removeOriginal) {
        matchingTransaction.enabledOriginal = matchingTransaction.enabled;
      }
      matchingTransaction.enabled = transaction.enabled;
    }
    // if (
    //   transaction.currentIsOperatingAsset !== null &&
    //   transaction.currentIsOperatingAsset !== undefined
    // ) {
    //   if (!removeOriginal) {
    //     matchingTransaction.isOperatingAssetOriginal = matchingTransaction.currentIsOperatingAsset;
    //   }
    //   matchingTransaction.currentIsOperatingAsset = transaction.currentIsOperatingAsset;
    // }
    if (transaction.clientsCostCenterName) {
      if (!removeOriginal) {
        matchingTransaction.clientsCostCenterNameOriginal =
          matchingTransaction.clientsCostCenterName || t('common:undefined');
      }
      matchingTransaction.clientsCostCenterName = transaction.clientsCostCenterName;
    }
    if (transaction.assetCategory) {
      if (!removeOriginal) {
        matchingTransaction.assetCategoryOriginal =
          matchingTransaction.assetCategory || t('common:undefined');
      }
      matchingTransaction.assetCategory = transaction.assetCategory;
    }
    if (transaction.assetCostCenterName) {
      if (!removeOriginal) {
        matchingTransaction.assetCostCenterNameOriginal =
          matchingTransaction.assetCostCenterName || t('common:undefined');
      }
      matchingTransaction.assetCostCenterName = transaction.assetCostCenterName;
    }
  }
  return newTransactions;
};

function mapNewTransactions(newTransactions) {
  const newTransactionsByIndex = new Map();
  const newTransactionsByAssetNumber = new Map();
  for (const newTransaction of newTransactions) {
    if (newTransaction.index) {
      newTransactionsByIndex.set(newTransaction.index, newTransaction);
    }
    if (newTransaction.assetNumber) {
      newTransactionsByAssetNumber.set(newTransaction.assetNumber, newTransaction);
    }
  }
  return { newTransactionsByAssetNumber, newTransactionsByIndex };
}
