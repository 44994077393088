import type { TFunction } from '@org/locales';

import type { CostCenter } from '../../griddy';
import type { ReportsResponse } from '../../rave';
import { getGroupTotalsOfRow } from './groupTotals';

interface GenerateOperatingInterestRateReportProps {
  reportObject: ReportsResponse;
  t: TFunction;
  primaryCostCentersGrouped: [string, CostCenter[]][];
  indirectCostCentersGrouped: [string, CostCenter[]][];
}

const avgEntriesDescription = new Set([
  'RBV_CAPITAL_LEDGER_ACCOUNT_AVG',
  'RBV_CAPITAL_IMPUTED_AVG',
  'RBV_CAPITAL_OPERATING_ASSET_AVG',
]);

const generateOperatingAssetRows = ({
  reportObject,
  indirectCostCentersGrouped,
  primaryCostCentersGrouped,
  t,
}: GenerateOperatingInterestRateReportProps) => {
  const avgRowIndex = reportObject.newInterestTableReport?.operating?.entries?.findIndex(
    ({ description = '' }) => avgEntriesDescription.has(description),
  );

  return (
    reportObject.newInterestTableReport?.operating?.entries
      ?.toSpliced(avgRowIndex!, 1)
      ?.map((row, rowIndex) => {
        const { ccShortNameToValueMap, description, reportingDate, imputedInterestAmount } = row;
        return {
          ...ccShortNameToValueMap,
          group: t('main:reportPanels.interestTable.tableRowsData.OPERATING_ASSETS'),
          imputedInterestAmount,
          reportingDate,
          rowDescription: t(`main:reportPanels.interestTable.tableRowsData.${description}`),
          rowIndex,
          subGroup: t(`main:reportPanels.interestTable.tableRowsData.${description}_HEADER`),
          ...getGroupTotalsOfRow({
            indirectCCGroups: indirectCostCentersGrouped,
            primaryCCGroups: primaryCostCentersGrouped,
            row: {
              costCenterValueMap: ccShortNameToValueMap,
            },
          }),
        };
      }) ?? []
  );
};

const generateWithdrawCapitalRows = (
  {
    reportObject,
    indirectCostCentersGrouped,
    primaryCostCentersGrouped,
    t,
  }: GenerateOperatingInterestRateReportProps,
  offset: number,
) =>
  reportObject.newInterestTableReport?.withdrawCapital?.entries?.flatMap((item, index) => {
    const { name, entries } = item;

    return entries?.flatMap((row, rowIndex) => {
      const { ccShortNameToValueMap, description = '', reportingDate, imputedInterestAmount } = row;
      if (avgEntriesDescription.has(description)) {
        return [];
      }

      return [
        {
          ...ccShortNameToValueMap,
          group: t('main:reportPanels.interestTable.tableRowsData.WITHDRAW_CAPITAL'),
          imputedInterestAmount,
          reportingDate,
          rowDescription: t(`main:reportPanels.interestTable.tableRowsData.${description}`),
          rowIndex: index * 2 + rowIndex + offset,
          subGroup: name,
          ...getGroupTotalsOfRow({
            indirectCCGroups: indirectCostCentersGrouped,
            primaryCCGroups: primaryCostCentersGrouped,
            row: {
              costCenterValueMap: ccShortNameToValueMap,
            },
          }),
        },
      ];
    });
  }) ?? [];

const summaryOrder: Record<string, number> = {
  IMPUTED_INTEREST: 2,
  IMPUTED_INTEREST_RATE: 1,
  INTEREST_BEARING_CAPITAL: 0,
};

const generateTotalRows = (
  {
    reportObject,
    indirectCostCentersGrouped,
    primaryCostCentersGrouped,
    t,
  }: GenerateOperatingInterestRateReportProps,
  offset: number,
) => {
  const rowData =
    reportObject.newInterestTableReport?.summary?.entries?.map((row) => {
      const { ccShortNameToValueMap, description = '', imputedInterestAmount, reportingDate } = row;
      const isImputedInterestRate = description === 'IMPUTED_INTEREST_RATE';
      return {
        ...ccShortNameToValueMap,
        disableRowFormatting: isImputedInterestRate,
        disableHighlight: isImputedInterestRate,
        group: null,
        imputedInterestAmount: isImputedInterestRate ? null : imputedInterestAmount,
        reportingDate,
        rowDescription: t(`main:reportPanels.interestTable.tableRowsData.${description}`),
        rowIndex: (summaryOrder[description] ?? 0) + offset,
        subGroup: null,
        ...getGroupTotalsOfRow({
          indirectCCGroups: indirectCostCentersGrouped,
          primaryCCGroups: primaryCostCentersGrouped,
          row: {
            ...row,
            costCenterValueMap: ccShortNameToValueMap,
            ...(isImputedInterestRate && {
              aggFunction: (aggRow, costCenters) =>
                aggRow.costCenterValueMap[costCenters.at(0)?.shortName ?? ''],
            }),
          },
        }),
      };
    }) ?? [];
  return rowData;
};

export const generateOperatingInterestRateReport = (
  params: GenerateOperatingInterestRateReportProps,
) => {
  const operatingAssetRows = generateOperatingAssetRows(params);
  let offset = operatingAssetRows.length;
  const withdrawCapitalRows = generateWithdrawCapitalRows(params, offset);
  offset += withdrawCapitalRows.length + 1;
  return [...operatingAssetRows, ...withdrawCapitalRows, ...generateTotalRows(params, offset)];
};
