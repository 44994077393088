import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice, griddy } from '@org/query';

import { CostCenterTableCheckbox } from '../../../AllocationKeysMasterDetail/components/CostCenterTable/components';

interface UseColumnsProps {
  costCenterDistributionMap?: dice.PlannedDirectEntryDistributionConfigDTO['allocationMapping'];
  onSelectionChanged: (
    selection: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'],
  ) => Promise<void>;
  rowData: dice.AllocationConfigCostCenterDTO[];
}

export const useColumns = ({ costCenterDistributionMap, onSelectionChanged }: UseColumnsProps) =>
  useMemo(
    () =>
      [
        {
          cellRenderer: ({ data, value }: ICellRendererParams) => (
            <CostCenterTableCheckbox
              costCenterDistributionMap={costCenterDistributionMap}
              data={data}
              onSelectionChanged={onSelectionChanged}
              value={value}
            />
          ),
          colId: 'longName',
          field: 'longName',
          flex: 2,
        },
      ] satisfies ColDef<griddy.CostCenter>[],
    [costCenterDistributionMap, onSelectionChanged],
  );
