import type { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef, useState } from 'react';

import type { erase } from '@org/query';
import { useInterestReportModal } from '@org/features';
import { aggregated } from '@org/query';

import type { BaseControllerType } from '../types';

export const getAssetTransactionsByCells = (assets: erase.Asset[]) => {
  const { nonOperatingAssetsTransactionsByCC, operatingAssetsTransactionsByCC } = assets.reduce(
    (acc, current) => {
      const { currentIsOperatingAsset, currentBabCCShortName } = current;

      if (!currentBabCCShortName) {
        return acc;
      }

      const assetTransactions = currentIsOperatingAsset
        ? acc.operatingAssetsTransactionsByCC
        : acc.nonOperatingAssetsTransactionsByCC;

      if (!assetTransactions.has(currentBabCCShortName)) {
        assetTransactions.set(currentBabCCShortName, []);
      }

      assetTransactions.get(currentBabCCShortName)?.push(current);

      return acc;
    },
    {
      nonOperatingAssetsTransactionsByCC: new Map<string, erase.Asset[]>(),
      operatingAssetsTransactionsByCC: new Map<string, erase.Asset[]>(),
    },
  );

  return {
    nonOperatingAssetsTransactionsByCC: Object.fromEntries(nonOperatingAssetsTransactionsByCC),
    operatingAssetsTransactionsByCC: Object.fromEntries(operatingAssetsTransactionsByCC),
  };
};

export interface UseControllerProps extends BaseControllerType {}

export const useController = (apiParams: UseControllerProps) => {
  const { publicFacilityId: publicFacility } = apiParams;
  const { assets } = aggregated.useAssets(apiParams);
  const tableGridRef = useRef<AgGridReact>(null);
  const nonOperatingAssetsGridRef = useRef<AgGridReact>(null);
  const { report } = aggregated.useReport(apiParams);

  const [transactionsPresentToFlush, setTransactionsPresentToFlush] = useState(false);

  const { operatingInterestTradeReport, nonOperatingInterestTradeReport } = report ?? {};

  const assetsByCell = useMemo(() => getAssetTransactionsByCells(assets), [assets]);

  const modalProps = useInterestReportModal({
    assetsByCell,
    setTransactionsPresentToFlush,
    transactionsPresentToFlush,
  });

  return {
    apiParams,
    assets,
    modalProps,
    nonOperatingAssetsGridRef,
    nonOperatingInterestTradeReport,
    operatingInterestTradeReport,
    publicFacility,
    setTransactionsPresentToFlush,
    tableGridRef,
    transactionsPresentToFlush,
  };
};

export type ControllerType = ReturnType<typeof useController>;
