// @ts-check
import { useEffect, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { FileOverview } from '@org/pages';

import { FILE_CONTENT_DEFAULT } from '../../components/Clients/FileContentPanel/helper';
import { CONFIG_ITEMS } from '../../components/Clients/Menu/Utils/configMenuItems';
import WorkflowMenu from '../../components/Clients/Menu/WorkflowMenu';
import ErrorBoundary from '../../utils/ErrorHandling/ErrorBoundary';
import { StyledWrapper } from '../styled/StyledComponents';

export const FilesPage = () => {
  const {
    configId = '',
    clientId = '',
    publicFacilityId = '',
    type = '',
    yearId: yearCalculationId = '',
  } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const setTab = (newTab) => {
    setSearchParams((prev) => {
      prev.set('tab', newTab);
      return prev;
    });
  };

  const [configItem, setConfigItem] = useState(configId ? CONFIG_ITEMS[0].value : '');
  const [fileContentToShow, setFileContentToShow] = useState(FILE_CONTENT_DEFAULT);

  useEffect(() => {
    if (configId && !configItem) {
      setConfigItem(CONFIG_ITEMS[0].value);
    }
  }, [configItem, configId]);

  const handleConfigItemClick = (selectedConfigItem) => {
    setConfigItem(selectedConfigItem);
    setFileContentToShow(FILE_CONTENT_DEFAULT);
  };

  return (
    <StyledWrapper>
      <ErrorBoundary>
        <div className="mt-1 grid h-full w-full grid-cols-[1fr_5fr] grid-rows-1 gap-3 p-0">
          <WorkflowMenu
            configItem={configItem}
            selectedConfigId={configId}
            setConfigItem={handleConfigItemClick}
          />
          <div className="h-full w-full">
            <FileOverview
              baseFileType={searchParams.get('fileType')}
              clientId={clientId}
              masterConfigurationId={configId}
              publicFacilityId={publicFacilityId}
              setTab={setTab}
              tab={searchParams.get('tab')}
              type={type}
              yearCalculationId={yearCalculationId}
            />
          </div>
        </div>
      </ErrorBoundary>
    </StyledWrapper>
  );
};
