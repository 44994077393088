import type { dice, griddy } from '../../../../../query/src';
import { NOT_AVAILABLE } from '../../../constants';
import { roundNumberToTwoDigits } from '../../../number';
import { isStringEmptyOrSpaces } from '../../../string';

export const resetAutomaticAllocations = (
  withdrawCapitalConfig: dice.WithdrawCapital[],
  costCenterConfig: dice.CostCenterConfig,
  accountCostCentersMap: Record<number, string[]>,
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse,
) => {
  const defaultAllocationMap = createAllocationStructureFromMapping(
    accountCostCentersMap,
    costCenterConfig,
    flatProfitCenterTree,
  );

  return withdrawCapitalConfig?.map((row) => {
    if (row?.accountsAllocation) {
      Object.entries(row?.accountsAllocation).forEach(
        ([accountId, accountToClientCostCenterMap]) => {
          const newAutomaticallyCreatedMap = defaultAllocationMap?.[accountId] ?? {};
          // creates new automatic map, keeping manual changes from old map
          const newAccountToClientCostCenterMap = {
            ...newAutomaticallyCreatedMap,
            // manual allocations (key NOT_AVAILABLE instead of Client CC)
            [NOT_AVAILABLE]: accountToClientCostCenterMap[NOT_AVAILABLE],
          };
          if (row.accountsAllocation) {
            row.accountsAllocation[accountId] = newAccountToClientCostCenterMap;
          }
        },
      );
    }
    return row;
  });
};

export const resetAllAllocations = (
  withdrawCapitalConfig: dice.WithdrawCapital[],
  costCenterConfig: dice.CostCenterConfig,
  accountCostCentersMap: Record<number, string[]>,
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse,
) => {
  const defaultAllocationMap = createAllocationStructureFromMapping(
    accountCostCentersMap,
    costCenterConfig,
    flatProfitCenterTree,
  );

  return withdrawCapitalConfig?.map((row) => {
    if (row?.accountsAllocation) {
      Object.keys(row?.accountsAllocation).forEach((accountId) => {
        const newAutomaticallyCreatedMap = defaultAllocationMap[accountId] ?? {};
        if (row.accountsAllocation) {
          row.accountsAllocation[accountId] = newAutomaticallyCreatedMap;
        }
      });
    }
    return row;
  });
};

export const createAllocationStructureFromMapping = (
  accountToClientCostCenterMap: Record<number, string[]>,
  costCenterConfig: dice.CostCenterConfig,
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse,
) => {
  const accountToClientCostCenterAllocationMap: Record<
    string,
    Record<string, Record<string, number>>
  > = {};
  if (!accountToClientCostCenterMap) {
    return accountToClientCostCenterAllocationMap;
  }

  Object.entries(accountToClientCostCenterMap).forEach(([accountId, costCentersList]) => {
    const costCenterIds = costCentersList.filter(
      (costCenter) => !isStringEmptyOrSpaces(costCenter),
    );
    if (costCenterIds.length === 0) {
      accountToClientCostCenterAllocationMap[accountId] = { NA: {} };
      return;
    }
    const linearAllocationMap: Record<string, Record<string, number>> = {};
    costCenterIds.forEach((costCenterId) => {
      let babCostCenters: string[] = [];
      Object.entries(costCenterConfig?.costCenterMapping ?? {}).forEach(([, currentMapping]) => {
        if (isCostCenterNameInCostCenter(costCenterId, currentMapping, flatProfitCenterTree)) {
          babCostCenters.push(currentMapping?.shortName ?? '');
        }
      });

      babCostCenters = babCostCenters.filter(Boolean) as string[];

      if (babCostCenters?.length) {
        const allocationPercentage = roundNumberToTwoDigits(100 / babCostCenters.length);
        const clientCostCenterAllocation: Record<string, number> = {};
        babCostCenters.forEach((babCostCenter: string) => {
          clientCostCenterAllocation[babCostCenter] = allocationPercentage;
        });
        linearAllocationMap[costCenterId] = clientCostCenterAllocation;
      } else if (!isStringEmptyOrSpaces(costCenterId)) {
        linearAllocationMap[costCenterId] = {};
      }
    });
    accountToClientCostCenterAllocationMap[accountId] = linearAllocationMap;
  });
  return accountToClientCostCenterAllocationMap;
};

const isCostCenterNameInCostCenter = (
  costCenterId: string,
  costCenterMapping: dice.CostCenter,
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse,
) => {
  const mappedBranches =
    costCenterMapping.mappingKeys?.map(({ id = '' }) => flatProfitCenterTree[id]) ?? [];
  const foundCostCenter = mappedBranches.find((branch) => branch?.costCenter === costCenterId);
  return !!foundCostCenter;
};
