import { forwardRef } from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/outline';

export interface InfoIconProps extends React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> {}

export const InfoIcon = forwardRef<SVGSVGElement, InfoIconProps>((props, ref) => (
  <InformationCircleIcon
    height={16}
    ref={ref}
    width={16}
    {...props}
  />
));
