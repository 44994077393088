/* eslint-disable unicorn/prefer-export-from -- need reexport for documentation */
import type { InitOptions, TFunction as OriginalTFunction } from 'i18next';

import i18n from 'i18next';
import i18nBrowserLangDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation as originalUseTranslation } from 'react-i18next';

import commonDe from './languages/de/common.json';
import mainDe from './languages/de/main.json';
import commonEn from './languages/en/common.json';
import mainEn from './languages/en/main.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const options: InitOptions = {
  // Config possibilities: https://www.npmjs.com/package/i18next-browser-languagedetector
  detection: {
    lookupCookie: 'i18nextLng',

    lookupFromPathIndex: 0,

    lookupFromSubdomainIndex: 0,

    lookupLocalStorage: 'i18nextLng',

    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    // order and from where user language should be detected
    order: [
      'querystring',
      'cookie',
      'localStorage',
      'sessionStorage',
      'navigator',
      'htmlTag',
      'path',
      'subdomain',
    ],
  },

  fallbackLng: 'en',

  interpolation: { escapeValue: false },

  resources: {
    de: {
      common: commonDe,
      main: mainDe,
    },
    en: {
      common: commonEn,
      main: mainEn,
    },
  },

  returnNull: false,

  saveMissing: true,

  saveMissingTo: 'all',

  supportedLngs: ['en', 'de'],
};

export const initI18n = () => {
  if (!i18n.isInitialized && i18nBrowserLangDetector && initReactI18next) {
    i18n.use(i18nBrowserLangDetector).use(initReactI18next).init(options);
  }

  if (typeof window !== 'undefined') {
    (window as unknown as { __localeId__: string }).__localeId__ = i18n.language;
  }

  return i18n;
};

export * from 'react-i18next';
export * from 'i18next';

export const initializedI18n = initI18n();

/**
 * `t` function from i18next used outside of the react functional components.
 *
 * For __React components__ use {@link useTranslation} hook.
 *
 * @see {@link useTranslation}
 * @example
 * import { t } from '@org/locales';
 * const translatedText = t('myTranslationKey');
 */
export const { t } = initializedI18n;

export type TFunction = typeof t | OriginalTFunction;

/**
 * __NEVER PASS__ `t` __FUNCTION TO CHILD COMPONENT__
 *
 * `useTranslation` hook for function react components.
 *
 * For __non react__ function use imported {@link t} function.
 *
 * @see {@link t}
 * @example
 * import { useTranslation } from '@org/locales';
 *
 * const MyComponent = () => {
 *   const { t } = useTranslation();
 *
 *   return <div>{t('myTranslationKey')}</div>
 * }
 */
export const useTranslation = originalUseTranslation;
