/**
 * "Returns a function that returns the custom label for the row if it is configured."
 */
import type { ValueGetterParams } from 'ag-grid-community';

import type { dice } from '@org/query';

export const getCustomLabel = (
  masterConfiguration: dice.MasterConfiguration,
  params: ValueGetterParams,
) => {
  const accountId = params?.data?.accountId;

  const rowKey = params?.data?.rowName;
  const foundCustomLabel = masterConfiguration?.customLabels?.find(
    (customLabel) => customLabel.key === rowKey,
  );

  return foundCustomLabel?.name ?? accountId;
};
