import type { FC } from 'react';

import { BaseTable, Button } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { defaultColDef } from './useColumns';
import { useController } from './useController';

const View: FC<ViewProps> = (props) => {
  const { rowData, tableGridRef, handleExport, columnDefs, defaultExcelExportParams } = props;
  return (
    <>
      <div className="float-right inline-flex items-center gap-4">
        <Button
          onClick={handleExport}
          size="sm"
          variant="primary"
        >
          <span className="appkiticon icon-download-outline" />
          EXPORT
        </Button>
      </div>
      <div className="flex h-[calc(100vh-180px)] w-full flex-auto items-start justify-center overflow-auto p-0 pt-3">
        <BaseTable
          animateRows
          autoSizeStrategy={{
            type: 'fitCellContents',
          }}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          defaultExcelExportParams={defaultExcelExportParams}
          groupDefaultExpanded={2}
          groupDisplayType="custom"
          ref={tableGridRef}
          rowData={rowData}
          suppressAggFuncInHeader
        />
      </div>
    </>
  );
};

export const TradeTaxReportPage = bindControllers(View, [useController]);
TradeTaxReportPage.displayName = 'TradeTaxReportPage';
