import type { GetRowIdParams } from 'ag-grid-community';
import { type FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const getRowId = ({ data }: GetRowIdParams) => data?.id;

const View: FC<ControllerType> = (props) => {
  const {
    isServerSideGroupOpenByDefault,
    agGridRef,
    autoGroupColumnDef,
    defaultColDef,
    getServerSideGroupKey,
    isServerSideGroup,
    dataSource,
    columnDefs,
  } = props;

  return (
    <BaseEditTable
      animateRows
      autoGroupColumnDef={autoGroupColumnDef}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      getServerSideGroupKey={getServerSideGroupKey}
      groupSelectsChildren
      groupSelectsFiltered
      isServerSideGroup={isServerSideGroup}
      isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
      ref={agGridRef}
      rowClassRules={{
        'bg-highlighted font-bold': 'data?.isGroup',
        'bg-white': '!data?.isGroup',
      }}
      rowModelType="serverSide"
      rowSelection="multiple"
      serverSideDatasource={dataSource}
      treeData
    />
  );
};

export const FilteringImportFileTable = bindControllers(View, [useController]);
FilteringImportFileTable.displayName = 'FilteringImportFileTable';
