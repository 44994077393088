import type React from 'react';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { useDisclosure } from '@nextui-org/react';
import { useForm } from 'react-hook-form';

import { useDebounce } from '@org/hooks';
import { aggregated, griddy } from '@org/query';

export interface UseControllerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LinkComponent: React.ComponentType<any>;
  getLinkProps: (clientName: string) => Record<string, unknown>;
}

export const useController = ({ LinkComponent, getLinkProps }: UseControllerProps) => {
  const { clients, createClient, updateClient, deleteClient, shareClient } = aggregated.useClients(
    {},
  );

  const disclosureState = useDisclosure();

  const { control } = useForm({});

  const { data: users = [] } = griddy.useGetUsers({});

  const [searchedClients, updateClients] = useState<griddy.ClientDocument[] | undefined>(undefined);

  const handleSearchClients = useDebounce(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (!value) {
        updateClients(clients);
        return;
      }

      const foundClients = clients?.filter((client) => {
        const found = client.clientName?.search(new RegExp(value, 'i'));
        if (found !== -1) {
          return client;
        }
      });
      updateClients(foundClients ?? []);
    },
    200,
  );

  const clientsToBeShows = searchedClients ?? clients;

  const sortedClients =
    clientsToBeShows?.sort((a, b) => {
      if (a.clientName && b.clientName) {
        return a.clientName.localeCompare(b.clientName);
      }
      return 0;
    }) ?? [];

  return {
    LinkComponent,
    control,
    createClient,
    deleteClient,
    disclosureState,
    getLinkProps,
    handleSearchClients,
    shareClient,
    sortedClients,
    updateClient,
    users,
  };
};

export type ControllerType = ReturnType<typeof useController>;
