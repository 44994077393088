import type { SWRConfiguration } from 'swr';

import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import type { griddy } from '@org/query';

import { FILE_TYPE, getApiFileType } from '../../utils/data_configuration';
import { GRIDDY_ACCOUNTS_PLAN_MAP } from './endpoints';

export const useAccountsPlanMap = (config?: SWRConfiguration) => {
  const { yearId } = useParams<{ yearId: string }>();
  const fileType = getApiFileType(FILE_TYPE.ACCOUNTS_PLAN);

  return useSWR<griddy.GetAllAccountIdToAccountDescriptionMapResponse>(
    yearId ? GRIDDY_ACCOUNTS_PLAN_MAP({}, { fileType, yearId }) : null,
    {
      ...config,
      fallbackData: {},
    },
  );
};
