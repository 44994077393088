import type {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import {
  ArrowDownTrayIcon,
  ArrowTrendingUpIcon,
  ArrowUpIcon,
  DocumentChartBarIcon,
} from '@heroicons/react/24/outline';
import nameOf from 'easy-tsnameof';

import type { APIParams, erase } from '@org/query';
import type { IconListType } from '@org/ui';
import { t } from '@org/locales';
import { aggregated, dice } from '@org/query';

import { ImputedValueRenderer, OperatingAssetRenderer, WiberaIndexRowRenderer } from './components';

export const columns = nameOf<erase.Asset, never>();

const BORDER_CSS_CLASS = 'border-left-1px';

const emptySelectValue = { label: '    ', value: '' };

export interface UseColumnsProps {
  onRefresh: (asset: erase.Asset | erase.Asset[]) => unknown;
  enableAssetImputedUsageTimeConfig: boolean;
  enableWiberaDepreciationConfig: boolean;
  assetCategoryConfigs: erase.AssetCategoryConfigDTO[];
  updateAssetConfig: (categoryConfigs: erase.AssetCategoryConfigDTO[]) => Promise<void>;
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  updateAssets: (updatedRows: erase.Asset[]) => Promise<void>;
}

export const useColumns = ({
  onRefresh,
  enableAssetImputedUsageTimeConfig,
  enableWiberaDepreciationConfig,
  assetCategoryConfigs,
  updateAssetConfig,
  apiParams,
  updateAssets,
}: UseColumnsProps) => {
  const { yearId } = apiParams;
  const { costCentersNameMap } = aggregated.useCostCenters(apiParams);
  const costCenterOptions = useMemo(
    () => Array.from(costCentersNameMap.entries()).map(([value, label]) => ({ label, value })),
    [costCentersNameMap],
  );

  const { masterConfiguration } = aggregated.useMasterConfiguration(apiParams);

  const { data: indexFactors } = dice.useGetIndexFactorForYearAndField({
    pathParams: {
      configYear: masterConfiguration?.wiberaConfig?.configYear?.toString() ?? '',
      distinctByField: 'IndexRow',
    },
  });

  const iconList = useMemo(
    () => ({
      ERP_ASSET: {
        icon: ArrowDownTrayIcon,
      },
      FORECAST_ASSET: {
        icon: DocumentChartBarIcon,
      },
      FUTURE_ASSET: {
        icon: ArrowTrendingUpIcon,
      },
      UPLOADED_ASSET: {
        icon: ArrowUpIcon,
      },
    }),
    [],
  ) satisfies IconListType<erase.Asset, 'assetType'>;

  return useMemo(
    () =>
      [
        {
          colId: 'assetCategory',
          field: 'assetCategory',
          hide: true,
          rowGroup: true,
          valueGetter: ({ data: { assetCategory, assetCategoryDescriptionByLabel } = {} }) =>
            `${assetCategory} ${assetCategoryDescriptionByLabel ?? ''}`.trim(),
        },
        {
          aggFunc: 'booleanSum',
          autoHeight: true,
          cellRenderer: (params: ICellRendererParams) => (
            <OperatingAssetRenderer
              {...params}
              assetCategoryConfigs={assetCategoryConfigs}
              updateAssetConfig={updateAssetConfig}
              updateAssets={updateAssets}
              yearId={yearId}
            />
          ),
          colId: 'currentIsOperatingAsset',
          editable: false,
          field: 'currentIsOperatingAsset',
          flex: 0.75,
          headerName: t('main:manageMenu.manageAssetCostCenters.isOperating'),
          minWidth: 100,
        },
        {
          aggFunc: 'aggregateCostCenters',
          cellEditor: 'select',
          cellEditorParams: {
            options: [...costCenterOptions, emptySelectValue],
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          colId: 'currentBabCCShortName',
          editable: true,
          field: 'currentBabCCShortName',
          flex: 2,
          headerName: t('main:manageMenu.manageAssetCostCenters.assetMatchingColumn'),
          valueFormatter: ({ value }) => costCentersNameMap.get(value) ?? '',
          valueSetter: (params: ValueSetterParams) => {
            params.data.currentBabCCLongName = costCentersNameMap.get(params.newValue);
            params.data.currentBabCCShortName = params.newValue;
            params.data.assetCostCenterName = params.newValue;

            return true;
          },
        },
        {
          aggFunc: 'uniqueItems',
          cellRenderer: 'status',
          cellRendererParams: {
            onRefresh,
          },
          colId: 'status',
          headerName: t('main:manageMenu.manageAssetCostCenters.status'),
          tooltipValueGetter: () => '',
          valueGetter: ({ data }) => data,
          // AG Grid set too high width when autowidth is called.
          // This value allows to minimize column width and show all statuses.
          width: 120,
        },
        {
          aggFunc: 'booleanSum',
          autoHeight: true,
          cellRenderer: (props: ICellRendererParams) => (
            <ImputedValueRenderer
              {...props}
              assetCategoryConfigs={assetCategoryConfigs}
              path="imputedTimeOfUsage"
              updateAssetConfig={updateAssetConfig}
            />
          ),
          colId: 'imputedTimeOfUsage',
          editable: false,
          field: 'imputedTimeOfUsage',
          flex: 1,
          headerName: t('main:manageMenu.manageAssetCostCenters.imputedTimeOfUsage'),
          hide: !enableAssetImputedUsageTimeConfig,
          minWidth: 120,
          suppressKeyboardEvent: () => true,
        },
        {
          aggFunc: 'booleanSum',
          autoHeight: true,
          cellRenderer: (props: ICellRendererParams) => (
            <WiberaIndexRowRenderer
              {...props}
              indexFactors={indexFactors?.filter(Boolean)}
              masterConfiguration={masterConfiguration}
              path="imputedTimeOfUsage"
            />
          ),
          colId: 'wiberaIndexRow',

          editable: false,
          // @ts-expect-error -- not typed
          field: 'wiberaIndexRow',
          flex: 2,
          headerName: t('main:manageMenu.manageAssetCostCenters.wiberaIndexRows'),
          hide: !enableWiberaDepreciationConfig,
          minWidth: 120,
          suppressKeyboardEvent: () => true,
        },
        {
          children: [
            {
              aggFunc: 'uniqueItems',
              cellRenderer: 'icon',
              cellRendererParams: {
                fieldName: 'assetType',
                iconList,
              },
              colId: 'assetType',
              columnGroupShow: 'closed',
              editable: false,
              headerName: t('main:manageMenu.manageAssetCostCenters.assetSource'),
              valueGetter: ({ data }) => data,
              width: 95,
            },
            {
              colId: 'clientsCostCenterName',
              columnGroupShow: 'open',
              field: 'clientsCostCenterName',
              flex: 1,
              headerName: t('main:manageMenu.manageAssetCostCenters.clientCostCenter'),
            },
            {
              colId: 'clientCCNameByLabel',
              columnGroupShow: 'open',
              field: 'clientCCNameByLabel',
              flex: 1,
              headerName: t('main:manageMenu.manageAssetCostCenters.clientCostCenterDesc'),
            },
          ],
          groupId: 'infoMappings',
          flex: 1,
          headerClass: BORDER_CSS_CLASS,
          headerName: t('main:manageMenu.manageAssetCostCenters.info'),
          openByDefault: false,
        },
      ] satisfies (ColDef<erase.Asset> | ColGroupDef<erase.Asset>)[],
    [
      costCenterOptions,
      onRefresh,
      enableAssetImputedUsageTimeConfig,
      enableWiberaDepreciationConfig,
      iconList,
      assetCategoryConfigs,
      updateAssetConfig,
      updateAssets,
      yearId,
      costCentersNameMap,
      indexFactors,
      masterConfiguration,
    ],
  );
};
