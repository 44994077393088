import type { ChangeEvent, Ref } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import classnames from 'classnames';

import styles from './CheckboxInput.module.css';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type CheckboxInputProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
  disableStopPropagation?: boolean;
} & Omit<OuterProps, 'onChange'>;

export const CheckboxInput = forwardRef(
  (props: CheckboxInputProps, forwardedRef: Ref<HTMLInputElement>) => {
    const {
      checked = false,
      disabled = false,
      indeterminate = false,
      onChange,
      className,
      children,
      disableStopPropagation = false,
      ...otherProps
    } = props;
    const checkboxRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(forwardedRef, () => checkboxRef.current!);

    useEffect(() => {
      if (checkboxRef?.current) {
        if (!checked) {
          checkboxRef.current.indeterminate = indeterminate;
        }

        if (checked) {
          checkboxRef.current.indeterminate = false;
        }
      }
    }, [checkboxRef, checked, indeterminate]);

    return (
      <div
        className={classnames(
          styles.CheckboxListItem,
          'flex items-center',
          { 'cursor-pointer': !disabled },
          className,
        )}
        data-testid="CheckboxInput"
        {...otherProps}
      >
        {/* TODO: Fix issus with accessibility */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- need fix */}
        <div
          className="flex items-center"
          onClick={(e) => {
            if (disableStopPropagation) {
              return;
            }
            e.stopPropagation();
          }}
        >
          <input
            checked={checked}
            className="mr-2 cursor-pointer accent-orange-default"
            disabled={disabled}
            onChange={onChange}
            ref={checkboxRef}
            type="checkbox"
          />
        </div>
        <div className={classnames({ 'text-text-disabled': disabled })}>{children}</div>
      </div>
    );
  },
);
