import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useTranslation } from '@org/locales';

interface UseColumnsProps {}

export const useColumns = ({}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      [
        {
          colId: 'accountId',
          field: 'accountId',
          flex: 2,
          headerName: t('main:manageMenu.accountRange.tableColumns.accountId'),
        },
        {
          colId: 'accountDescription',
          field: 'accountDescription',
          flex: 1,
          headerName: t('main:manageMenu.accountRange.tableColumns.accountDescription'),
        },
      ] satisfies (ColDef | ColGroupDef)[],
    [t],
  );
};
