import { useCallback, useState } from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Accordion, AccordionItem } from '@nextui-org/react';
import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { Input, showNotification, Upload, VirtualizedList } from '@org/ui';
import { parseCSV } from '@org/utils';

import type { ResponseErrorType } from './utils';
import { createValidRow, getLocalizedErrors } from './utils';

const filterCsv = (fileRows: Record<string, unknown>[]) =>
  fileRows.map((row) => createValidRow(row));

interface IndexFactorsUploadProps {
  onClose?: () => void;
}

export const IndexFactorsUpload = ({ onClose }: IndexFactorsUploadProps) => {
  const { t } = useTranslation();
  const [responseErrorItems, setResponseErrorItems] = useState<string[]>([]);
  const { importWiberaIndices, isLoading } = aggregated.useIndexFactors();

  const {
    control,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const handleFileUpload = useCallback(
    async (files: File[]) => {
      if (files.length === 0) {
        return;
      }
      const year = getValues('year');
      if (!year) {
        setError('year', {});
        return;
      }

      const file = files.at(0)!;
      const parsedFile = await parseCSV(file);
      const filtered = filterCsv(parsedFile as Record<string, unknown>[]);

      const sendData = {
        indexFactors: filtered,
        year,
      };

      try {
        await importWiberaIndices(sendData);
        showNotification('success', t('main:adminMenu.indexFactors.alerts.success.upload'));
        onClose?.();
      } catch (rError) {
        const error = rError as { errors: ResponseErrorType[] };
        const responseErrors = error?.errors ?? [];
        showNotification(
          'error',
          t('main:adminMenu.indexFactors.alerts.error.validationError', {
            count: responseErrors.length,
          }),
        );
        setResponseErrorItems(getLocalizedErrors(responseErrors, t));
      }
    },
    [getValues, importWiberaIndices, onClose, setError, t],
  );

  const ErrorLine = useCallback(
    ({ index = 0 }) => {
      const item = responseErrorItems[index];
      return (
        <li className="flex items-center break-words text-left text-xs">
          <span className="pr-2">
            <ExclamationTriangleIcon className="h-4 w-4 text-red-400" />{' '}
          </span>
          <span>{item}</span>
        </li>
      );
    },
    [responseErrorItems],
  );

  return (
    <div>
      <Controller
        control={control}
        name="year"
        render={({ field }) => (
          <Input
            {...field}
            className="pb-4"
            errorMessage={
              !!errors.year ? t('main:adminMenu.indexFactors.alerts.warning.invalidYear') : null
            }
            isDisabled={isLoading}
            isInvalid={!!errors.year}
            isRequired
            label={t('main:adminMenu.indexFactors.placeholderInputYear')}
            labelPlacement="outside"
            onChange={(event) => {
              const {
                target: { value: targetValue },
              } = event;
              field.onChange(targetValue);
            }}
            radius="none"
            type="number"
            value={field.value ?? ''}
            variant="default"
          />
        )}
        rules={{ max: 2999, min: 1900, required: true }}
      />

      <Upload
        acceptedFileTypes={['text/csv']}
        isBtnDisabled={isLoading || !!errors.year}
        isUploading={isLoading}
        onUpload={handleFileUpload}
      >
        <div className="flex flex-col">
          <span className="pt-4 text-orange-default">
            {t('main:adminMenu.indexFactors.upload.hint1')}
          </span>
          <span className="pt-4 text-gray-500 underline">
            {t('main:adminMenu.indexFactors.upload.hint2')}
          </span>
        </div>
      </Upload>

      {responseErrorItems.length > 0 && (
        <Accordion
          className="mt-4"
          defaultExpandedKeys={['1']}
          isCompact
          itemClasses={{
            base: 'py-0 w-full',
            content: 'text-small px-2',
            indicator: 'text-medium',
            title: 'font-normal text-medium',
            trigger:
              'px-2 py-0 bg-default-50 data-[hover=true]:bg-default-100 h-14 flex items-center border-b-1 border-solid border-slate-300',
          }}
          variant="bordered"
        >
          <AccordionItem
            key="1"
            title={t('main:adminMenu.indexFactors.alerts.error.validationError', {
              count: responseErrorItems.length,
            })}
          >
            <VirtualizedList
              Component={ErrorLine}
              height={120}
              itemCount={responseErrorItems.length}
            />
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};
