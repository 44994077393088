import type { FC } from 'react';

import { BaseTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { getRowClasses } from '../ReportTable/helpers';
import { useController } from './useController';

const View: FC<ViewProps> = ({
  columnDefs,
  defaultColDef,
  agGridRef,
  agGridProps,
  defaultExcelExportParams,
}) => (
  <BaseTable
    autoSizeStrategy={{
      colIds: ['rowDescription'],
      type: 'fitCellContents',
    }}
    columnDefs={columnDefs}
    defaultColDef={defaultColDef}
    defaultExcelExportParams={defaultExcelExportParams}
    getRowClass={getRowClasses()}
    groupDefaultExpanded={2}
    groupDisplayType="custom"
    ref={agGridRef}
    suppressAggFuncInHeader
    {...agGridProps}
  />
);

export const InterestRateReportTable = bindControllers(View, [useController]);
InterestRateReportTable.displayName = 'InterestRateReportTable';
