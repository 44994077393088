import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef } from 'react';

import type { griddy } from '@org/query';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';

import type { BaseControllerType } from '../types';
import { useColumns } from './useColumns';

interface UseControllerProps extends BaseControllerType {}

export const useController = (props: UseControllerProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(props);

  const { customLabels = [] } = masterConfiguration ?? {};

  const removeLabel = useCallback(
    async (index: number) => {
      customLabels.splice(index, 1);
      const { masterConfiguration: { customLabels: newCustomLabels = [] } = {} } =
        await updateMasterConfiguration({
          ...masterConfiguration,
          customLabels,
        });
      gridRef.current?.api.updateGridOptions({
        rowData: newCustomLabels,
      });
    },
    [customLabels, masterConfiguration, updateMasterConfiguration],
  );

  const updateLabels = useCallback(
    async (newCustomLabels: griddy.CustomLabel[]) => {
      await updateMasterConfiguration({
        ...masterConfiguration,
        customLabels: newCustomLabels,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const { modal, openModal } = useDeleteRowConfirmModal<number>({
    onConfirm: removeLabel,
  });

  const colDef = useColumns({ apiParams: props, gridRef, openModal });

  return {
    colDef,
    gridRef,
    modal,
    rowData: customLabels,
    updateLabels,
  };
};

export type ViewProps = ReturnType<typeof useController>;
