import { useTranslation } from '@org/locales';

import type { APIParams } from '../../..';
import type { ReportsResponse } from '../../rave';
import { useGetByFileIdsFromGridFS } from '../../griddy';
import { useMasterConfiguration } from '../useMasterConfiguration';
import { useMasterConfigurationByYearCalculation } from '../useMasterConfigurationByYearCalculation';
import { groupCostCenters } from './costCenters';
import { generateInterestTableReport } from './interestTableReport';
import { generateNonOperatingInterestRateReport } from './nonOperatingInterestRateReport';
import { generateOperatingInterestRateReport } from './operatingInterestRateReport';
import { generateTradeTaxTableReport } from './tradeTaxTableReport';
import { useRecalculateReport } from './useRecalculateReport';

export type InterestTableReportData = ReturnType<typeof generateInterestTableReport>;
export type TradeTaxReportData = ReturnType<typeof generateTradeTaxTableReport>;
export type NonOperatingInterestTradeReportData = ReturnType<
  typeof generateNonOperatingInterestRateReport
>;
export type OperatingInterestTradeReportData = ReturnType<
  typeof generateOperatingInterestRateReport
>;

export interface ExtendedReportObjectsResponse
  extends ReportsResponse,
    ReturnType<typeof groupCostCenters> {
  interestTableReportData: ReturnType<typeof generateInterestTableReport>;
  tradeTaxTableReportData: ReturnType<typeof generateTradeTaxTableReport>;
  nonOperatingInterestTradeReport: ReturnType<typeof generateNonOperatingInterestRateReport>;
  operatingInterestTradeReport: ReturnType<typeof generateOperatingInterestRateReport>;
}

export interface UseReportParams
  extends APIParams<
    'publicFacilityId' | 'yearId' | 'clientId' | 'type' | 'masterConfigurationId'
  > {}

export const useReport = <TData = ExtendedReportObjectsResponse>(params: UseReportParams) => {
  const { t } = useTranslation();
  const { masterConfiguration } = useMasterConfiguration(params);
  const { selectedEntity } = useMasterConfigurationByYearCalculation({
    masterConfigurationId: params.masterConfigurationId,
    yearCalculationId: params.yearId,
  });
  const reportId = selectedEntity?.reportId;

  const { recalculateReport, isRecalculating } = useRecalculateReport(params);

  const { data: report, ...responseObject } = useGetByFileIdsFromGridFS<TData>(
    {
      pathParams: {
        ids: [reportId!],
      },
    },
    {
      enabled: Boolean(reportId),
      select(data) {
        const reportObject = data?.[0] as ReportsResponse;
        const groupedCostCenters = groupCostCenters(reportObject.costCenters);
        const returnObject: ExtendedReportObjectsResponse = {
          ...reportObject,
          ...groupedCostCenters,
          interestTableReportData: generateInterestTableReport({
            masterConfiguration,
            reportObject,
            t,
            ...groupedCostCenters,
          }),
          nonOperatingInterestTradeReport: generateNonOperatingInterestRateReport({
            reportObject,
            t,
            ...groupedCostCenters,
          }),
          operatingInterestTradeReport: generateOperatingInterestRateReport({
            reportObject,
            t,
            ...groupedCostCenters,
          }),
          tradeTaxTableReportData: generateTradeTaxTableReport({
            ...groupedCostCenters,
            reportObject,
          }),
        };
        return returnObject as TData;
      },
    },
  );

  return { ...responseObject, isRecalculating, recalculateReport, report };
};
