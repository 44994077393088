import type { TFunction } from '@org/locales';
import { getFirstDayOfYear, getLastDayOfYear } from '@org/utils';

import type { CostCenter, MasterConfiguration } from '../../dice';
import type {
  ImputedWorkingCapitalConfig,
  InterestRates,
  InterestReportEntry,
  InterestTableReport,
  ReportsResponse,
} from '../../rave';
import { getGroupTotalsOfRow } from './groupTotals';

export interface InterestReportTableEntry {
  valueInEuro?: number | null;
  rowDescription?: string;
  rowId?: string;
  date?: string | number | null;
  withdrawCapitalName?: string;
  totalAmount?: number;
  costCenterValueMap?: Record<string, number>;
}

type InterestTableReportFixed = InterestTableReport & Record<string, InterestReportEntry>;

const capitalStartRows = [
  'RBV_CAPITAL_OPERATING_ASSET_BEGIN',
  'RBV_CAPITAL_NON_OPERATING_ASSET_BEGIN',
  'RBV_CAPITAL_LEDGER_ACCOUNT_BEGIN',
  'RBV_CAPITAL_IMPUTED_BEGIN',
] as const;

const capitalEndRows = [
  'RBV_CAPITAL_OPERATING_ASSET_END',
  'RBV_CAPITAL_NON_OPERATING_ASSET_END',
  'RBV_CAPITAL_LEDGER_ACCOUNT_END',
  'RBV_CAPITAL_IMPUTED_END',
] as const;

const capitalAverageRows = [
  'RBV_CAPITAL_OPERATING_ASSET_AVG',
  'RBV_CAPITAL_NON_OPERATING_ASSET_AVG',
  'RBV_CAPITAL_LEDGER_ACCOUNT_AVG',
  'RBV_CAPITAL_IMPUTED_AVG',
] as const;

const dateParsers = {
  RBV_CAPITAL_IMPUTED_BEGIN: getFirstDayOfYear,
  RBV_CAPITAL_IMPUTED_END: getLastDayOfYear,
  RBV_CAPITAL_LEDGER_ACCOUNT_BEGIN: getFirstDayOfYear,
  RBV_CAPITAL_LEDGER_ACCOUNT_END: getLastDayOfYear,
  _default: (year?: string | null) => year,
} as const;

const getReportRowDate = (rowKey: string) =>
  dateParsers[rowKey as keyof typeof dateParsers] ?? dateParsers._default;

export const createImputedWithdrawCapitalRowsFromResponse = (
  imputedWithDrawCapitalRowsFromResponse:
    | InterestTableReport['interestReportImputedWicapEntryMap']
    | undefined,
  interestRatesConfig: InterestRates,
  t: TFunction,
): InterestReportTableEntry[] => {
  const rowsToExtract = hideSpecificRowsRelatedToInterestRatesConfig(
    ['RBV_CAPITAL_IMPUTED_BEGIN', 'RBV_CAPITAL_IMPUTED_END', 'RBV_CAPITAL_IMPUTED_AVG'] as const,
    interestRatesConfig,
  );

  if (imputedWithDrawCapitalRowsFromResponse) {
    return Object.entries(imputedWithDrawCapitalRowsFromResponse).flatMap(
      ([groupName, withdrawCapitalValues]) => [
        { rowDescription: groupName },
        ...rowsToExtract.map((rowKey) => {
          const rowDescription = t(`main:reportPanels.interestTable.tableRowsData.${rowKey}`);
          return {
            date: getReportRowDate(rowKey)(withdrawCapitalValues[rowKey]?.reportingDate ?? ''),
            rowDescription,
            rowId: rowKey,
            // FIXME: totalAmount is not present in the type
            totalAmount: (withdrawCapitalValues[rowKey] as { totalAmount?: number })?.totalAmount,
            valueInEuro: withdrawCapitalValues[rowKey]?.imputedInterestAmount,
            ...withdrawCapitalValues[rowKey]?.ccShortNameToValueMap,
          };
        }),
      ],
    );
  }
  return [];
};

const createWithdrawCapitalRowsFromResponse = (
  withDrawCapitalRowsFromResponse: InterestTableReport['interestReportWicapEntryMap'],
  interestRatesConfig: InterestRates,
  t: TFunction,
) => {
  const withdrawCapitalRows: InterestReportTableEntry[] = [];
  const rowsToExtract = hideSpecificRowsRelatedToInterestRatesConfig(
    [
      'RBV_CAPITAL_LEDGER_ACCOUNT_BEGIN',
      'RBV_CAPITAL_LEDGER_ACCOUNT_END',
      'RBV_CAPITAL_LEDGER_ACCOUNT_AVG',
    ] as const,
    interestRatesConfig,
  );
  if (withDrawCapitalRowsFromResponse) {
    Object.entries(withDrawCapitalRowsFromResponse).forEach(
      ([withdrawCapitalName, withdrawCapitalValues]) => {
        withdrawCapitalRows.push({ rowDescription: withdrawCapitalName });
        rowsToExtract.forEach((key) => {
          const description = t(`main:reportPanels.interestTable.tableRowsData.${key}`);
          const { reportingDate, totalAmount, imputedInterestAmount, ccShortNameToValueMap } =
            withdrawCapitalValues[key] as InterestReportEntry & { totalAmount?: number };

          withdrawCapitalRows.push({
            date: getReportRowDate(key)(reportingDate),
            rowDescription: description,
            rowId: key,
            totalAmount,
            valueInEuro: imputedInterestAmount,
            withdrawCapitalName,
            ...ccShortNameToValueMap,
          });
        });
      },
    );
  }
  return withdrawCapitalRows;
};

export const hideSpecificRowsRelatedToInterestRatesConfig = <T extends readonly string[]>(
  rowsToExtract: T,
  interestRatesConfig: InterestRates,
): T => {
  const rowsToExtractSet = new Set(rowsToExtract);
  const rowsToDelete: string[] = [];

  switch (interestRatesConfig?.interestBearingCapitalBased) {
    case 'START_OF_YEAR': {
      rowsToDelete.push(...capitalEndRows, ...capitalAverageRows);
      break;
    }
    case 'END_OF_YEAR': {
      rowsToDelete.push(...capitalStartRows, ...capitalAverageRows);
      break;
    }
  }

  rowsToDelete.forEach((row) => rowsToExtractSet.delete(row));
  return Array.from(rowsToExtractSet) as unknown as T;
};

const createRowsFromAPIResponse = ({
  interestTableEntries,
  interestRatesConfig,
  imputedWorkingCapitalConfig,
  t,
}: {
  interestTableEntries?: InterestTableReportFixed;
  interestRatesConfig: InterestRates;
  imputedWorkingCapitalConfig?: ImputedWorkingCapitalConfig;
  t: TFunction;
}) => {
  const showWorkingCapitalRows = imputedWorkingCapitalConfig?.workingCapitalEnabled ?? false;

  const convertedRows: InterestReportTableEntry[] = [];
  const rowsToExtract = hideSpecificRowsRelatedToInterestRatesConfig(
    [
      'ROW_HEADER_1',
      'RBV_CAPITAL_OPERATING_ASSET_BEGIN',
      'RBV_CAPITAL_OPERATING_ASSET_END',
      'RBV_CAPITAL_OPERATING_ASSET_AVG',
      'ROW_HEADER_2',
      'RBV_CAPITAL_NON_OPERATING_ASSET_BEGIN',
      'RBV_CAPITAL_NON_OPERATING_ASSET_END',
      'RBV_CAPITAL_NON_OPERATING_ASSET_AVG',
      ...(showWorkingCapitalRows ? ['ROW_HEADER_3', 'WORKING_CAPITAL'] : []),
    ] as const,
    interestRatesConfig,
  );

  rowsToExtract.forEach((rowKey) => {
    const rowName = t(`main:reportPanels.interestTable.tableRowsData.${rowKey}`);
    const { ccShortNameToValueMap, reportingDate, imputedInterestAmount } =
      interestTableEntries?.[rowKey] ?? {};

    convertedRows.push({
      date: reportingDate,
      rowDescription: rowName,
      rowId: rowKey,
      valueInEuro: imputedInterestAmount,
      ...ccShortNameToValueMap,
    });
  });

  return convertedRows;
};

const createTableAggregationRowsFromResponse = (
  interestTableEntries: InterestTableReportFixed | undefined,
  t: TFunction,
) => {
  const convertedRows: InterestReportTableEntry[] = [];
  const rowsToExtract = ['WICAP_TOTAL', 'INTEREST_BEARING_CAPITAL', 'IMPUTED_INTEREST'] as const;

  rowsToExtract.forEach((key) => {
    const rowName = t(`main:reportPanels.interestTable.tableRowsData.${key}`);
    const { reportingDate, imputedInterestAmount, ccShortNameToValueMap } =
      interestTableEntries?.[key] ?? {};

    convertedRows.push({
      date: reportingDate,
      rowDescription: rowName,
      rowId: key,
      valueInEuro: imputedInterestAmount,
      ...ccShortNameToValueMap,
    });
  });
  return convertedRows;
};

export const convertInterestTableAPIResponseToTableRows = (
  interestTableReport: InterestTableReportFixed,
  masterConfig: MasterConfiguration,
  t: TFunction,
): InterestReportTableEntry[] => {
  const { interestRates, imputedWorkingCapitalConfig } = masterConfig;
  return [
    createRowsFromAPIResponse({
      imputedWorkingCapitalConfig,
      interestRatesConfig: interestRates,
      interestTableEntries: interestTableReport,
      t,
    }),
    createWithdrawCapitalRowsFromResponse(
      interestTableReport?.interestReportWicapEntryMap,
      interestRates,
      t,
    ),
    createImputedWithdrawCapitalRowsFromResponse(
      interestTableReport?.interestReportImputedWicapEntryMap,
      interestRates,
      t,
    ),
    createTableAggregationRowsFromResponse(interestTableReport, t),
  ].flat();
};

export const addGroupTotalsToFlattenedData = (
  rowData: InterestReportTableEntry[],
  indirectCCGroups: [string, CostCenter[]][],
  primaryCCGroups: [string, CostCenter[]][],
) => {
  if (!rowData) {
    return rowData;
  }
  return rowData.map((row, rowIndex) => {
    const totals = getGroupTotalsOfRow({ indirectCCGroups, primaryCCGroups, row });
    return { ...row, ...totals, rowIndex };
  });
};

interface GenerateInterestTableReport {
  masterConfiguration?: MasterConfiguration;
  reportObject: ReportsResponse;
  t: TFunction;
  primaryCostCentersGrouped: [string, CostCenter[]][];
  indirectCostCentersGrouped: [string, CostCenter[]][];
}

export function generateInterestTableReport({
  masterConfiguration,
  reportObject,
  t,
  primaryCostCentersGrouped,
  indirectCostCentersGrouped,
}: GenerateInterestTableReport) {
  return addGroupTotalsToFlattenedData(
    convertInterestTableAPIResponseToTableRows(
      reportObject.interestTableReport as InterestTableReportFixed,
      masterConfiguration!,
      t,
    ),
    indirectCostCentersGrouped,
    primaryCostCentersGrouped,
  );
}
