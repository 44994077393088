import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { LoaderWrapper } from '@org/ui';

import App from './App';
import Logout from './components/Logout';
import config from './config';
import { DevTools } from './DevTools';
import LandingPage from './pages/Authentication/LandingPage';
import Register from './pages/Authentication/Registration';
import { sendGet, sendPost } from './utils/apiCalls';
import { _getClientID, _getLoginUrl, _getRedirectUri } from './utils/env-url';
import { useSetPersistentTimeout } from './utils/PersistentTimer/PersistentTimer';

const performProductIAMLogin = () => {
  const env = process.env.REACT_APP_ENV;
  if (env !== config.LOCAL && env !== config.DEVR2) {
    if (window.location.pathname !== '/') {
      localStorage.setItem('initialPath', window.location.pathname);
    }
    const url = _getLoginUrl();

    window.location.href = url;
  }
};

const Auth = () => {
  const navigate = useNavigate();
  const [redirectTo, setRedirectTo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getAccesssToken = async (code: string) => {
    try {
      const response = await sendPost('/productIAM/access_token', {
        client_id: _getClientID(),
        code,
        grant_type: 'authorization_code',
        redirect_uri: _getRedirectUri(),
      });
      sessionStorage.setItem('pIAM_authorization_token', response.access_token);
      sessionStorage.setItem('pIAM_refreshtoken_token', response.refresh_token);
      sessionStorage.setItem('pIAM_id_token', response.id_token);
      sessionStorage.setItem('initCall', 'false');
      sessionStorage.setItem('time', Date.now().toString());
      const data = await sendGet(`${config.PROXY_SAFE_PATH}${config.PROXY_CURRENT_USER_DETAILS}`);
      if (data) {
        sessionStorage.setItem('UserDts', JSON.stringify(data));
        await sendPost(`${config.PROXY_GRIDDY_PATH}${config.GRIDDY_USERS_PATH}`, {
          ...data,
          email: data.username,
        });
      }

      startRefreshTimer();
      setIsLoading(false);
      setRedirectTo(true);
    } catch (error) {
      console.error('Error:', error);
      sessionStorage.clear();
      sessionStorage.setItem('initCall', 'false');
      navigate('/');
    }
  };

  const convertSuccessfulLogin = useCallback(() => {
    const code = window.location.href.split('?')[1].split('&')[0].split('code=')[1];
    sessionStorage.setItem('pIAM', code);
    sessionStorage.setItem('initCall', 'false');
    let initialPath = localStorage.getItem('initialPath');
    setIsLoading(true);
    getAccesssToken(code);
    if (_.isEmpty(initialPath)) {
      initialPath = '';
    }
    navigate('/');
  }, [getAccesssToken, navigate]);

  const convertSuccessfulRegistration = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    navigate(`/register/${params.get('registrationToken')}/${params.get('email')}`);
  }, [navigate]);

  const checkAuthenticationWithPIAM = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      convertSuccessfulLogin();
      return false;
    } else {
      const productIAM = sessionStorage.getItem('pIAM');
      const pIAMToken = sessionStorage.getItem('pIAM_authorization_token');
      if (productIAM == null && pIAMToken == null) {
        return false;
      }

      sessionStorage.setItem('initCall', 'true');
    }
    return true;
  }, [convertSuccessfulLogin]);

  useEffect(() => {
    if (window.location.href.includes('code=')) {
      convertSuccessfulLogin();
    }
    if (window.location.href.includes('registrationToken=')) {
      convertSuccessfulRegistration();
    }
    const env = process.env.REACT_APP_ENV;
    if (env !== config.LOCAL && env !== config.DEVR2) {
      if (window.location.pathname !== '/logout') {
        const loggedIn = checkAuthenticationWithPIAM();
        if (loggedIn) {
          const pIAMToken = sessionStorage.getItem('pIAM_authorization_token');
          if (pIAMToken !== null && pIAMToken !== undefined) {
            setIsLoading(false);
            setRedirectTo(true);
          }
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setRedirectTo(false);
        navigate('/logout');
      }
    } else {
      setIsLoading(false);
      setRedirectTo(true);
    }
  }, [
    checkAuthenticationWithPIAM,
    convertSuccessfulLogin,
    convertSuccessfulRegistration,
    navigate,
  ]);

  const getRefreshToken = async (refreshToken: string) => {
    try {
      const response = await sendPost('/productIAM/refresh_access_token', {
        client_id: _getClientID(),
        grant_type: 'refresh_token',
        redirect_uri: _getRedirectUri(),
        refresh_token: refreshToken,
      });
      sessionStorage.setItem('pIAM_authorization_token', response.access_token);
      sessionStorage.setItem('pIAM_id_token', response.id_token);
      sessionStorage.setItem('refresh_called', 'Yes');
      resetTokenExpire();
    } catch (error) {
      console.error('Error:', error);
      sessionStorage.clear();
      sessionStorage.setItem('initCall', 'false');
      navigate('/');
    }
  };

  const resetToken = () => {
    const refreshToken = sessionStorage.getItem('pIAM_refreshtoken_token');
    if (refreshToken !== null) {
      getRefreshToken(refreshToken);
    }
  };

  const resetTokenExpire = () => {
    startSessionClosingTimer();
  };

  const [startRefreshTimer] = useSetPersistentTimeout('refreshToken', resetToken, 1000 * 60 * 50);
  const [startSessionClosingTimer] = useSetPersistentTimeout(
    'sessionClosing',
    () => {
      navigate('/logout');
    },
    6_000_000, // product iam will automatically log out after 100 minutes
  );

  if (redirectTo) {
    return (
      <>
        <App />
        <DevTools />
      </>
    );
  }

  return (
    <>
      <LoaderWrapper
        loading={isLoading}
        style={{ margin: 0 }}
      >
        <Routes>
          <Route
            Component={Logout}
            path="/logout"
          />
          <Route
            Component={Register}
            path="/register/:regToken/:regEmail"
          />
          <Route
            element={<LandingPage userLogin={performProductIAMLogin} />}
            path="/"
          />
        </Routes>
      </LoaderWrapper>
      <ToastContainer />
    </>
  );
};

export default Auth;
