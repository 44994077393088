import type { CellClassParams, IRowNode } from 'ag-grid-community';

import { findRoot } from '@org/utils';

const getBackgroudClass = (node: IRowNode): string => {
  const { childIndex: rootChildNodeIndex } = findRoot(node);
  return rootChildNodeIndex % 2 ? 'gray-lightest-background' : 'white-background';
};

const getBoldFontClass = (rowsToBold: string[], params: CellClassParams): string => {
  const { data, node } = params;
  const label = data?.costTypeDescription ?? node.groupData?.costTypeDescription;
  return label && rowsToBold.includes(label) ? 'font-bold' : '';
};

/**
 * Function defines classes for a cell in a table.
 * @returns array of strings (class names)
 */
export const getCellClasses = (rowsToBold: string[], params: CellClassParams): string[] => {
  const { node } = params;
  return [getBackgroudClass(node), getBoldFontClass(rowsToBold, params)];
};
