import type { ExcelExportParams } from 'ag-grid-community';

import type { ExcelReportType } from '@org/models';

export const getDefaultExcelExportParams = (
  fileName: string,
  reportType: ExcelReportType,
): ExcelExportParams => ({
  fileName,
  processRowGroupCallback: (params) => params.node.key ?? '',
  sheetName: reportType,
});
