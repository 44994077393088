import type { ColDef, GetRowIdFunc } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef } from 'react';

import type { dice } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

const defaultColDef: ColDef<dice.TransactionModification> = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
  sortable: false,
};

const getRowId: GetRowIdFunc<dice.TransactionModification> = ({ data }) => data.index!;

interface UseControllerProps extends BaseControllerType {}

export const useController = (props: UseControllerProps) => {
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(props);
  const agGridRef = useRef<AgGridReact>(null);

  const deleteBabChangelogEntry = useCallback(
    async (index: number) => {
      const newTransactionModification =
        masterConfiguration?.transactionModifications?.toSpliced(index, 1) ?? [];
      await updateMasterConfiguration({
        ...masterConfiguration,
        transactionModifications: newTransactionModification,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const deleteBabChangelogEntries = useCallback(
    async (indexes: number[]) => {
      const newTransactionModification = masterConfiguration?.transactionModifications?.filter(
        (_, index) => !indexes.includes(index),
      );
      await updateMasterConfiguration({
        ...masterConfiguration,
        transactionModifications: newTransactionModification,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const { modal, openModal } = useDeleteRowConfirmModal<number | number[]>({
    onConfirm: (index) => {
      if (Array.isArray(index)) {
        deleteBabChangelogEntries(index);
        return;
      }
      deleteBabChangelogEntry(index);
    },
  });

  const columnDefs = useColumns({
    ...props,
    agGridRef,
    openModal,
  });

  const agGridProps = useAgGridData({
    agGridRef,
    data: masterConfiguration?.transactionModifications,
  });

  return {
    agGridProps,
    agGridRef,
    columnDefs,
    defaultColDef,
    getRowId,
    modal,
  };
};

export type ViewProps = ReturnType<typeof useController>;
