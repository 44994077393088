import type { UseDisclosureProps } from '@nextui-org/react';
import type { FC } from 'react';
import { useState } from 'react';

import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';

import type { griddy } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { Button, Modal, ModalContent, SearchField } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import {
  AddNewClientModalContent,
  DeleteClientModalContent,
  RenameClientModalContent,
  ShareClientModalContent,
} from './components';
import { useController } from './useController';

interface ClientsOverviewProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

type ModalContentKeys = 'ADD_NEW_CLIENT' | 'RENAME_CLIENT' | 'DELETE_CLIENT' | 'SHARE_CLIENT';

export interface ClientModalContentProps {
  updateClient: (client: griddy.ClientDocument) => Promise<griddy.SaveClientsResponse>;
  createClient: (clientName: string) => Promise<griddy.SaveClientsResponse>;
  deleteClient: (clientId: number) => Promise<void>;
  shareClient: (fromClientId: number, toClients: string[]) => Promise<undefined>;
  disclosureState: UseDisclosureProps;
  selectedClient: griddy.ClientDocument;
  sortedClients?: griddy.ClientDocument[];
  users: griddy.User[];
}

const View: FC<ClientsOverviewProps> = ({
  LinkComponent,
  getLinkProps,
  sortedClients,
  disclosureState,
  createClient,
  shareClient,
  updateClient,
  deleteClient,
  handleSearchClients,
  control,
  users,
}) => {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<griddy.ClientDocument>({});
  const [activeModalKey, setActiveModalKey] = useState<ModalContentKeys>('ADD_NEW_CLIENT');

  const { onOpen, onOpenChange, isOpen } = disclosureState;

  const MODAL_CONTENT_LIST: Record<ModalContentKeys, React.FC<ClientModalContentProps>> = {
    ADD_NEW_CLIENT: AddNewClientModalContent,
    DELETE_CLIENT: DeleteClientModalContent,
    RENAME_CLIENT: RenameClientModalContent,
    SHARE_CLIENT: ShareClientModalContent,
  };

  const SelectedModalContent = MODAL_CONTENT_LIST[activeModalKey];

  const clientModalContentProps = {
    createClient,
    deleteClient,
    disclosureState,
    selectedClient,
    shareClient,
    sortedClients,
    updateClient,
    users,
  };

  const handleOnDropdownItemClick = (key: ModalContentKeys, client: griddy.ClientDocument) => {
    onOpen();
    setActiveModalKey(key);
    setSelectedClient(client);
  };

  return (
    <>
      <div className="h-full">
        <div className="mx-auto my-4 max-w-7xl px-4">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8 lg:grid-cols-4">
            <div className="mb-4">
              <Button
                className="flex cursor-pointer items-center !rounded-md"
                onClick={() => {
                  onOpen();
                  setActiveModalKey('ADD_NEW_CLIENT');
                }}
                size="md"
                variant="secondary"
              >
                <PlusIcon className="h-4 w-4" />
                <div>{t('main:clients.clientsOverviewPanel.header')}</div>
              </Button>
            </div>

            <div className="col-span-1 lg:col-span-2">
              <SearchField
                control={control}
                name="search"
                onChange={handleSearchClients}
              />
            </div>
          </div>
        </div>
        <div className="scrollable-container mx-auto h-[calc(100vh-240px)] max-w-7xl">
          <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-4">
            {sortedClients?.map((client) => {
              const { clientName = '', id } = client;
              return (
                <Card
                  as={LinkComponent}
                  className="h-[100px] rounded-md p-4 hover:bg-actions-hover hover:text-black hover:no-underline md:max-w-[340px]"
                  key={clientName}
                  {...getLinkProps(`${id}`)}
                >
                  <div className="flex justify-between space-x-4">
                    <div className="break-words">{clientName}</div>
                    <div className="">
                      <Dropdown>
                        <DropdownTrigger className="h-8 w-8 rounded p-1 hover:bg-gray-200">
                          <EllipsisVerticalIcon
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Static Actions">
                          <DropdownItem
                            key={t('common:contextMenu.edit')}
                            onClick={() => handleOnDropdownItemClick('RENAME_CLIENT', client)}
                            startContent={
                              <span className="menu-item-icon appkiticon icon-edit-outline" />
                            }
                          >
                            {t('common:contextMenu.edit')}
                          </DropdownItem>
                          <DropdownItem
                            key={t('common:contextMenu.share')}
                            onClick={() => handleOnDropdownItemClick('SHARE_CLIENT', client)}
                            startContent={
                              <span className="menu-item-icon appkiticon icon-share-outline" />
                            }
                          >
                            {t('common:contextMenu.share')}
                          </DropdownItem>
                          <DropdownItem
                            className="text-red-500"
                            color="danger"
                            key="delete"
                            onClick={() => handleOnDropdownItemClick('DELETE_CLIENT', client)}
                            startContent={
                              <span className="menu-item-icon appkiticon icon-delete-outline" />
                            }
                          >
                            {t('common:contextMenu.delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="2xl"
      >
        <ModalContent>
          <SelectedModalContent {...clientModalContentProps} />
        </ModalContent>
      </Modal>
    </>
  );
};

export const ClientsOverview = bindControllers(View, [useController]);

ClientsOverview.displayName = 'ClientsOverview';
