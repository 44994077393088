import { useEffect, useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { Combobox, ComboboxItem } from '@org/design';
import { useTranslation } from '@org/locales';
import { dice } from '@org/query';

interface WiberaYearSelectProps {
  updateSelectedWiberaYear: (year: number) => Promise<void>;
  currentYear: number;
  wiberaConfigYear: number | undefined;
  disabled: boolean;
}

export const WiberaYearSelect = ({
  updateSelectedWiberaYear,
  currentYear,
  wiberaConfigYear,
  disabled = false,
}: WiberaYearSelectProps) => {
  const { t } = useTranslation();
  const { data: indexFactorYears } = dice.useGetIndexFactorYears({
    pathParams: {
      onlyYears: 'true',
    },
  });

  const { control } = useForm({
    defaultValues: {
      selectedWiberaYear: wiberaConfigYear?.toString() ?? currentYear.toString(),
    },
  });

  const uniqueYearLabels = useMemo(
    () =>
      Array.from(new Set(indexFactorYears ?? []))
        .sort()
        .map(Number),
    [indexFactorYears],
  );

  // setting default values for wibera file year,
  // if uploaded files do not include currently saved year
  useEffect(() => {
    if (
      uniqueYearLabels?.length > 0 &&
      wiberaConfigYear &&
      !uniqueYearLabels.includes(wiberaConfigYear)
    ) {
      if (uniqueYearLabels.includes(currentYear)) {
        updateSelectedWiberaYear(currentYear);
      } else {
        updateSelectedWiberaYear(uniqueYearLabels.at(-1) ?? currentYear);
      }
    }
  }, [currentYear, wiberaConfigYear, updateSelectedWiberaYear, uniqueYearLabels]);

  return uniqueYearLabels?.length ? (
    <Controller
      control={control}
      name="selectedWiberaYear"
      render={({ field: { onChange, value } }) => (
        <Combobox
          aria-label={t('main:manageMenu.manageWiberaMapping.lblSelectWiberaYear')}
          isDisabled={disabled}
          onSelectionChange={async (newValue) => {
            onChange(newValue);
            await updateSelectedWiberaYear(newValue as number);
          }}
          placeholder={t('main:manageMenu.manageWiberaMapping.lblSelectWiberaYear')}
          selectedKey={value}
          size="sm"
        >
          {uniqueYearLabels.map((year) => (
            <ComboboxItem key={year}>{year.toString()}</ComboboxItem>
          ))}
        </Combobox>
      )}
    />
  ) : (
    <></>
  );
};
