import type { FC } from 'react';

import { InterestRateReportTable, InterestReportModal } from '@org/features';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

export interface InterestTableViewProps extends ControllerType {}

const InterestTableView: FC<ControllerType> = (props) => {
  const {
    tableGridRef,
    modalProps,
    setTransactionsPresentToFlush,
    transactionsPresentToFlush,
    nonOperatingAssetsGridRef,
    operatingInterestTradeReport,
    nonOperatingInterestTradeReport,
    apiParams,
  } = props;
  const { handleOpen, selectedAssets, isOpen, onOpenChange, ...otherModalProps } = modalProps;

  return (
    <>
      <div className="flex min-h-[calc(100vh-200px)] w-[calc(100vw-50px)] flex-col p-0 pb-3 pt-3">
        <div className="flex h-[calc(100vh-150px)] w-full">
          <InterestRateReportTable
            agGridRef={tableGridRef}
            apiParams={apiParams}
            data={operatingInterestTradeReport}
            handleOpen={handleOpen}
          />
        </div>
        <div className="flex h-[215px] w-full pt-2">
          <InterestRateReportTable
            agGridRef={nonOperatingAssetsGridRef}
            apiParams={apiParams}
            data={nonOperatingInterestTradeReport}
            handleOpen={handleOpen}
          />
        </div>
      </div>
      <InterestReportModal
        apiParams={apiParams}
        data={selectedAssets}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        setTransactionsPresentToFlush={setTransactionsPresentToFlush}
        transactionsPresentToFlush={transactionsPresentToFlush}
        {...otherModalProps}
      />
    </>
  );
};

export const InterestReportPage = bindControllers(InterestTableView, [useController]);
InterestReportPage.displayName = 'InterestReportPage';
