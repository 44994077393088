import type { dice } from '@org/query';
import { t } from '@org/locales';
import { formatNumberToLocaleString } from '@org/utils/number';
import * as objUtil from '@org/utils/object';
import { isNil } from '@org/utils/type-guards';

import type { CostUnitReportRow } from '../models';
import { roundNumber } from '../../../utils/formatUtils';
import { createCostCentersAggregatedRows } from './createCostCentersAggregatedRows';

const createRowsBasedOnCostUnitsConfig = (
  costUnits: dice.CostUnit[],
  rowsToExtract: Record<string, string>,
) =>
  Object.entries(rowsToExtract).map(([rowKey, rowName]) => {
    const currentRow: CostUnitReportRow = {
      costRevenueCalculationTotal: 0,
      costTypeDescription: rowName,
    };

    costUnits.forEach((costUnit) => {
      const value = objUtil.get(costUnit, rowKey);
      objUtil.set(currentRow, costUnit.shortName, value);
      currentRow.costRevenueCalculationTotal += value ?? 0;
    });

    currentRow.costRevenueCalculationTotal = roundNumber(currentRow.costRevenueCalculationTotal);
    return currentRow;
  });

const createCalculationResultsRow = (
  costUnitRows: CostUnitReportRow[],
  costUnits: dice.CostUnit[],
) => {
  const foundTotalReimbursementNeedsRows = costUnitRows.filter(
    (obj) => obj.parentCostTypeDescription === t('main:reportPanels.costUnits.tableRows.subtotal'),
  );
  const foundReturnSurplusCover = costUnitRows.find(
    (obj) =>
      obj.costTypeDescription ===
      t('main:reportPanels.costUnits.tableRows.returnsAndRecoveriesFromPreviousYears'),
  );

  if (foundTotalReimbursementNeedsRows.length > 0 && foundReturnSurplusCover) {
    const foundTotalReimbursementNeeds = foundTotalReimbursementNeedsRows.reduce(
      (prev, curr) => prev + (curr?.costRevenueCalculationTotal ?? 0),
      0,
    );

    const calculationResultsRow: CostUnitReportRow = {
      costTypeDescription: t('main:reportPanels.costUnits.tableRows.costRecoveryRequirement'),
      costRevenueCalculationTotal: roundNumber(
        foundTotalReimbursementNeeds + (foundReturnSurplusCover.costRevenueCalculationTotal ?? 0),
      ),
    };
    costUnits.forEach((costUnit) => {
      const shortCostUnitName = costUnit.shortName;
      const foundTotalReimbursementNeedsForCostUnit = foundTotalReimbursementNeedsRows.reduce(
        (prev, curr) => prev + (objUtil.get(curr, shortCostUnitName) ?? 0),
        0,
      );

      objUtil.set(
        calculationResultsRow,
        shortCostUnitName,
        roundNumber(
          foundTotalReimbursementNeedsForCostUnit +
            (objUtil.get(foundReturnSurplusCover, shortCostUnitName) ?? 0),
        ),
      );
    });
    return calculationResultsRow;
  }
  return {};
};

const createAssessmentBaseRow = (): CostUnitReportRow => ({
  costTypeDescription: t('main:reportPanels.costUnits.tableRows.assessmentBase'),
  costRevenueCalculationTotal: 0,
});

const createFeeRateRow = (): CostUnitReportRow => ({
  costTypeDescription: t('main:reportPanels.costUnits.tableRows.feeRate'),
  costRevenueCalculationTotal: 0,
});

const updateAssessmentBaseRow = (
  assessmentBaseRow: CostUnitReportRow,
  costUnit: dice.CostUnit,
  unit: string | undefined,
) => {
  if (!isNil(costUnit.assessmentBase) && unit) {
    assessmentBaseRow.costRevenueCalculationTotal! += costUnit.assessmentBase ?? 0;
    objUtil.set(
      assessmentBaseRow,
      costUnit.shortName,
      `${formatNumberToLocaleString(costUnit.assessmentBase)} ${unit}`,
    );
  }
};

const updateFeeRateRow = (
  feeRateRow: CostUnitReportRow,
  costUnit: dice.CostUnit,
  shortCostUnitNameConfig: dice.CostUnit,
) => {
  if (!isNil(costUnit.feeRate) && shortCostUnitNameConfig.unit) {
    feeRateRow.costRevenueCalculationTotal! += costUnit.feeRate ?? 0;
    objUtil.set(
      feeRateRow,
      costUnit.shortName,
      `${formatNumberToLocaleString(costUnit.feeRate)} €/${shortCostUnitNameConfig.unit}`,
    );
  }
};

// TODO Refactor without create functions. Update functions is enough
export const convertToCostUnitReportRows = (
  reportCostUnits: dice.CostUnit[],
  configurationCostUnits: dice.CostUnit[],
): CostUnitReportRow[] => {
  let costUnitRows = createRowsBasedOnCostUnitsConfig(reportCostUnits, {
    creditOrReimbursementFromPreviousYears: t(
      'main:reportPanels.costUnits.tableRows.returnsAndRecoveriesFromPreviousYears',
    ),
  });

  const costCenterAggregatedRows = createCostCentersAggregatedRows(reportCostUnits, {
    ccCostsShortNameToValue: t('main:reportPanels.costUnits.tableRows.eligibleCosts'),
    ccRevenuesShortNameToValue: t(
      'main:reportPanels.costUnits.tableRows.eligibleAncillaryRevenues',
    ),
    ccSubtotalShortNameToValue: t('main:reportPanels.costUnits.tableRows.subtotal'),
  });
  costUnitRows = [...costCenterAggregatedRows, ...costUnitRows];

  const calcResultsRow = createCalculationResultsRow(costUnitRows, reportCostUnits);
  const feeIncomeRow = createRowsBasedOnCostUnitsConfig(reportCostUnits, {
    feeIncome: t('main:reportPanels.costUnits.tableRows.feeIncome'),
  })[0];

  const feeIncomeBasicCharge = createRowsBasedOnCostUnitsConfig(reportCostUnits, {
    feeIncomeBasicCharge: t('main:reportPanels.costUnits.tableRows.feeIncomeBasicCharge'),
  })[0];
  const totalOverfundingUnderfundingRow = createRowsBasedOnCostUnitsConfig(reportCostUnits, {
    totalOverfundingUnderfunding: t(
      'main:reportPanels.costUnits.tableRows.totalOverfundingUnderfunding',
    ),
  })[0];

  const requiredCostCoverage = createRowsBasedOnCostUnitsConfig(reportCostUnits, {
    requiredCostCoverage: t('main:reportPanels.costUnits.tableRows.requiredCostCoverage'),
  })[0];
  const assessmentBaseRow = createAssessmentBaseRow();
  const feeRateRow = createFeeRateRow();

  reportCostUnits.forEach((costUnit: dice.CostUnit) => {
    const shortCostUnitNameConfig = configurationCostUnits.find(
      (cUnitConfig) => cUnitConfig.shortName === costUnit.shortName,
    );
    if (shortCostUnitNameConfig) {
      updateAssessmentBaseRow(assessmentBaseRow, costUnit, shortCostUnitNameConfig.unit);
      updateFeeRateRow(feeRateRow, costUnit, shortCostUnitNameConfig);
    }
  });

  return [
    ...costUnitRows,
    calcResultsRow,
    feeIncomeBasicCharge,
    requiredCostCoverage,
    assessmentBaseRow,
    feeRateRow,
    feeIncomeRow,
    totalOverfundingUnderfundingRow,
  ].map((item, index) => {
    item.rowIndex = index;
    return item;
  });
};
