import nameOf from 'easy-tsnameof';

import type { dice } from '@org/query';

export const GROUP_COL_NAME = 'groupName';
export const SUB_GROUP_COL_NAME = 'subGroupName';

export const columnNames = nameOf<dice.PriceSheetGroupDTO, never>();

export const PRICE_SHEET = 'PRICE_SHEET';
export const BASIC_FEE = 'BASIC_FEE';
