import type {
  CellEditingStoppedEvent,
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  IRowNode,
  ValueGetterParams,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import type { griddy } from '@org/query';
import type { ButtonHandlerProps } from '@org/ui';
import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';
import {
  generateGroupRowName,
  getCellClassesForGroups,
  getCopyOfTableData,
  GROUP,
  GROUP_COLUMN_ID,
  prepareGroupRowNamesFromGridAPI,
} from '@org/utils';

import type { BaseControllerType } from '../types';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

export const useController = (apiParams: UseControllerProps) => {
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);
  const { t } = useTranslation();

  const activeConfiguration =
    masterConfiguration?.imputedWithdrawalCapitalConfig?.imputedWithdrawalCapitalEnabled ?? false;

  const imputedWithdrawalCapitals = useMemo(
    () => masterConfiguration?.imputedWithdrawalCapitalConfig?.imputedWithdrawalCapitals ?? [],
    [masterConfiguration?.imputedWithdrawalCapitalConfig?.imputedWithdrawalCapitals],
  );

  const [enableDeleteButton, setEnableDeleteButton] = useState(false);

  const tableRef = useRef<AgGridReact>(null);

  const saveConfiguration = useCallback(
    async (tableData?: griddy.ImputedWithdrawalCapital[]) => {
      const imputedWithdrawalCapitalsData = tableData ?? getCopyOfTableData(tableRef?.current?.api);

      await updateMasterConfiguration({
        ...masterConfiguration,
        imputedWithdrawalCapitalConfig: {
          ...masterConfiguration?.imputedWithdrawalCapitalConfig,
          imputedWithdrawalCapitals: imputedWithdrawalCapitalsData,
        },
      });
    },
    [masterConfiguration, tableRef, updateMasterConfiguration],
  );

  const updateSingleRowGroupName = useCallback(
    async (node: IRowNode, newValue: string) => {
      const { data } = node;
      data.groupName = newValue;

      tableRef.current?.api?.applyTransaction({ update: [data] });

      await saveConfiguration();
    },
    [saveConfiguration],
  );

  const updateGroupName = useCallback(
    async (event: CellEditingStoppedEvent) => {
      if (event.column.getColId() === GROUP_COLUMN_ID) {
        const { oldValue, newValue, node } = event;

        if (oldValue !== newValue) {
          const childrenRowData = (node.childrenAfterGroup ?? []).map((row) => {
            const { data } = row;
            data.groupName = newValue;
            return data;
          });
          if (childrenRowData?.length) {
            tableRef.current?.api?.applyTransaction({ update: childrenRowData });
          }

          await saveConfiguration();
        }
      }
    },
    [saveConfiguration],
  );

  const onTableDataChange = useCallback(
    async (tableData?: griddy.ImputedWithdrawalCapital[], event?: CellEditingStoppedEvent) => {
      if (event?.column.getColId() === GROUP_COLUMN_ID) {
        const { node, newValue } = event;
        await updateSingleRowGroupName(node, newValue);
      } else {
        tableData?.forEach((item) => {
          if (
            item.lifeTimeInMonths !== undefined &&
            item.lifeTimeInMonths !== 0 &&
            item.lifeTimeInMonths < 12
          ) {
            item.lifeTimeInMonths = 12;
            tableRef?.current?.api?.applyTransaction({ update: [item] });
            showNotification(
              'warning',
              t('main:manageMenu.manageImputedWithdrawalCapital.warningLifeTimeAdjusted'),
            );
          }
        });
        await saveConfiguration(tableData);
      }
    },
    [saveConfiguration, t, updateSingleRowGroupName],
  );

  const addSubRow = useCallback(
    async ({ data, node }: ICellRendererParams) => {
      const { agGridRowId: _, ...rest } = data;
      tableRef.current?.api?.applyTransaction({
        add: [rest],
        addIndex: node.rowIndex,
      });
      await saveConfiguration();
    },
    [saveConfiguration, tableRef],
  );

  const addNewRow = useCallback(async () => {
    const updated = tableRef.current?.api?.applyTransaction({
      add: [
        {
          babReportEnabled: false,
          groupName: generateGroupRowName(
            t('main:manageMenu.manageImputedWithdrawalCapital.newGroup'),
            prepareGroupRowNamesFromGridAPI(tableRef.current?.api, 'groupName'),
          ),
          interestTableReportEnabled: false,
        },
      ],
    });
    if (updated?.add?.length) {
      tableRef.current?.api?.ensureIndexVisible(updated.add[0].rowIndex!, null);
      await saveConfiguration();
    }
  }, [tableRef, t, saveConfiguration]);

  const onSelectionChanged = () => {
    setEnableDeleteButton(!!tableRef.current?.api?.getSelectedRows().length);
  };

  const deleteRow = useEvent(async (btnProps: ButtonHandlerProps) => {
    const { type, ...props } = btnProps;
    let rowsToKeep: griddy.ImputedWithdrawalCapital[];
    let nodesToDelete;

    if (type === GROUP) {
      const {
        node: { key: groupName, childrenAfterGroup },
      } = props;

      rowsToKeep = imputedWithdrawalCapitals.filter((cap) => cap.groupName !== groupName);
      nodesToDelete = childrenAfterGroup?.map((nodeToDelete) => nodeToDelete.data);
    } else {
      const {
        data,
        node: { rowIndex },
      } = props;

      const allNodes: IRowNode[] = [];
      tableRef.current?.api?.forEachLeafNode((row) => allNodes.push(row));
      rowsToKeep = allNodes
        .filter((cap) => cap.rowIndex !== rowIndex)
        .map((rowToKeep) => rowToKeep.data);
      nodesToDelete = [data];
    }

    tableRef.current?.api?.applyTransaction({
      remove: nodesToDelete,
    });

    await saveConfiguration(rowsToKeep);
  });

  const activateConfiguration = useCallback(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      await updateMasterConfiguration({
        ...masterConfiguration,
        imputedWithdrawalCapitalConfig: {
          ...masterConfiguration?.imputedWithdrawalCapitalConfig,
          imputedWithdrawalCapitalEnabled: checked,
        },
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const clearSelected = useEvent(async () => {
    const allNodes: IRowNode[] = [];
    tableRef.current?.api?.forEachLeafNode((row) => allNodes.push(row));
    const selectedNodes = tableRef.current?.api?.getSelectedNodes().map((n) => n);

    const rowsToKeep = allNodes
      .filter(
        (node) => !selectedNodes?.some((selectedNode) => selectedNode.rowIndex === node.rowIndex),
      )
      .map((node) => node.data);

    tableRef.current?.api?.applyTransaction({ remove: tableRef.current?.api?.getSelectedRows() });

    await saveConfiguration(rowsToKeep);
  });

  const defaultColDef: ColDef = useMemo(
    () => ({
      editable: (params: EditableCallbackParams) => activeConfiguration && !params.node.group,
      filter: true,
      floatingFilter: true,
    }),
    [activeConfiguration],
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererParams: {
        checkbox: activeConfiguration,
        suppressCount: true,
      },
      editable: activeConfiguration,
      filterValueGetter: (params: ValueGetterParams) => params.data.groupName,
      headerName: t('main:manageMenu.manageImputedWithdrawalCapital.groupName'),
    }),
    [t, activeConfiguration],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonHandlerProps>({
    onConfirm: deleteRow,
  });

  const columnDefs = useColumns({
    activeConfiguration,
    addNewRow,
    addSubRow,
    apiParams,
    handleDeleteModal,
    onTableDataChange,
    rowData: imputedWithdrawalCapitals,
  });

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: imputedWithdrawalCapitals,
  });

  return {
    activateConfiguration,
    activeConfiguration,
    agGridProps,
    autoGroupColumnDef,
    clearSelected,
    columnDefs,
    defaultColDef,
    enableDeleteButton,
    gridApi: tableRef.current?.api,
    modal,
    onSelectionChanged,
    onTableDataChange,
    tableRef,
    updateGroupName,
  };
};

export type ControllerType = ReturnType<typeof useController>;
