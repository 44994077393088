import type { CostCenter, ReportsResponse, TradeTaxReport, TradeTaxReportEntry } from '../../rave';
import { getGroupTotalsOfRow } from './groupTotals';

const TRADE_TAX_TABLE_ROWS = [
  { group: 'DELTA_TOTAL', rowName: 'IMPUTED_DEPRECIATION_APC', subGroup: 'DELTA_DEPRECIATION' },
  { group: 'DELTA_TOTAL', rowName: 'IMPUTED_DEPRECIATION_RPV', subGroup: 'DELTA_DEPRECIATION' },
  { group: 'DELTA_TOTAL', rowName: 'COMMERCIAL_LAW_DEPRECIATION', subGroup: 'DELTA_DEPRECIATION' },
  { group: 'DELTA_TOTAL', rowName: 'IMPUTED_INTEREST', subGroup: 'DELTA_INTEREST' },
  { group: 'DELTA_TOTAL', rowName: 'COMMERCIAL_LAW_INTEREST', subGroup: 'DELTA_INTEREST' },
  {
    aggFunction: () => undefined,
    group: undefined,
    rowName: 'TRADE_TAX_RATE',
    subGroup: undefined,
  },
  { group: undefined, rowName: 'IMPUTED_TRADE_TAX', subGroup: undefined },
] satisfies {
  aggFunction?: (
    row: TradeTaxReportTableEntry,
    costCentersOfGroup: CostCenter[],
  ) => number | undefined;
  group?: string | null;
  rowName: Exclude<TradeTaxReportEntry['rowName'], undefined>;
  subGroup?: string | null;
}[];

interface GenerateTradeTaxTableReportProps {
  reportObject: ReportsResponse;
  primaryCostCentersGrouped: [string, CostCenter[]][];
  indirectCostCentersGrouped: [string, CostCenter[]][];
}

interface TradeTaxReportTableEntry extends TradeTaxReportEntry {
  group?: string | null;
  subGroup?: string | null;
}

export const addGroupTotalsToFlattenedData = (
  rowData: TradeTaxReportTableEntry[],
  indirectCCGroups: [string, CostCenter[]][],
  primaryCCGroups: [string, CostCenter[]][],
) => {
  if (!rowData) {
    return rowData;
  }
  return rowData.map((row) => {
    const totals = getGroupTotalsOfRow({ indirectCCGroups, primaryCCGroups, row });
    return { ...row, ...totals };
  });
};

export const generateTradeTaxTableReport = ({
  reportObject: { tradeTaxReport },
  indirectCostCentersGrouped,
  primaryCostCentersGrouped,
}: GenerateTradeTaxTableReportProps) => {
  const tableData = TRADE_TAX_TABLE_ROWS.flatMap(
    ({ rowName, subGroup, group, aggFunction }, index) => {
      if ((tradeTaxReport as TradeTaxReport['tradeTaxReportEntries'])?.[rowName]?.rowName) {
        return [
          {
            // FIXME: Type is wrong
            ...(tradeTaxReport as TradeTaxReport['tradeTaxReportEntries'])?.[rowName],
            aggFunction,
            group,
            rowIndex: index,
            rowName,
            subGroup,
          },
        ];
      }
      return [];
    },
  );

  return addGroupTotalsToFlattenedData(
    tableData,
    indirectCostCentersGrouped,
    primaryCostCentersGrouped,
  );
};
