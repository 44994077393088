import type { dice, rave } from '@org/query';
import { t } from '@org/locales';

import type { BabRow } from '../../ReportTable/types';
import { ACCOUNT_TYPES } from '../../ReportTable/types';
import { extractAllocationKeyCalculations } from './prepareAllocationData';

export const prepareExpenseCalculations = (
  apiResponse?: rave.ReportsResponse,
  selectedConfiguration?: dice.MasterConfiguration,
  applyRPV?: boolean,
  applyCalculatedInterests?: boolean,
): BabRow[] => {
  const { babReport } = apiResponse ?? {};

  const EXPENSE_CALC_NAMES = [
    ...(applyCalculatedInterests ? ['IMPUTED_INTEREST'] : []),
    applyRPV ? 'IMPUTED_DEPRECIATION_RPV' : 'IMPUTED_DEPRECIATION_APC',
    ...(selectedConfiguration?.concessionFeeConfig?.concessionFeeEnabled
      ? ['IMPUTED_CONCESSION_FEE']
      : []),
    ...(selectedConfiguration?.imputedTradeTaxConfig ? ['IMPUTED_TRADE_TAX'] : []),
    ...(selectedConfiguration?.corporateRiskConfig ? ['IMPUTED_CORPORATE_RISK'] : []),
    'TOTAL_COSTS',
  ];

  const expenseCalculations = EXPENSE_CALC_NAMES.map((name) =>
    createBabRow(babReport, name, ACCOUNT_TYPES.EXPENSE),
  );
  const allocationKeyCalculations = extractAllocationKeyCalculations(
    apiResponse,
    ACCOUNT_TYPES.EXPENSE,
  );
  // put allocation keys between total costs and total costs after allocation
  const totalCostAfterAllocation = createBabRow(
    babReport,
    'TOTAL_COSTS_AFTER_COST_ALLOCATION',
    ACCOUNT_TYPES.EXPENSE,
  );

  return [...expenseCalculations, ...allocationKeyCalculations, totalCostAfterAllocation];
};

export const prepareRevenueCalculations = (apiResponse?: rave.ReportsResponse): BabRow[] => {
  const { babReport } = apiResponse ?? {};

  const totalRevenue = createBabRow(babReport, 'TOTAL_REVENUE', ACCOUNT_TYPES.REVENUE);
  const totalRevenueAfterAllocation = createBabRow(
    babReport,
    'TOTAL_REVENUE_AFTER_REVENUE_ALLOCATION',
    ACCOUNT_TYPES.REVENUE,
  );
  const allocationKeyCalculations = extractAllocationKeyCalculations(
    apiResponse,
    ACCOUNT_TYPES.REVENUE,
  );

  return [totalRevenue, ...allocationKeyCalculations, totalRevenueAfterAllocation];
};

export const prepareSubTotalRow = (babReport?: rave.BabReport) =>
  createBabRow(babReport, 'REIMBURSEMENT_NEEDS', undefined);

const createBabRow = (
  babReport: rave.ReportsResponse['babReport'],
  attrName: string,
  accountType?: ACCOUNT_TYPES,
): BabRow => ({
  ...(babReport?.[attrName as keyof rave.ReportsResponse['babReport']] as {} | undefined),
  // to display BAB KPIs outside the groups
  accountId: t(`main:reportPanels.babReport.tableRows.${attrName}`),

  accountType,
});

export const getAllModifiedClientCostCenters = (
  modifications: dice.TransactionModification[],
): (string | undefined)[] => {
  if (!modifications) {
    return [];
  }
  return modifications
    .flatMap(({ mappedCostCenterShortName, clientsCostCenterName }) =>
      clientsCostCenterName
        ? [mappedCostCenterShortName, clientsCostCenterName]
        : [mappedCostCenterShortName],
    )
    .filter(Boolean);
};

export function compareAccountRows() {
  return (firstRow: BabRow, secondRow: BabRow) =>
    (firstRow?.costTypeAccountRank ?? 0) - (secondRow?.costTypeAccountRank ?? 0);
}

export const addModificationCountsToRows = (
  rowsData: BabRow[],
  transactionAccounts: Map<string | number | undefined | null, dice.TransactionModification[]>,
  manualCorrectionsForAccounts: Map<string | number | undefined | null, dice.ManualCorrection[]>,
): BabRow[] =>
  rowsData
    .map((account) => {
      const accountModifications = transactionAccounts.get(account.accountId) ?? [];
      const manualCorrectionsForAccount = manualCorrectionsForAccounts.get(account.accountId) ?? [];

      const modifiedCostCenters = getAllModifiedClientCostCenters(accountModifications);
      const manuallyCorrectedCostCenters = manualCorrectionsForAccount.map(
        (correction) => correction.costCenterShortName,
      );

      return {
        ...account,
        manuallyCorrectedCostCenters,
        modifications: accountModifications.length + manualCorrectionsForAccount.length,
        modifiedCostCenters,
      };
    })
    .sort(compareAccountRows());
