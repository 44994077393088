import type { CellClassFunc, CellClassParams, ColDef, ColGroupDef } from 'ag-grid-community';

import classNames from 'classnames';

import type { TFunction } from '@org/locales';

import type { griddy } from '../../../query';

export const addPriceClassForCorrectType = (params: CellClassParams) => {
  if (params.colDef.type === 'report-price') {
    return 'ag-cell-type-report-price';
  }
  return '';
};

export const addBoldTextClassForFirstLevelRows = ({ node, data }: CellClassParams) => {
  if (node.level <= 0 && !data?.disableRowFormatting) {
    return 'font-bold';
  }
  return '';
};

export const addBoldTextClassForFirstLevelExceptEmptyGroupsRows = ({ node }: CellClassParams) => {
  if (node.level <= 0 && node.allChildrenCount !== null) {
    return 'font-bold';
  }
  return '';
};

export interface RenderColumnGroupParams extends ColDef {
  type: string;
  cellClassName?: string;
  cellClass?: CellClassFunc;
}

const getCCColumnsForGroup = (
  costCentersList: griddy.CostCenter[],
  groupIndex: number,
  props: RenderColumnGroupParams,
  isSeparated: boolean,
) => {
  const { aggFunc, cellClassName, cellClass: cellClassProp } = props;

  return costCentersList.map((costCenter, index): ColDef | ColGroupDef => {
    const { shortName = '', longName } = costCenter;
    const cellClasses = classNames({
      'border-left-1px': index === 0 && groupIndex !== 0,
      // Separator should be added only for first cost center which index is 0
      'border-left-2px': isSeparated && index === 0 && groupIndex === 0,
    });
    return {
      aggFunc,
      cellClass: (params) => [
        ...(cellClassProp?.(params) ?? []),
        addBoldTextClassForFirstLevelRows(params),
        addPriceClassForCorrectType(params),
        cellClasses,
        cellClassName ?? '',
        'accounting-number',
      ],
      colId: `${props.type}_${shortName}`,
      // cellStyle: applyStylesToFirstLevelRow({ textAlign: 'right' }),
      columnGroupShow: 'open',
      field: shortName,
      headerClass: classNames('white-background', cellClasses),
      headerName: longName,
      type: 'report-price',
    };
  });
};

export interface GenerateColumnGroupParams extends ColDef {
  costCentersGroups: [string, griddy.CostCenter[]][];
  type: string;
  isSeparated?: boolean;
  t: TFunction;
  cellClass?: CellClassFunc;
}

export const generateColumnGroups = (
  props: GenerateColumnGroupParams,
): (ColDef | ColGroupDef)[] => {
  const {
    costCentersGroups,
    type,
    aggFunc,
    cellClass: defaultCellClass,
    t,
    isSeparated = false,
  } = props;
  if (costCentersGroups.length === 0) {
    return [];
  }

  return costCentersGroups.map(([groupName, costCentersList], groupIndex) => {
    const cols = getCCColumnsForGroup(costCentersList, groupIndex, props, isSeparated);
    const groupBorderClasses = classNames({
      'border-left-1px': groupIndex !== 0,
      'border-left-2px': groupIndex === 0 && isSeparated,
    });

    if (cols.length > 1) {
      cols.unshift({
        aggFunc,
        cellClass: (params: CellClassParams) => [
          ...(defaultCellClass?.(params) ?? []),
          addBoldTextClassForFirstLevelRows(params),
          addPriceClassForCorrectType(params),
          groupBorderClasses,
          'accounting-number',
        ],
        colId: `${type}_GROUP_TOTAL_${groupName}`,
        columnGroupShow: 'closed',
        field: `${type}_GROUP_TOTAL_${groupName}`,
        headerClass: classNames('white-background', groupBorderClasses),
        headerName: t('common:total'),
        type: 'report-price',
      });
    }

    if (groupName) {
      return {
        children: cols,
        colId: groupName,
        headerClass: classNames(groupBorderClasses, {
          'grey-light-background': groupIndex % 2,
          'grey-lighter-background': !(groupIndex % 2),
        }),
        headerName: groupName,
        openByDefault: true,
        showRowGroup: true,
      } satisfies ColDef | ColGroupDef;
    }

    return {
      children: cols,
      headerName: t('main:reportPanels.babReport.noGroup'),
      openByDefault: true,
      showRowGroup: true,
    } satisfies ColDef | ColGroupDef;
  });
};
