import type { AgGridEvent, GetRowIdParams, GridApi } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import type { griddy } from '@org/query';
import { useDebounce, useEvent } from '@org/hooks';
import { aggregated } from '@org/query';
import { getCopyOfTableData } from '@org/utils';

import type { BaseControllerType } from '../types';
import { useSelectOptions } from './hooks/useSelectOptions';
import { useColumns } from './useColumns';

export interface ControllerProps extends BaseControllerType {
  disableEditing: boolean;
}

export type ControllerType = ReturnType<typeof useController>;

export const DEFAULT_INTEREST_RATE = 0;

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
};

const getFilteredCostCenterInterestRates = (
  gridApi: GridApi | undefined,
  newInterestRate: number,
) => {
  const updatedRows: griddy.CostCenterInterestRates[] = [];
  gridApi?.forEachNodeAfterFilter((rowNode) => {
    rowNode.data.interestRate = newInterestRate;
    updatedRows.push(rowNode.data);
  });
  gridApi?.applyTransaction({ update: updatedRows });
  return updatedRows;
};

const mergeCCInterestRates = (
  gridApi: GridApi | undefined,
  newCCRates: griddy.CostCenterInterestRates[],
) => {
  const tableData = getCopyOfTableData<griddy.CostCenterInterestRates>(gridApi);

  newCCRates.forEach((changedCCRate) => {
    const changedIdx = tableData.findIndex(
      (tableCCRate) => tableCCRate.ccShortName === changedCCRate.ccShortName,
    );
    if (changedIdx !== -1) {
      tableData[changedIdx] = changedCCRate;
    }
  });

  return tableData;
};

export function useController({ disableEditing, ...apiParams }: ControllerProps) {
  const { masterConfiguration, updateMasterConfiguration: updateConfiguration } =
    aggregated.useMasterConfiguration(apiParams);
  const uniformInterestRate = useMemo(
    () => masterConfiguration?.interestRates?.uniformInterestRate ?? DEFAULT_INTEREST_RATE,
    [masterConfiguration?.interestRates?.uniformInterestRate],
  );

  const selectOptions = useSelectOptions();

  const disabled = useMemo(
    () => !masterConfiguration?.interestRates?.applyCalculatedInterests || disableEditing,
    [disableEditing, masterConfiguration?.interestRates?.applyCalculatedInterests],
  );

  const tableRef = useRef<AgGridReact>(null);

  const [noneIsSelected, setNoneIsSelected] = useState(true);

  const onSelectionChanged = (event: AgGridEvent) => {
    setNoneIsSelected(event.api?.getSelectedRows().length === 0);
  };

  const onApplyInterestRate = async () => {
    const changedRows = getFilteredCostCenterInterestRates(
      tableRef?.current?.api,
      uniformInterestRate,
    );

    const tableData = mergeCCInterestRates(tableRef?.current?.api, changedRows);

    await onTableDataChange(tableData);
  };

  const updateCostCenterInterestRates = useCallback(
    async (costCenterInterestRates: griddy.InterestRates['costCenterInterestRates']) => {
      await updateConfiguration({
        ...masterConfiguration,
        interestRates: {
          ...masterConfiguration?.interestRates,
          costCenterInterestRates,
        },
      });
    },
    [masterConfiguration, updateConfiguration],
  );

  const onTableDataChange = useCallback(
    async (costCenterInterestRatesChange: griddy.CostCenterInterestRates[]) => {
      await updateCostCenterInterestRates(costCenterInterestRatesChange);
    },
    [updateCostCenterInterestRates],
  );

  const handleResetRow = useCallback(
    async (costCenterInterestRate: griddy.CostCenterInterestRates) => {
      costCenterInterestRate.interestRate = uniformInterestRate;

      const tableData = mergeCCInterestRates(tableRef?.current?.api, [costCenterInterestRate]);

      tableRef?.current?.api.applyTransaction({ update: [costCenterInterestRate] });
      await updateCostCenterInterestRates(tableData);
    },
    [uniformInterestRate, updateCostCenterInterestRates],
  );

  const handleResetSelectedRows = useCallback(async () => {
    const data = (tableRef?.current?.api.getSelectedRows() ??
      []) as griddy.CostCenterInterestRates[];
    data.forEach(
      (costCenterInterestRate) => (costCenterInterestRate.interestRate = uniformInterestRate),
    );

    const tableData = mergeCCInterestRates(tableRef?.current?.api, data);

    tableRef?.current?.api.applyTransaction({ update: [data] });
    tableRef?.current?.api.deselectAll();

    await updateCostCenterInterestRates(tableData);
  }, [uniformInterestRate, updateCostCenterInterestRates]);

  const handleChangeDefaultInterestRate = useDebounce(async (newValue: number | undefined) => {
    await updateDefaultInterestRate(
      typeof newValue === 'number' ? newValue : DEFAULT_INTEREST_RATE,
    );
  }, 300);

  const enableCalculatedInterestsToggle = useEvent(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      await updateConfiguration({
        ...masterConfiguration,
        interestRates: {
          ...masterConfiguration?.interestRates,
          applyCalculatedInterests: checked,
        },
      });
    },
  );

  const updateBearingCapitalBased = useCallback(
    async (interestBearingCapitalBased: griddy.InterestRates['interestBearingCapitalBased']) => {
      await updateConfiguration({
        ...masterConfiguration,
        interestRates: {
          ...masterConfiguration?.interestRates,
          interestBearingCapitalBased,
        },
      });
    },
    [masterConfiguration, updateConfiguration],
  );

  const updateDefaultInterestRate = useCallback(
    async (data: griddy.InterestRates['uniformInterestRate']) => {
      await updateConfiguration({
        ...masterConfiguration,
        interestRates: {
          ...masterConfiguration?.interestRates,
          uniformInterestRate: data,
        },
      });
    },
    [masterConfiguration, updateConfiguration],
  );

  const getRowId = useCallback(({ data }: GetRowIdParams) => data?.ccShortName, []);

  const columnDefs = useColumns({
    apiParams,
    handleResetRow,
    isDisabled: disabled,
  });

  return {
    columnDefs,
    defaultColDef,
    disabled,
    enableCalculatedInterestsToggle,
    getRowId,
    handleChangeDefaultInterestRate,
    handleResetSelectedRows,
    masterConfiguration,
    noneIsSelected,
    onApplyInterestRate,
    onSelectionChanged,
    onTableDataChange,
    rowData: masterConfiguration?.interestRates?.costCenterInterestRates ?? [],
    selectOptions,
    tableRef,
    updateBearingCapitalBased,
  };
}
