import type { FC } from 'react';

import { Controller } from 'react-hook-form';

import type { dice } from '@org/query';
import { Combobox, ComboboxItem } from '@org/design';
import { t } from '@org/locales';
import { BaseEditTable, Label } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerProps, ControllerType } from './useController';
import { DistributionTable, ManualTable, TechnicalData } from './components';
import { TechnicalDataDetailModal } from './components/TechnicalDataDetail';
import { useController } from './useController';

export type PlannedCostRevenueMasterDetailProps = ControllerProps;

const View: FC<ControllerType> = (props) => {
  const {
    columnDefs,
    modal,
    defaultColDef,
    agGridProps,
    getRowId,
    tableRef,
    control,
    methodOptions,
    currentMethodType,
    apiParams,
    onRowSelectionChanged,
    selectedPlannedDirectEntryId,
    selectedPlannedAccountEntry,
    onFirstSelectFirst,
    handleOnChangeMethod,
    updateAndReplacePlannedCostRevenueDirectEntry,
    handleOnChange,
    distributionTableRef,
    manualTableRef,
    handleOnCommentChange,
    technicalTableRef,
    isOpenTechnicalModal,
    onOpenChangeTechnicalModal,
    technicalModalEntryId,
  } = props;

  const methodProps = {
    apiParams,
    selectedPlannedAccountEntry,
    selectedPlannedDirectEntryId,
    updateAndReplacePlannedCostRevenueDirectEntry,
  };

  return (
    <div className="h-[415px]">
      <div className="flex h-[75%]">
        <div className="h-full w-1/2 p-2">
          <BaseEditTable
            {...agGridProps}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            onCellValueChanged={handleOnChange}
            onFirstDataRendered={onFirstSelectFirst}
            onSelectionChanged={onRowSelectionChanged}
            ref={tableRef}
            rowSelection="single"
            suppressRowClickSelection={false}
          />
          {modal}
          <TechnicalDataDetailModal
            apiParams={apiParams}
            isOpenTechnicalModal={isOpenTechnicalModal}
            onOpenChangeTechnicalModal={onOpenChangeTechnicalModal}
            selectedPlannedAccountEntry={selectedPlannedAccountEntry}
            selectedPlannedDirectEntryId={technicalModalEntryId}
            updateAndReplacePlannedCostRevenueDirectEntry={
              updateAndReplacePlannedCostRevenueDirectEntry
            }
          />
        </div>
        {selectedPlannedDirectEntryId && (
          <div className="flex h-full w-1/2 flex-col p-2">
            <Label className="mb-0 inline-flex w-full cursor-pointer items-center gap-2 font-bold">
              <span className="text-[0.75rem] after:content-[':']">
                {t('main:manageMenu.allocationKeysConfiguration.tableColumns.method')}
              </span>
              <Controller
                control={control}
                name="methodType"
                render={({ field }) => (
                  <Combobox
                    {...field}
                    aria-label={t(
                      'main:manageMenu.allocationKeysConfiguration.tableColumns.method',
                    )}
                    isFullWidth
                    onSelectionChange={async (data) => {
                      field.onChange(data);
                      await handleOnChangeMethod(
                        data as dice.PlannedDirectEntryDistributionConfigDTO['allocationMethod'],
                      );
                    }}
                    selectedKey={field.value}
                  >
                    {methodOptions.map(({ label, value }) => (
                      <ComboboxItem key={value}>{label}</ComboboxItem>
                    ))}
                  </Combobox>
                )}
              />
            </Label>

            <div className="h-full pt-2">
              {currentMethodType === 'DISTRIBUTION' && (
                <div className="flex h-full">
                  <DistributionTable
                    {...methodProps}
                    currentMethodType={currentMethodType}
                    gridRef={distributionTableRef}
                  />
                </div>
              )}

              {currentMethodType === 'TECHNICAL_DATA' && (
                <div className="flex h-full">
                  <TechnicalData
                    {...methodProps}
                    currentMethodType={currentMethodType}
                    gridRef={technicalTableRef}
                  />
                </div>
              )}

              {currentMethodType === 'MANUAL' && (
                <div className="flex h-full">
                  <ManualTable
                    {...methodProps}
                    currentMethodType={currentMethodType}
                    gridRef={manualTableRef}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mx-2 mb-2 space-y-2">
        <div className="font-bold">{t('main:comments.header')}</div>
        <Controller
          control={control}
          name="comment"
          render={({ field }) => (
            <textarea
              {...field}
              className="w-full border border-solid border-gray-500 bg-white p-2"
              onChange={async ({ target: { value } }) => {
                field.onChange(value);
                await handleOnCommentChange(value);
              }}
              rows={4}
            />
          )}
        />
      </div>
    </div>
  );
};

export const PlannedCostRevenueMasterDetail = bindControllers(View, [useController]);
PlannedCostRevenueMasterDetail.displayName = 'PlannedCostRevenueMasterDetail';
