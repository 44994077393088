import type { ChangeEvent } from 'react';
import { useCallback, useReducer } from 'react';

export interface State {
  profitCenterSearch: string;
  costCenterSearch: string;
  jobIdSearch: string;
  selectedProfitCenterName: string;
  selectedCostCenterName: string;
}

export const useLocalState = () => {
  const [localState, updateState] = useReducer(
    (state: State, action: Partial<State>) => ({ ...state, ...action }),
    {
      costCenterSearch: '',
      jobIdSearch: '',
      profitCenterSearch: '',
      selectedCostCenterName: '',
      selectedProfitCenterName: '',
    },
  );

  const handleInputChange = useCallback(
    (key: keyof State) =>
      ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
        updateState({
          [key]: value,
        });
      },
    [],
  );

  return {
    ...localState,
    handleInputChange,
    updateState,
  };
};
