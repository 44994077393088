import type { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { ButtonCellRendererProps } from '@org/ui';
import { useAgGridData, useEvent } from '@org/hooks';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
};

export const useController = (apiParams: UseControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);

  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);
  const { costCenterOptions } = aggregated.useCostCenters(apiParams);

  const manualCorrections = useMemo(
    () => masterConfiguration?.manualCorrections ?? [],
    [masterConfiguration],
  );

  const deleteManualCorrection = useCallback(
    async ({ gridProps: { node } }: ButtonCellRendererProps) => {
      if (node.rowIndex === undefined || node.rowIndex === null) {
        return;
      }

      const manualCorrectionsAfterDeletion = manualCorrections.filter(
        (_, index) => index !== node.rowIndex,
      );

      await updateMasterConfiguration({
        ...masterConfiguration,
        manualCorrections: manualCorrectionsAfterDeletion,
      });
    },
    [masterConfiguration, updateMasterConfiguration, manualCorrections],
  );

  const handleDelete = useCallback(
    (btnProps: ButtonCellRendererProps) => {
      deleteManualCorrection(btnProps);
    },
    [deleteManualCorrection],
  );

  const { onGridReady } = useAgGridData({
    agGridRef: tableRef,
    data: manualCorrections,
  });

  const addNewRow = useCallback(async () => {
    const newRow = {
      accountId: 0,
      correction: 0,
      correctionType: 'MANUAL',
      costCenterShortName: '',
    };

    const newlyAddedRow = tableRef.current?.api?.applyTransaction({
      add: [newRow],
    });

    if (newlyAddedRow?.add?.length) {
      const {
        add: [{ rowIndex }],
      } = newlyAddedRow;

      tableRef.current?.api?.ensureIndexVisible(rowIndex!, 'middle');

      await updateMasterConfiguration({
        ...masterConfiguration,
        manualCorrections: [...manualCorrections, newRow],
      });
    }
  }, [manualCorrections, masterConfiguration, updateMasterConfiguration]);

  const onChange = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      node,
      data: { agGridRowId: _, ...otherData },
    } = event;

    if (!colName) {
      return;
    }

    const updatedManualCorrection = manualCorrections;

    if (node.rowIndex) {
      updatedManualCorrection[node.rowIndex] = otherData;
    }

    await updateMasterConfiguration({
      ...masterConfiguration,
      manualCorrections: updatedManualCorrection,
    });
  });

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonCellRendererProps>(
    {
      onConfirm: handleDelete,
    },
  );

  const columnDefs = useColumns({
    masterConfigurationId: apiParams.masterConfigurationId,
    addNewRow,
    costCenterOptions,
    handleDeleteModal,
  });

  return {
    columnDefs,
    defaultColDef,
    handleDelete,
    modal,
    onChange,
    onGridReady,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
