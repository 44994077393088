import type { FC } from 'react';

import type { griddy } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { Combobox, ComboboxItem } from '@org/design';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, Label, NumberInput, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { DEFAULT_INTEREST_RATE, useController } from './useController';

interface InterestRatesProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const InterestRatesMemo: FC<InterestRatesProps> = (props) => {
  const {
    enableCalculatedInterestsToggle,
    masterConfiguration,
    disabled,
    updateBearingCapitalBased,
    selectOptions,
    handleChangeDefaultInterestRate,
    onApplyInterestRate,
    onTableDataChange,
    tableRef,
    rowData,
    defaultColDef,
    onSelectionChanged,
    noneIsSelected,
    handleResetSelectedRows,
    columnDefs,
    getRowId,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <h6 className="my-3">{t('main:manageMenu.manageInterestRates.tableTitle')}</h6>

      <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
        <ToggleInput
          checked={masterConfiguration?.interestRates?.applyCalculatedInterests ?? false}
          name={t('main:manageMenu.manageInterestRates.applyImputedInterests')}
          onChange={enableCalculatedInterestsToggle}
        />
        {t('main:manageMenu.manageInterestRates.applyImputedInterests')}
      </Label>

      <div className="w-[21rem] pt-2">
        <Label>{t('main:manageMenu.manageInterestRates.bearingCapitalBased')}</Label>
        <Combobox
          aria-label={t('main:manageMenu.manageInterestRates.bearingCapitalBased')}
          isDisabled={disabled}
          onSelectionChange={(newValue) => {
            updateBearingCapitalBased(
              newValue as griddy.InterestRates['interestBearingCapitalBased'],
            );
          }}
          selectedKey={masterConfiguration?.interestRates?.interestBearingCapitalBased}
        >
          {selectOptions.bearingCapitalBased.map(({ label, value }) => (
            <ComboboxItem key={value}>{label}</ComboboxItem>
          ))}
        </Combobox>
      </div>

      <div className="flex items-end justify-start gap-2 pt-2">
        <div className="w-44">
          <Label>{t('main:manageMenu.manageInterestRates.standardInterestRates')}</Label>
          <NumberInput
            className="text-right"
            defaultValue={DEFAULT_INTEREST_RATE}
            disabled={disabled}
            isAllowed={({ floatValue }) => !Number.isNaN(floatValue)}
            onChange={handleChangeDefaultInterestRate}
            suffix="%"
            value={masterConfiguration?.interestRates?.uniformInterestRate ?? 0}
          />
        </div>
        <Button
          disabled={disabled}
          onClick={onApplyInterestRate}
          size="md"
          variant="primary"
        >
          {t('main:manageMenu.manageInterestRates.applyToTable')}
        </Button>
      </div>

      <div className="w-[21rem] pt-2">
        <Button
          disabled={disabled || noneIsSelected}
          onClick={handleResetSelectedRows}
          size="md"
          variant="primary"
        >
          <span className="appkiticon icon-refresh-outline" />
          <span>{t('main:manageMenu.manageInterestRates.resetToDefault')}</span>
        </Button>
      </div>

      <div
        className="mt-2.5 h-full flex-1"
        data-testid="InterestRatesTable"
      >
        <BaseEditTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          isDisabled={disabled}
          onChange={onTableDataChange}
          onSelectionChanged={onSelectionChanged}
          ref={tableRef}
          rowData={rowData}
          rowSelection="multiple"
        />
      </div>
    </>
  );
};

export const InterestRatesPage = bindControllers(InterestRatesMemo, [useController]);
InterestRatesPage.displayName = 'InterestRatesPage';
