import type { ColDef, ServerSideRowGroupSelectionState } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useRef } from 'react';

import { useAgGridData } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated, dice } from '@org/query';
import { showErrorCamsNotification, showNotification } from '@org/ui';
import { FILE_CONFIG } from '@org/utils';

import type { BaseControllerType } from '../types';
import { getColumnsForInputFile } from './utils';

export interface ControllerProps extends Partial<BaseControllerType> {
  type: string;
  yearCalculationId?: string;
  masterConfigurationId: string;
  publicFacilityId: string;
  baseFileType: aggregated.BaseFileType;
  tab?: string;
  setTab: (tab: string) => void;
}

export type ControllerType = ReturnType<typeof useController>;
const DEFAULT_ERP_TYPE = 'SAP_ECC6';

const defaultColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
  minWidth: 150,
  resizable: true,
} satisfies ColDef;

const PARENT_NODE_SELECT_ALL = ['ALL'];
const PARENT_COST_CENTER_NODE_SELECT_ALL = [
  {
    id: 'ALL',
    status: 'FULLY',
  },
];

const isCostCenterNode = (nodeId?: string) =>
  nodeId === 'costCenterSetKeys' || nodeId === 'costCenterSetss';

const setSelectAllForEachGroup = (filterGroup: Record<string, string[]>) => {
  const response: Record<string, string[] | { id: string; status: string }[]> = {};
  for (const [key, _value] of Object.entries(filterGroup)) {
    if (isCostCenterNode(key)) {
      response[key] = PARENT_COST_CENTER_NODE_SELECT_ALL;
    } else {
      response[key] = PARENT_NODE_SELECT_ALL;
    }
  }

  return response;
};

function getSelectedIds(data: ServerSideRowGroupSelectionState) {
  const stack = [data];
  const ccResponse: { id: string; status: string }[] = [];
  const response: string[] = [];

  let previousNode = data;
  const parentName = data.nodeId;
  const hasCCFormat = isCostCenterNode(data.nodeId);

  while (stack.length > 0) {
    const next = stack.pop();
    const children = next?.toggledNodes ?? [];
    if (next?.selectAllChildren === true) {
      if (next?.nodeId === 'unknown-node') {
        if (hasCCFormat) {
          ccResponse.push({
            id: previousNode.nodeId ?? '',
            status: 'partial',
          });
        } else {
          const actualNodeId = previousNode.nodeId?.replace(`${parentName}-`, '') ?? '';
          response.push(actualNodeId);
        }
      } else {
        if (hasCCFormat) {
          ccResponse.push({
            id: next.nodeId ?? '',
            status: 'fully',
          });
        } else {
          const actualNodeId = next.nodeId?.replace(`${parentName}-`, '') ?? '';
          response.push(actualNodeId);
        }
      }
    }

    if (children.length > 0) {
      for (const child of children) {
        stack.push(child);
      }
    }

    if (next) {
      previousNode = next;
    }
  }

  return response.length > 0 ? response : ccResponse;
}

export function useController({
  masterConfigurationId,
  baseFileType,
  publicFacilityId,
  clientId,
  yearCalculationId,
  type,
  tab = 'UPLOAD',
  setTab,
}: ControllerProps) {
  const apiFileType = aggregated.baseToApiFileType[baseFileType];
  const camsFiltersTableRef = useRef<AgGridReact | null>(null);
  const overViewTableRef = useRef<AgGridReact>(null);

  const columnDefs = useMemo(
    () => (baseFileType ? getColumnsForInputFile(baseFileType) : []),
    [baseFileType],
  );

  const { year } = aggregated.useYear({
    publicFacilityId: publicFacilityId ?? '',
    type,
    yearId: yearCalculationId ?? '',
  });

  const { filesMetadata, updateFileMetadata, createNewFileMetadata } = aggregated.useFileMetadata({
    yearCalculationId,
  });

  const fileMetadata = useMemo(
    () => filesMetadata?.find((item) => item.type === apiFileType),
    [apiFileType, filesMetadata],
  );

  const { t } = useTranslation();
  const {
    isLoading: isLoadingImportFiles,
    importLabels,
    importAccountPlans,
    importAssets,
    importJournals,
    importLedgerAccounts,
  } = aggregated.useCamsImportData({
    yearId: yearCalculationId ?? '',
  });

  const {
    isLoading: preselectedErpTypesIsLoading,
    preselectedErpTypes,
    createFileErpType,
    updateFileErpType,
  } = aggregated.useCamsErpTypes({});

  const { erpType: apiFileErpTypes, isLoading: getOneErpTypeIsLoading } =
    aggregated.useCamsGetOneErpType({
      yearId: yearCalculationId!,
      clientId: clientId ?? '',
      year: year?.year,
    });

  const erpType = useMemo(
    () =>
      apiFileErpTypes?.find((item) => item.fileType === apiFileType)?.erpType ?? DEFAULT_ERP_TYPE,
    [apiFileErpTypes, apiFileType],
  );

  const { updateToggle, updateToggleIsLoading } = aggregated.useCamsToggles({
    calculationYear: year?.year ?? '',
    yearId: yearCalculationId ?? '',
    clientId: clientId ?? '',
    fileType: apiFileType,
    erpType,
    isPostCalculation: type === 'post',
  });

  const handleErpTypeChange = useCallback(
    async (newErpType: dice.FileErpTypeDTO['erpType']) => {
      const data = {
        yearId: yearCalculationId!,
        clientId: clientId ?? '',
        calculationYear: Number(year?.year),
        fileType: apiFileType,
        erpType: newErpType,
      };
      if (!erpType) {
        await createFileErpType(data);
      } else {
        await updateFileErpType(data);
      }
    },
    [
      apiFileType,
      clientId,
      createFileErpType,
      erpType,
      updateFileErpType,
      year?.year,
      yearCalculationId,
    ],
  );

  const importFileData = async () => {
    if (apiFileType === FILE_CONFIG.ACCOUNT_PLAN.apiType) {
      const data = await importAccountPlans({
        calculationYear: Number(year?.year),
        esraClientId: clientId ?? '',
      });
      showErrorCamsNotification(t, data);
    }

    if (apiFileType === FILE_CONFIG.LABELS.apiType) {
      const data = await importLabels({
        calculationYear: Number(year?.year),
        esraClientId: clientId ?? '',
      });
      showErrorCamsNotification(t, data);
    }
  };

  const handleFiltering = useCallback(async () => {
    const selectedRows =
      camsFiltersTableRef?.current?.api?.getServerSideSelectionState() as ServerSideRowGroupSelectionState;
    const parentItems = (selectedRows?.toggledNodes ?? []) as ServerSideRowGroupSelectionState[];
    const selected: Record<string, string[] | { id: string; status: string }[]> = {};
    const isPostCalculation = type === 'post';

    let queryParams = {
      calculationYear: Number(year?.year),
      erpType,
      esraClientId: clientId ?? '',
      isPostCalculation,
    };

    // top level select all checkbox
    if (parentItems.length === 0 && selectedRows.selectAllChildren) {
      let responseData;
      if (apiFileType === FILE_CONFIG.ASSETS.apiType) {
        queryParams = {
          ...queryParams,
          ...setSelectAllForEachGroup(aggregated.createAssetFilterQuery()),
        };
        responseData = await importAssets(queryParams);
      } else if (apiFileType === FILE_CONFIG.JOURNAL.apiType) {
        queryParams = {
          ...queryParams,
          ...setSelectAllForEachGroup(aggregated.createJournalFilterQuery()),
        };
        responseData = await importJournals(queryParams);
      } else {
        queryParams = {
          ...queryParams,
          ...setSelectAllForEachGroup(aggregated.createLedgerAccountFilterQuery()),
        };
        responseData = await importLedgerAccounts(queryParams);
      }

      showErrorCamsNotification(t, responseData);
    } else if (parentItems.length > 0) {
      parentItems.forEach((parent) => {
        if (parent.selectAllChildren === true) {
          if (isCostCenterNode(parent.nodeId)) {
            selected[parent.nodeId!] = PARENT_COST_CENTER_NODE_SELECT_ALL;
          } else {
            selected[parent.nodeId!] = PARENT_NODE_SELECT_ALL;
          }
        } else {
          selected[parent.nodeId!] = getSelectedIds(parent as ServerSideRowGroupSelectionState);
        }
      });

      let responseData;
      if (apiFileType === FILE_CONFIG.ASSETS.apiType) {
        queryParams = {
          ...queryParams,
          ...aggregated.createAssetFilterQuery(),
          ...selected,
        };
        responseData = await importAssets(queryParams);
      } else if (apiFileType === FILE_CONFIG.JOURNAL.apiType) {
        queryParams = {
          ...queryParams,
          ...aggregated.createJournalFilterQuery(),
          ...selected,
        };
        responseData = await importJournals(queryParams);
      } else {
        queryParams = {
          ...queryParams,
          ...aggregated.createLedgerAccountFilterQuery(),
          ...selected,
        };
        responseData = await importLedgerAccounts(queryParams);
      }

      showErrorCamsNotification(t, responseData);
    } else {
      showNotification('warning', t('common:modals.camsImport.noFilters'));
    }
  }, [
    apiFileType,
    clientId,
    erpType,
    importAssets,
    importJournals,
    importLedgerAccounts,
    t,
    type,
    year?.year,
  ]);

  const { data, isFetching: isLoadingFiles } = dice.useGetFile(
    {
      queryParams: {
        fileType: apiFileType,
        yearId: yearCalculationId ?? '',
      },
    },
    {
      staleTime: 0,
      enabled: !!apiFileType && tab === 'OVERVIEW',
    },
  );

  const overViewAgGridProps = useAgGridData({
    agGridRef: overViewTableRef,
    data: data?.file,
  });

  const updateFileMetadataForUploadOrErp = useCallback(
    async (
      { currentTarget: { checked } }: ChangeEvent<HTMLInputElement>,
      columnType: 'fileUploadToggleEnabled' | 'erpToggleEnabled',
    ) => {
      if (!fileMetadata) {
        await createNewFileMetadata({
          type: apiFileType,
          yearCalcId: yearCalculationId,
          [columnType]: checked,
        });
      } else {
        await updateFileMetadata({
          ...fileMetadata,
          [columnType]: checked,
        });
      }
    },
    [fileMetadata, createNewFileMetadata, apiFileType, yearCalculationId, updateFileMetadata],
  );

  return {
    baseFileType,
    columnDefs,
    masterConfigurationId,
    defaultColDef,
    fileMetadata,
    apiFileType,
    importFileData,
    overViewAgGridProps,
    yearCalculationId: yearCalculationId ?? '',
    isLoading:
      isLoadingFiles ||
      isLoadingImportFiles ||
      preselectedErpTypesIsLoading ||
      getOneErpTypeIsLoading ||
      updateToggleIsLoading,
    setTab,
    overViewTableRef,
    tab,
    data,
    updateFileMetadataForUploadOrErp,
    camsFiltersTableRef,
    handleFiltering,
    type,
    year,
    clientId,
    preselectedErpTypes,
    erpType,
    handleErpTypeChange,
    updateToggle,
  };
}
