import type { ValueGetterFunc } from 'ag-grid-community';

import type { dice, griddy } from '@org/query';

import { accountIdValue } from './getAccountID';
import { getCustomLabel } from './getCustomLabel';

export const getValueForTableColumnsGroup =
  (
    masterConfiguration: dice.MasterConfiguration,
    accountsPlanMap?: griddy.GetAllAccountIdToAccountDescriptionMapResponse,
  ): ValueGetterFunc =>
  (params) =>
    params?.node?.level === 1
      ? getCustomLabel(masterConfiguration, params)
      : accountIdValue(params, accountsPlanMap);
