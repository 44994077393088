import type {
  CellClassRules,
  ColDef,
  ExcelExportParams,
  ValueFormatterFunc,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community';
import type { AgGridReactProps } from 'ag-grid-react';
import { forwardRef, useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';
import classnames from 'classnames';

import {
  containsOnlyDigitsCommaAndDot,
  formatIntegerToLocaleString,
  formatNonZeroNumberToLocaleString,
  formatToSimpleDate,
  formatValue,
  initialGroupOrderComparator,
  parseGermanPriceFormat,
} from '@org/utils';

import { TooltipCellRenderer } from '../TooltipCellRenderer';
import { defaultExcelStyles } from './utils/defaultExcelStyles';

import './ag-grid.css';

import styles from './BaseTable.module.css';

const DEFAULT_SHEET_NAME = 'Daten';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface BaseTableProps<TData = any> extends AgGridReactProps<TData> {
  'data-testid'?: string;
}

const valueFormatterZero: ValueFormatterFunc = ({ value }) =>
  value === '' || value === null || value === undefined || containsOnlyDigitsCommaAndDot(value)
    ? formatNonZeroNumberToLocaleString(value, { defaultValue: '-' })
    : value;

const defaultColTypes = {
  date: {
    cellClass: 'ag-cell-type-date',
    valueFormatter: ({ value }) => (value ? formatToSimpleDate(new Date(value)) : ''),
  },
  'number-without-zero-formatting': {
    cellClass: 'ag-cell-type-number-without-zero-formatting',
    cellEditorParams: {},
    valueFormatter: ({ value }) =>
      formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
    valueParser: ({ newValue }) => parseGermanPriceFormat(newValue),
  },
  float: {
    cellClass: 'ag-cell-type-float',
    valueFormatter: valueFormatterZero,
  },
  integer: {
    cellClass: 'ag-cell-type-integer',
    valueFormatter: ({ value }) =>
      value
        ? formatIntegerToLocaleString(value, {
            defaultValue: '-',
          })
        : '-',
  },
  number: {
    cellClass: 'ag-cell-type-number',
    valueFormatter: valueFormatterZero,
  },
  percent: {
    cellClass: 'ag-cell-type-float',
    valueFormatter: valueFormatterZero,
    valueGetter: (params: ValueGetterParams) => {
      const fieldName = params.colDef.field!;
      return Number.parseFloat(params.data?.[fieldName].toFixed(2));
    },
    valueParser: ({ newValue }: ValueParserParams) => (newValue as unknown as number).toFixed(2),
  },
  price: {
    cellClass: 'ag-cell-type-price',
    cellEditorParams: {},
    valueFormatter: valueFormatterZero,
    valueParser: ({ newValue }) => parseGermanPriceFormat(newValue),
  },
  'report-price': {
    cellClass: 'ag-cell-type-report-price accounting-number',
    cellEditorParams: {},
    valueFormatter: valueFormatterZero,
    valueParser: ({ newValue }) => parseGermanPriceFormat(newValue),
  },
  text: {
    cellClass: 'ag-cell-type-text',
  },
} satisfies Record<string, ColDef>;

// Workaround for jumping columns
const processUnpinnedColumns = () => [];

const Table = forwardRef<AgGridReact, BaseTableProps>((properties, ref) => {
  const {
    className,
    defaultColDef,
    'data-testid': testId,
    excelStyles,
    defaultExcelExportParams: excelExportParameters,
    columnTypes,
    rowModelType,
    ...otherProperties
  } = properties;

  const cellClassRules: CellClassRules = useMemo(
    () => ({
      'ag-editable': (params) => {
        if (!params.colDef.editable) {
          return false;
        }
        if (params.colDef.editable instanceof Function) {
          // const { colId = '' } = params.colDef;
          // const column = params.api.getColumnDef(colId);
          // const column = params.api.getColumn(field);

          return params.colDef.editable({ ...params });
        }
        return true;
      },
      ...defaultColDef?.cellClassRules,
    }),
    [defaultColDef?.cellClassRules],
  );

  const combinedExcelStyles = useMemo(
    () => [...defaultExcelStyles, ...(excelStyles ?? [])],
    [excelStyles],
  );

  const combinedDefaultExcelExportParameters = useMemo(
    () =>
      ({
        sheetName: DEFAULT_SHEET_NAME,
        ...excelExportParameters,
      }) satisfies ExcelExportParams,
    [excelExportParameters],
  );

  const defaultColDefCombined: ColDef = useMemo(
    () => ({
      autoHeaderHeight: true,
      resizable: true,
      sortable: true,
      suppressMovable: true,
      tooltipComponent: TooltipCellRenderer,
      tooltipValueGetter: (parameters) => formatValue(parameters.value),
      type: 'text',
      useValueFormatterForExport: true,
      ...defaultColDef,
      cellClassRules,
    }),
    [cellClassRules, defaultColDef],
  );

  const notServerSideProps = useMemo(() => {
    if (rowModelType !== 'serverSide') {
      return {
        // Black magic for ordering group rows
        initialGroupOrderComparator,
      };
    }
  }, [rowModelType]);

  return (
    <div
      className={classnames('ag-theme-balham', 'ag-grid', styles.BaseTable, className)}
      data-testid={testId ?? 'BaseTable'}
    >
      <AgGridReact
        animateRows
        columnTypes={{
          ...defaultColTypes,
          ...columnTypes,
        }}
        defaultColDef={defaultColDefCombined}
        defaultExcelExportParams={combinedDefaultExcelExportParameters}
        enableCellTextSelection
        excelStyles={combinedExcelStyles}
        groupAllowUnbalanced
        groupHeaderHeight={23}
        headerHeight={23}
        processUnpinnedColumns={processUnpinnedColumns}
        ref={ref}
        rowModelType={rowModelType}
        stopEditingWhenCellsLoseFocus
        suppressAggFuncInHeader
        suppressFieldDotNotation
        {...notServerSideProps}
        {...otherProperties}
      />
    </div>
  );
});

export const BaseTable = Table as unknown as typeof AgGridReact;
